////////////////////
// All functions that are used throughout the admin app
// Includes redux dispatch functions
////////////////////

export function login(info) {
    return {
        type: "LOGIN",
        payload: {
            info
        }
    }
}

export function logout() {
    return {
        type: "LOGOUT",
        payload: {
            info: null
        }
    }
}

export function setStep(step) {
    return {
        type: "NEXT_STEP",
        payload: {
            currentStep: step
        }
    }
}

export function saveDiscounts(discounts) {
    return new Promise((resolve) => {
        resolve({
            type: "SAVE_DISCOUNTS",
            payload: discounts
        });
    });
}

export function cleanReducer() {
    return new Promise((resolve) => {
        resolve({
            type: "CLEAN_BUILDER"
        });
    });
}

export function saveSettings(data) {
    return new Promise((resolve) => {
        resolve({
            type: "SAVE_SETTINGS",
            payload: {
                builderName: data.builderName,
                active: data.active,
                theme: data.theme,
                lightbox: data.lightbox,
                productId: data.productId,
                primary: data.primary,
                secondary: data.secondary,
                useVariants: data.useVariants,
                taxAble: data.taxAble,
                fixedPrice: data.fixedPrice,
                isFixed: data.isFixed,
                addText: data.addText,
                selectedColor: data.selectedColor,
                seoTitle: data.seoTitle,
                seoDescription: data.seoDescription,
                seoKeywords: data.seoKeywords,
                showPrices: data.showPrices,
                formatDescription: data.formatDescription,
                borderColor: data.borderColor,
                lightboxBg: data.lightboxBg,
                showCarousel: data.showCarousel,
                showProgress: data.showProgress,
                progressBarColor: data.progressBarColor,
                progressTextColor: data.progressTextColor,
                translations: data.translations,
                priceColor: data.priceColor,
                totalBoxBackground: data.totalBoxBackground,
                totalColor: data.totalColor,
                boxContentsText: data.boxContentsText,
                customJS: data.customJS,
                customHTML: data.customHTML,
                modalColor: data.modalColor,
                showThumbnails: data.showThumbnails,
                startPrice: data.startPrice,
                showBuilding: data.showBuilding,
                buildingGif: data.buildingGif,
                buildingBackground: data.buildingBackground,
                buildingTitle: data.buildingTitle,
                buildingText: data.buildingText,
                hideSoldOut: data.hideSoldOut,
                redirectTo: data.redirectTo,
                requiresShipping: data.requiresShipping,
                fulfilmentOption: data.fulfilmentOption,
                customDomain: data.customDomain,
                rememberSelections: data.rememberSelections,
                singleProducts: data.singleProducts,
                keepBuilderOnOrder: data.keepBuilderOnOrder,
                paginated: data.paginated
            }
        });
    });
}

export function exitBuilder() {
    return {
        type: "EXIT_BUILDER"
    }
}

export function closeBuilder(props) {
    props.exitBuilder();
    props.history.push("/admin/");
}

export function editBuilder(build) {
    return new Promise((resolve) => {
        resolve({
            type: 'EDIT_BUILDER',
            payload: build
        });
    });
}

export function setBuilderDetails(data) {
    return new Promise((resolve) => {
        resolve({
            type: "SET_BUILDER",
            payload: data
        });
    });
}

export function createBuilder(builderId) {
    return new Promise((resolve) => {
        resolve({
            type: "NEW_BUILDER",
            payload: {
                builderId
            }
        });
    });
}

export function addStep(step) {
    return {
        type: "ADD_STEP",
        payload: step
    }
}

export function replaceSteps(steps) {
    return new Promise((resolve) => {
        resolve({
            type: "SET_STEPS",
            payload: steps
        });
    });
}

export function setPath(path) {
    return {
        type: "SET_PATH",
        payload: path
    }
}

export function setProduct(p) {
    return {
        type: "SET_PRODUCT",
        payload: p
    }
}


// normal functions

const API_URL = process.env.REACT_APP_API_URL;

export function checkAuth(query) {
    return new Promise((resolve, reject) => {
        let parameters = [];
        for (var key in query) {
            parameters.push(key + '=' + query[key]);
        }

        let params = parameters.sort().join(query.hmac ? '&' : '');
        const endpoint = `${API_URL}/auth?${params}`;
    
        try {
            fetch(endpoint)
            .then((data) => data.json())
            .then((res) => resolve(res))
            .catch((err) => { 
                console.log(err);
                reject(err); 
            });
        } catch(e) {
            console.log(e);
            console.log(e.message);
        }
    });
}

export function install(query) {
    return new Promise((resolve, reject) => {
        const { timestamp, shop, hmac, code, state, host } = query;
        let endpoint = `${API_URL}/auth/install?shop=${shop}&hmac=${hmac}&timestamp=${timestamp}&code=${code}&state=${state}`;
        if (host) endpoint = endpoint + `&host=${host}`;
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function checkPlan(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/auth/plan?id=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function confirmPlan(query) {
    return new Promise((resolve, reject) => {
        const { charge_id, id } = query;
        const endpoint = `${API_URL}/auth/confirm?id=${id}&charge_id=${charge_id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function storeScriptTag(id, enabled) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/shop/script?id=${id}&enabled=${enabled}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export const resizeImage = (file, maxDimension) => {
    return new Promise((resolve) => {
      var fr = new FileReader();
      fr.onload = () => {
        var img = new Image;
        img.onload = () => {
          let newHeight, newWidth, ratio = 1;
          if (img.width > maxDimension) {
            ratio = maxDimension/img.width;
          } else if (img.height > maxDimension) {
            ratio = maxDimension/img.height;
          } else {
            ratio = 1
          }
  
          newWidth = img.width*ratio;
          newHeight = img.height*ratio;
  
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width  = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          return canvas.toBlob((blob) => {
            resolve(blob);
          });
        }
        img.src = fr.result;
      }
      fr.readAsDataURL(file);
    });
}

export function createProduct(data) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/products/create`;

        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
                "title": data.title,
                "id": data.id,
                "builderId": data.builderId
            })
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            resolve(false); 
        });
    });
}

export function updateProductTitle(data) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/products/update`;

        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
                "title": data.title,
                "id": data.id,
                "productId": data.productId
            })
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            resolve(false); 
        });
    });
}

export function updateVersion(version, id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/versions/${version}`;

        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
                "id": id
            })
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function deleteProduct(id, productId) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/products/delete`;

        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
                "id": id,
                "productId": productId
            })
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function exportFile(query, path) {
    return new Promise((resolve, reject) => {
        const { timestamp, shop, hmac, code, state } = query;
        const endpoint = `${API_URL}/export/${path}${query}`;

        fetch(endpoint)
        .then((data) => data.blob())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function saveProductWebhook(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/webhooks/check?id=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            resolve(err); 
        });
    });
}

export function getOrder(shopId, id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/orders/get?id=${shopId}&order=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            resolve(false); 
        });
    });
}

export function unwrapOrder(shopId, id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/orders/unwrap?id=${shopId}&order=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            resolve(false); 
        });
    });
}

export function getFulfilmentServices(shopId) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/products/fulfilment?id=${shopId}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            resolve(false); 
        });
    });
}

export function getAssets(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/assets/get?id=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            resolve(false); 
        });
    });
}

export function checkPaginatedSection(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/custom/section?id=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res.hasSection))
        .catch((err) => { 
            console.log(err);
            resolve(false); 
        });
    });
}

export function collectionProducts(id, collectionId) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/products/collection?id=${id}&collectionId=${collectionId}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            resolve(false); 
        });
    });
}

export function uploadAsset(id, files) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/assets/post`;
        var fr = new FileReader;
        let file = files[0];
        fr.onload = () => {
            let base64Img = fr.result.replace(/^data:image\/[a-z]+;base64,/, "");
            fetch(endpoint, {
                method: "POST",
                body: JSON.stringify({
                    "id": id,
                    "asset": {
                      "key": `assets/${file.name.replace(" ", "")}`,
                      "attachment": base64Img
                    }
                })
            })
            .then((data) => data.json())
            .then((res) => resolve(res))
            .catch((err) => { 
                console.log(err);
                reject(err); 
            });
        }
        fr.readAsDataURL(file);
    });
}
