import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { db } from './../../actions/settings';
import { closeBuilder, exitBuilder, addStep, replaceSteps, setStep, createProduct, updateProductTitle, setProduct } from './../../actions/';
import { Card, DataTable, Button, ButtonGroup, Banner, InlineError, Heading } from '@shopify/polaris';

import FormStep from './step/form';
import ProductStep from './step/product';
import PreStep from './step/pre';
import SubStep from './step/sub';
import ContentStep from './step/content';

function BuilderSteps(props) {

    let [saving, setSaving] = useState(false);
    let [error, setError] = useState('');
    let [steps, setSteps] = useState(props.builder.steps);
    let [formActive, setFormActive] = useState(false);
    let [productActive, setProductActive] = useState(false);
    let [subActive, setSubActive] = useState(false);
    let [editStep, setEditStep] = useState(null);
    let [preActive, setPreActive] = useState(false);
    let [contentActive, setContentActive] = useState(false);

    useEffect(() => {
        setSteps(props.builder.steps);
    }, [props.builder]);

    const saveSteps = async() => {
        try {
            if (steps.length === 0) {
                setError('At least 1 step is required.');
            } else {
                setSaving(true);
                let { 
                    builderName, builderId, steps, active, addText,
                    theme, lightbox, productId, primary, secondary,
                    discounts, useVariants, taxAble, isFixed, fixedPrice,
                    selectedColor, seoTitle, seoKeywords, seoDescription,
                    showPrices, formatDescription, borderColor, lightboxBg,
                    showCarousel, showProgress, progressBarColor, progressTextColor,
                    translations, totalBoxBackground, totalColor, priceColor,
                    boxContentsText, customJS, customHTML, modalColor, showThumbnails, startPrice,
                    showBuilding, buildingGif, buildingBackground, buildingTitle, buildingText,
                    hideSoldOut, redirectTo, requiresShipping, fulfilmentOption, customDomain,
                    rememberSelections, singleProducts, keepBuilderOnOrder, paginated
                } = props.builder;
                steps.sort(function(a, b) {
                    return parseFloat(a.displayOrder) - parseFloat(b.displayOrder);
                });
                let productHandle = '';
                if (!productId) {
                    let product = await createProduct({
                        title: builderName, id: props.shop.info.id, builderId: builderId
                    });
                    productId = product.id;
                    productHandle = product.handle;
                    props.setProduct(productId);
                } else {
                    let updated = await updateProductTitle({
                        title: builderName, id: props.shop.info.id, productId: productId
                    });

                    if (updated.error) {
                        let product = await createProduct({
                            title: builderName, id: props.shop.info.id, builderId: builderId
                        });
                        productId = product.id;
                        productHandle = product.handle;
                        props.setProduct(productId);
                    } else {
                        productHandle = updated.handle;
                    }
                }

                await db
                    .collection("Builders")
                    .doc(builderId)
                    .set({
                        builderName,
                        builderId,
                        stepCount: steps.length,
                        active,
                        shopId: props.shop.info.id,
                        shop: props.shop.info.shop,
                        theme: theme,
                        deleted: false,
                        lightbox,
                        productId,
                        productHandle,
                        primary,
                        selectedColor,
                        secondary,
                        discounts: discounts || [],
                        useVariants: (useVariants === undefined) ? false : useVariants,
                        taxAble: (taxAble === undefined) ? true : taxAble,
                        isFixed: (isFixed === undefined) ? false : isFixed,
                        fixedPrice: (fixedPrice === undefined) ? '' : fixedPrice,
                        addText: (addText === undefined) ? '' : addText,
                        seoTitle, seoKeywords, seoDescription,
                        showPrices: (showPrices === undefined) ? true : showPrices,
                        showCarousel: (showCarousel === undefined) ? true : showCarousel,
                        showProgress: (showProgress === undefined) ? false : showProgress,
                        formatDescription: (formatDescription === undefined) ? false : formatDescription,
                        borderColor: borderColor,
                        lightboxBg: lightboxBg,
                        customDomain: (customDomain === undefined) ? '' : customDomain,
                        customHTML: (customHTML === undefined) ? '' : customHTML,
                        progressBarColor, progressTextColor, translations,
                        totalBoxBackground, totalColor, priceColor, boxContentsText,
                        customJS, modalColor,
                        showThumbnails: (showThumbnails === undefined || theme !== 'full') ? false : showThumbnails,
                        startPrice: (startPrice === undefined) ? 0.00 : startPrice,
                        showBuilding, buildingGif, buildingBackground, buildingTitle, buildingText,
                        hideSoldOut, redirectTo,
                        requiresShipping: (requiresShipping === undefined) ? true : requiresShipping,
                        fulfilmentOption:  (fulfilmentOption === undefined) ? 'manual' : fulfilmentOption,
                        rememberSelections: (rememberSelections === undefined) ? true : rememberSelections,
                        singleProducts: (singleProducts === undefined) ? false : singleProducts,
                        keepBuilderOnOrder: (keepBuilderOnOrder === undefined) ? false : keepBuilderOnOrder,
                        paginated: (paginated === undefined) ? false : paginated
                    });
                await deleteSteps(builderId);
                await saveDBSteps(steps, builderId);

                if (isFixed) {
                    await db.collection("VariantLimits").doc().set({
                        shopId: props.shop.info.id,
                        productId,
                        count: 0,
                        variantId: "deleteAll"
                    });
                }

                props.setStep(2);
                props.parent.setState({tabSelected: 2});
            }
        } catch(e) {
            setSaving(false);
            console.log(e);
            setError('An error occurred, please try again.');
        }
    }

    async function deleteSteps(id) {
        let docs = await db.collection(`Builders/${id}/Steps`).get();
        if (docs.size > 0) {
            docs.forEach(async (doc) => {
                await db.collection(`Builders/${id}/Steps`).doc(doc.id).delete();
            });
        }
    }

    function saveDBSteps (steps, id) {
        try {
            return new Promise((resolve) => {
            
                for (let i = 0, p = Promise.resolve(); i < steps.length; i++) {
                    p = p.then(_ => new Promise(async(presolve) => {
                        let thisStep = steps[i];
                        thisStep.shopId = props.shop.info.id;
                        await db
                            .collection(`Builders/${id}/Steps`)
                            .doc(`Step${i+1}`)
                            .set(thisStep);
                        if (thisStep.filterCollection && thisStep.selectedCollections.length > 0) {
                            await db
                                .collection(`Builders/${id}/Steps/Step${i+1}/Filters`)
                                .doc('Collections')
                                .set({
                                    collections: thisStep.selectedCollections
                                });
                        }
                        
                        if (i === steps.length-1) resolve();
                        presolve();
                    }));
                }
            });
        } catch(e) {
            db.collection("Errors").doc(id).set({
                errorMsg: e.message,
                id: id
            });
        }
    }

    const addStep = async(editIndex, step) => {
        if (editIndex === null) {
            props.addStep(step);
        } else {
            let newSteps = props.builder.steps;
            newSteps[editIndex] = step;
            await props.replaceSteps(newSteps);
        }
        setProductActive(false);
        setFormActive(false);
        setPreActive(false);
        setSubActive(false);
        setContentActive(false);
        setEditStep(null);
    }

    const editStepIndex = (index) => {
        setEditStep(index);
        setTimeout(() => {
            if (steps[index].type === "product") {
                setProductActive(true);
            } else if (steps[index].type === "pre") {
                setPreActive(true);
            } else if (steps[index].type === "subscription") {
                setSubActive(true);
            } else if (steps[index].type === "content") {
                setContentActive(true);
            } else {
                setFormActive(true);
            }
            let orderedSteps = props.builder.steps.sort((a,b) => Number(a.displayOrder) - Number(b.displayOrder));
            props.replaceSteps(orderedSteps);
        }, 100);
    }

    const removeIndex = (index) => {
        let newSteps = props.builder.steps.filter((s,i) => i !== index);
        props.replaceSteps(newSteps);
    }

    return <>
        {formActive &&
        <FormStep addStep={addStep} editing={editStep} hideForm={setFormActive} />
        }
        {productActive &&
        <ProductStep addStep={addStep} editing={editStep} hideForm={setProductActive}/>
        }
        {preActive &&
        <PreStep addStep={addStep} editing={editStep} hideForm={setPreActive}/>
        }
        {subActive &&
        <SubStep addStep={addStep} editing={editStep} hideForm={setSubActive}/>
        }
        {contentActive &&
        <ContentStep addStep={addStep} editing={editStep} hideForm={setContentActive}/>
        }
        {!formActive && !productActive && !preActive && !subActive && !contentActive &&
        <Card 
            title="Builder Steps" 
            sectioned
            secondaryFooterActions={[{
                content: 'Back',
                onAction: () => {
                    props.setStep(0);
                    props.parent.setState({tabSelected: 0});
                },
                loading: saving
            }]}
            primaryFooterAction={{
                content: 'Save & Next',
                onAction: () => saveSteps(),
                loading: saving,
                disabled: !props.builder.steps.length > 0 
            }}
        >
            <p>Add the steps in the order you wish for them to appear to the customer.</p>
            <Card.Section>
                <Banner
                    title="Form Steps"
                    status="info"
                    >
                    <p>
                        A form step will allow you to collect custom information from the customer.{` `}
                        This could be an engraving, a personalised note or anything you like.{` `}
                        This information you collect will appear on the order.
                    </p>
                </Banner>
            </Card.Section>
            <Card.Section>
                {error !== "" &&
                <InlineError message={error} />
                }
                <DataTable
                    columnContentTypes={[
                        'text',
                        'numeric',
                        'text',
                        'text',
                    ]}
                    headings={[
                        'Step',
                        'Step #',
                        'Edit',
                        'Delete',
                    ]}
                    rows={props.builder.steps.sort((a,b) => Number(a.displayOrder) - Number(b.displayOrder)).map((i, x) => {
                        return [
                            i.title, 
                            i.displayOrder, 
                            <Button onClick={() => editStepIndex(x)} size="slim">Edit</Button>, 
                            <Button onClick={() => removeIndex(x)} destructive size="slim">Delete</Button>
                        ]
                    })}
                    footerContent={`${props.builder.steps.length} steps added`}
                />
            </Card.Section>
            <Card.Section>
                <div style={{marginBottom: '10px'}}><Heading>Add a Product Step</Heading></div>
                <ButtonGroup>
                    <Button
                        onClick={() => {
                            setEditStep(null);
                            setProductActive(true);
                        }}
                    >Add a Product Selection Step</Button>
                    <Button
                        onClick={() => {
                            setEditStep(null);
                            setPreActive(true);
                        }}
                    >Add a Pre-Selected Product Step </Button>
                    
                </ButtonGroup>
            </Card.Section>
            <Card.Section>
                <div style={{marginBottom: '10px'}}><Heading>Other Step Options</Heading></div>
                <ButtonGroup>
                    <Button
                        onClick={() => {
                            setEditStep(null);
                            setFormActive(true);
                        }}
                    >Add a Form Step </Button>
                    <Button
                        onClick={() => {
                            setEditStep(null);
                            setSubActive(true);
                        }}
                    >Add a Subscription Step </Button>
                    <Button
                        onClick={() => {
                            setEditStep(null);
                            setContentActive(true);
                        }}
                    >Add a Content/HTML Step </Button>
                </ButtonGroup>
            </Card.Section>
        </Card>
        }
    </>
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    closeBuilder,
    exitBuilder,
    addStep,
    replaceSteps,
    setStep,
    setProduct
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BuilderSteps));