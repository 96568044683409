import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setStep, saveDiscounts, exitBuilder } from './../../actions/';
import { 
    Card, Form, FormLayout, InlineError, DataTable, Button,
    Modal, Select, TextField, Checkbox, Banner
 } from '@shopify/polaris';
import { db } from '../../actions/settings';

////////////////////
// The store can set up different criteria for percentage or fixed value discounts
// Discount toast/popup widgets will appear on the customer-end when they meet the criteria
////////////////////

class Discounts extends React.Component {

    state = {
        discounts: this.props.builder.discounts || [],
        editing: '',
        error: '',
        modalError: '',
        saving: false,
        modal: false,
        type: 'percent',
        amount: '',
        trigger: 'count',
        triggerValue: '',
        step: '',
        encourage: false,
        title: '',
        triggerWhen: '',
        order: '',
        remove: true,
        useVariants: this.props.builder.useVariants,
        keepBuilderOnOrder: this.props.builder.keepBuilderOnOrder
    }

    saveDiscounts = async() => {
        try {
            this.setState({saving: true}, async() => {
                let { discounts } = this.state;
                await this.props.saveDiscounts(discounts);
                await db.collection("Builders")
                    .doc(this.props.builder.builderId)
                    .update({discounts});
                this.props.setStep(3);
                this.props.parent.setState({tabSelected: 3});
            });
        } catch(e) {
            console.log(e.message);
            this.setState({
                error: 'An error occurred, please try again.',
                saving: false
            });
        }
    }

    addDiscount = () => {
        let { editing } = this.state;
        this.setState({modalError: ''});
        let { 
            discounts, type, amount, trigger, triggerValue, 
            step, encourage, title, triggerWhen, order, remove
        } = this.state;
        if (!triggerValue || !amount || !title || !order) return this.setState({modalError: 'Please complete all fields'});
        let current = discounts;
        if (editing !== '') {
            current[editing] = {
                type, amount, trigger, triggerValue, step, encourage, title, triggerWhen, order, remove
            };
        } else {
            current.push({
                type, amount, trigger, triggerValue, step, encourage, title, triggerWhen, order, remove
            });
        }
        this.setState({discounts: current, modal: false});
    }

    removeIndex = (index) => {
        let newDiscs = this.props.builder.discounts.filter((s,i) => i !== index);
        this.props.saveDiscounts(newDiscs);
        this.setState({discounts: newDiscs});
    }

    editStepIndex = (discount, i) => {
        this.setState({
            editing: i,
            title: discount.title,
            type: discount.type,
            amount: discount.amount,
            trigger: discount.trigger,
            triggerValue: discount.triggerValue,
            step: discount.step,
            encourage: discount.encourage,
            triggerWhen: discount.triggerWhen,
            order: discount.order,
            remove: discount.remove,
            modal: true
        });
    }
    
    render() {
        let steps = this.props.builder.steps;
        let stepOpts = [];
        steps.map((s, i) => {
            if (s.type === 'product') stepOpts.push({label: `Step ${i+1}`, value: i.toString()});
        });
        stepOpts.unshift({
            label: 'Whole Builder', value: '' 
        });
        let { 
            error, modal, type, modalError, amount, trigger, useVariants, keepBuilderOnOrder, 
            triggerValue, step, encourage, title, editing, triggerWhen, order, remove
        } = this.state;
        console.log("keepBuilderOnOrder", keepBuilderOnOrder, "useV", useVariants)
        return <>
        {!(useVariants && !keepBuilderOnOrder) && 
        <>
        <div style={{marginBottom: '20px'}}>
            <Banner
                title="Automatic discounts."
                status="info"
                >
                <p>
                    The discounts you add here will be applied automatically, without requiring a discount code to be entered. 
                    You can use these to encourage your customers to add more items to their boxes.
                </p>
            </Banner>
        </div>
        <Card 
            title="Automatic Builder Discounts" 
            sectioned
            secondaryFooterActions={[{
                content: 'Back',
                onAction: () => {
                    this.props.setStep(1);
                    this.props.parent.setState({tabSelected: 1});
                },
                loading: this.state.saving
            }]}
            primaryFooterAction={{
                content: 'Finish',
                onAction: () => this.saveDiscounts(),
                loading: this.state.saving
            }}
        >
            <Card.Section>
                {error !== "" &&
                <InlineError message={error} />
                }
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                    ]}
                    headings={[
                        'Discount',
                        'Edit',
                        'Delete',
                    ]}
                    rows={this.state.discounts.map((i, x) => {
                        return [
                            i.title, 
                            <Button onClick={() => this.editStepIndex(i, x)} size="slim">Edit</Button>, 
                            <Button onClick={() => this.removeIndex(x)} destructive size="slim">Delete</Button>
                        ]
                    })}
                    footerContent={`${this.state.discounts.length} discounts added`}
                />
            </Card.Section>
            <Card.Section>
                <Button
                    onClick={() => {
                        this.setState({
                            modal: true,
                            editing: '',
                            title: '',
                            amount: '',
                            trigger: 'count',
                            triggerValue: '',
                            step: '',
                            order: '',
                            remove: true,
                            encourage: false
                        });
                    }}
                >Add a Discount</Button>
            </Card.Section>
        </Card>
        </>
        }
        {useVariants && !keepBuilderOnOrder && 
        <div style={{marginBottom: '20px'}}>
            <Banner
                title="Unavailable on this builder"
                status="info"
                action={{
                    content: 'Finish',
                    onAction: () => this.saveDiscounts(),
                    loading: this.state.saving
                }}
                >
                <p>
                    As you have chosen to 'Use Your Products on new Orders' but not to 'Keep the builder on the order' on the Builder Settings tab, automatic discounts aren't available on this builder.
                </p><br/>
                <p>You can enable the 'Keep the builder on the order' setting on the Builder Settings tab to create automatic discounts.</p>
            </Banner>
        </div>
        }
        <div style={{height: '500px'}}>
        <Modal
            open={modal}
            onClose={() => this.setState({
                modal: true
            })}
            title="Add a Discount"
            primaryAction={{
                content: (editing !== '') ? 'Save Discount' : 'Add Discount',
                onAction: () => this.addDiscount(),
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: () => this.setState({
                      modal: false
                    }),
                },
            ]}
        >
            <Modal.Section>
                <Form>
                    <FormLayout>
                        {modalError !== "" &&
                        <InlineError message={modalError} />
                        }
                        <TextField
                            label="Discount Title"
                            type="text"
                            placeholder="e.g. 10% off 8 items or more"
                            value={title}
                            onChange={(val) => this.setState({title: val})}
                            helpText={
                                <span>
                                Customers will see this in the cart/checkout pages
                                </span>
                            }
                        />
                        <Select
                            label="Discount Type"
                            options={[
                                {label: 'Percentage', value: 'percent'},
                                {label: 'Fixed Amount', value: 'fixed'}
                            ]}
                            onChange={(val) => this.setState({type: val})}
                            value={type}
                        />
                        <TextField
                            label={`Discount Amount ${type === `percent` ? `%` : ``}`}
                            type="number"
                            placeholder="e.g. 8"
                            value={amount}
                            onChange={(val) => this.setState({amount: val})}
                            helpText={
                                <span>
                                {(type === 'percent') ? 'A percentage between 1-99' : ''}
                                </span>
                            }
                        />
                        <FormLayout.Group>
                            <Select
                                label="Apply Discount When"
                                options={[
                                    {label: 'Number of items added', value: 'count'},
                                    {label: 'Total price reaches an amount', value: 'price'}
                                ]}
                                onChange={(val) => this.setState({trigger: val})}
                                value={trigger}
                            />
                            <TextField
                                label={(trigger === 'count') ? 'Number of items required' : 'Price required'}
                                type="number"
                                placeholder="e.g. 10.00"
                                value={triggerValue}
                                onChange={(val) => this.setState({triggerValue: val})}
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Select
                                label="Apply to a step or the whole builder"
                                options={stepOpts}
                                onChange={(val) => this.setState({step: val.toString()})}
                                value={step}
                                helpText={
                                    <span>
                                    The discount will only apply when the above requirements are met on the step you choose, or the builder as a whole.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Encourage discount"
                                checked={encourage}
                                onChange={(val) => this.setState({encourage: val})}
                                helpText={
                                    <span>
                                    A small alert will appear to tell the customer how close they are to receiving a discount.
                                    </span>
                                }
                            />
                            {encourage &&
                            <TextField
                                label={(trigger === 'count') ? 'Encourage when items selected reach' : 'Encourage when price reaches'}
                                type="number"
                                placeholder="e.g. 15"
                                value={triggerWhen}
                                onChange={(val) => this.setState({triggerWhen: val})}
                                helpText={
                                    <span>
                                    We will show a small popup when the item count or current price equals the amount you enter here.
                                    </span>
                                }
                            />
                            }
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Remove any discounts applied before this"
                                checked={remove}
                                onChange={(val) => this.setState({remove: val})}
                                helpText={
                                    <span>
                                    If unchecked, this discount will be added to any other discounts that are applied earlier in the order.
                                    </span>
                                }
                            />
                            <TextField
                                label="Order"
                                type="number"
                                placeholder="e.g. 1"
                                value={order}
                                onChange={(val) => this.setState({order: val})}
                                helpText={
                                    <span>
                                    The order in which the discount is applied in case multiple discounts are added.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        {modalError !== "" &&
                        <InlineError message={modalError} />
                        }
                    </FormLayout>
                </Form>
            </Modal.Section>
        </Modal>
        </div>
        </>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    setStep,
    saveDiscounts,
    exitBuilder
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Discounts));