import React, { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
    Form, FormLayout, TextField, Button, TextStyle, Card,
    Avatar, Thumbnail, ResourceList, ResourceItem, Checkbox,
    InlineError, Stack, RadioButton, ButtonGroup, Banner, Select,
    ChoiceList, TextContainer, Modal, Tabs
} from '@shopify/polaris';

import createApp from '@shopify/app-bridge';
import { ResourcePicker, Toast } from '@shopify/app-bridge/actions';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { collectionProducts } from '../../../actions';

////////////////////
// Bulky Builder
// Store uses this screen when setting up/editing a standard Product Selection step
// All products added below are then available for selection on the builder
////////////////////

const ProductStep = React.forwardRef((props, ref) => {

    const [error, setError] = useState('');
    const [editing] = useState(props.editing !== null ? props.builder.steps[props.editing] : {});
    const [title, setTitle] = useState(editing.title || '');
    const [columns, setColumns] = useState(editing.columns || '4');
    const [description, setDescription] = useState(editing.description || '');
    const [shortTitle, setShortTitle] = useState(editing.shortTitle || '');
    const [displayOrder, setDisplayOrder] = useState(editing.displayOrder || '');
    const [selectedItems, setSelectedItems] = useState(editing.products || []);
    const [required, setRequired] = useState((editing.required) === undefined  ? false : editing.required);
    const [minimum, setMinimum] = useState(editing.minimum || '');
    const [maximum, setMaximum] = useState(editing.maximum || '');
    const [productDisplay, setProductDisplay] = useState(editing.productDisplay || 'variant');
    const [skip, setSkip] = useState(false);
    const [skipIf, setSkifIf] = useState(editing.skipIf || []);
    const [availableTags, setAvailableTags] = useState(editing.availableTags || '');

    const [hidingProductModal, setHidingProductModal] = useState(false);
    const [productHideIf, setProductHideIf] = useState([]);
    const [hideWhenTotalSelected, setHideWhenTotalSelected] = useState("");
    const [hidingProductIndex, setHidingProductIndex] = useState(0);

    const [quantity, setQuantity] = useState(editing.quantity || false);
    const [sortby, setSortby] = useState(editing.sortby || false);
    const [filterTags, setFilterTags] = useState(editing.filterTags || false);
    const [filterCollection, setFilterCollection] = useState(editing.filterCollection || false);
    const [filterTitle, setFilterTitle] = useState(editing.filterTitle || false);
    const [selectedCollections, setSelectedCollections] = useState(editing.selectedCollections || []);
    const [pOrder, setReorder] = useState('');
    const [tab, setTab] = useState(0);
    const [ptab, setpTab] = useState(!props.builder.paginated ? (editing.products && editing.products.length > 0) ? 1 : 0 : 0);
    const [hideTab, setHideTab] = useState(0);
    let loadCollection = [];
    if (typeof editing.collection === 'object' && editing.collection.handle) {
        loadCollection[0] = editing.collection;
    } else {
        loadCollection[0] = {};
    }
    const [selectedCollection, setCollection] = useState(loadCollection[0]);
    const [confirmCollection, setConfirmCollection] = useState(false);
    
    const [collectionItem, setCollectionItem] = useState(loadCollection);
    const [showSummary, setShowSummary] = useState((editing.showSummary) === undefined  ? false : editing.showSummary);
    const [hideFromProgress, setHideFromProgress] = useState(editing.hideFromProgress === undefined  ? false : editing.hideFromProgress);
    
    const [collectionsProducts, setCollectionsProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(false);

    const allProducts = [], allFields = [], allCollections = [];
    props.builder.steps.map((s, x) => {
        if (Number(s.displayOrder) < Number(displayOrder)) {
            if (s.type === "product") {
                s.products.map(p => {
                    p.variants.map((v, i) => {
                        let index = i+1;
                        let vId = (v.id).split("/")[(v.id).split("/").length-1];
                        let step = x+1;
                        allProducts.push({
                            label: "Step " + step + " - " + p.title + " - variant " + index,
                            value: vId
                        });
                    });
                });
                if (s.collection) {
                    allCollections.push(s.collection);
                }
            }
        }
    });
    props.builder.steps.map((s, i) => {
        if (Number(s.displayOrder) < Number(displayOrder)) {
            if (s.type === "form") {
                s.fields.map(f => {
                    let val = '', lab = '';
                    if (f.type === 'checkbox') {
                        lab = f.label + " is checked";
                        allFields.push({
                            label: lab,
                            value: f.label+"@@true"
                        });
                    } else if (f.type === 'text' || f.type === 'number' || f.type === 'date') {
                        lab = f.label + " has a value";
                        allFields.push({
                            label: lab,
                            value: f.label+"@@"
                        });
                    } else if (f.type === 'select') {
                        f.options.split(",").map(o => {
                            allFields.push({
                                label: f.label + ": "+o+" is chosen",
                                value: f.label+"@@"+o
                            });
                        });
                    } else if (f.type === 'color') {
                        f.colors.map(o => {
                            allFields.push({
                                label: f.label + ": "+o+" is chosen",
                                value: f.label+"@@Color Selected: "+o
                            });
                        });
                    } else if (f.type === 'file') {
                        allFields.push({
                            label: f.label + " is uploaded",
                            value: f.label+"@@"
                        });
                    }
                    else if (f.type === 'image') {
                        f.images.map(o => {
                            allFields.push({
                                label: f.label + ": "+o.title+" is chosen",
                                value: f.label+"@@Image Selected: "+o.title
                            });
                        });
                    }
                });
            }
        }
    });

    const tabs = [
        {
            id: 'products',
            component: <div style={{paddingTop:'20px'}}>
                {loadingProducts && 
                    <p>Loading products, this may take a little while...</p>
                }
                {!loadingProducts &&
                <ChoiceList
                    title="Skip this step if any of these products are selected on previous steps"
                    allowMultiple
                    choices={collectionsProducts.length > 0 ? collectionsProducts : allProducts}
                    onChange={(sel) => {
                        let useProducts = collectionsProducts.length > 0 ? collectionsProducts : allProducts;
                        let newArr = [];
                        sel.map(f => {
                            let exists = allFields.filter(af => af.value === f);
                            let existProds = useProducts.filter(af => af.value === f);
                            if (exists.length > 0 || existProds.length > 0) {
                                newArr.push(f);
                            }
                        });
                        setSkifIf(newArr);
                    }}
                    selected={skipIf}
                />
                }
            </div>,
            content: 'Based on Products',
            panelID: 'products'
        },
        {
            id: 'fields',
            component: <div style={{paddingTop:'20px'}}>
                <ChoiceList
                    title="Skip this step if any of these values are chosen"
                    allowMultiple
                    choices={allFields}
                    onChange={(sel) => {
                        let newArr = [];
                        sel.map(f => {
                            let exists = allFields.filter(af => af.value === f);
                            let existProds = allProducts.filter(af => af.value === f);
                            if (exists.length > 0 || existProds.length > 0) {
                                newArr.push(f);
                            }
                        });
                        setSkifIf(newArr);
                    }}
                    selected={skipIf}
                />
            </div>,
            content: 'Based on Fields',
            panelID: 'fields'
        }
    ];

    const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        shopOrigin: props.shop.info.shop,
    });

    let prevSelected = selectedItems.map(p => {
        let varSelected = p.variants.map(v => {
            return {
                id: 'gid://Shopify/ProductVariant/' + v.id
            }
        })
        return {
            id: 'gid://Shopify/Product/' + p.id,
            variants: varSelected
        }
    });

    const picker = ResourcePicker.create(app, {
        resourceType: ResourcePicker.ResourceType.Product,
        options: {
            selectMultiple: true,
            showHidden: false,
            actionVerb: "select",
            showVariants: true
        }
    });

    const addPicker = ResourcePicker.create(app, {
        resourceType: ResourcePicker.ResourceType.Product,
        options: {
            selectMultiple: true,
            showHidden: false,
            actionVerb: "select",
            showVariants: true
        }
    });

    const collectionPicker = ResourcePicker.create(app, {
        resourceType: ResourcePicker.ResourceType.Collection,
        options: {
            selectMultiple: false,
            showHidden: false,
            actionVerb: "select"
        }
    });

    collectionPicker.subscribe(ResourcePicker.Action.SELECT, ({selection}) => {
        let col = {};
        if (selection.length > 0) {
            let sel = selection[0];
            col = {
                "handle": sel.handle,
                "id": sel.id,
                "title": sel.title || '',
                "sortOrder": sel.sortOrder || '',
                "image": (sel.image) ? sel.image.originalSrc : ''
            }
        }
        let newCol = [];
        newCol[0] = col;
        setCollectionItem(newCol);
        setCollection(col);
        setSelectedItems([]);
    });

    const collPicker = ResourcePicker.create(app, {
        resourceType: ResourcePicker.ResourceType.Collection,
        options: {
            selectMultiple: true,
            showHidden: false,
            actionVerb: "select"
        }
    });

    collPicker.subscribe(ResourcePicker.Action.SELECT, ({selection}) => {
        setSelectedCollections(selection);
    });

    picker.subscribe(ResourcePicker.Action.SELECT, ({selection}) => {
        setSelectedItems(selection);
        setCollection({});
    });

    addPicker.subscribe(ResourcePicker.Action.SELECT, ({selection}) => {
        let items = selectedItems.concat(selection);
        setSelectedItems(items);
    });

    const toastOptions = {
        message: 'Step saved',
        duration: 3000
    };
    const toast = Toast.create(app, toastOptions);

    const addStep = () => {
        setError("");
        if (!title) return setError("Please enter a step title.");
        if (!displayOrder) return setError("Please enter a display order.");
        if (minimum === "") return setError("Please enter a minimum selection.");
        if (maximum === "") return setError("Please enter a maximum selection.");
        if (props.builder.paginated && filterTags && !availableTags) return setError("Please enter the tags to show if you wish to use the tags filter.");
        let colSelected = collectionItem.length > 0 ? (collectionItem[0].handle) ? true : false : false;
        if (selectedItems.length === 0 && !colSelected) return setError("Please add products or a collection to this step.");
        if (filterCollection && selectedCollections.length === 0) return setError("Please add at least 1 collection or disable the collection filter.");
        let products = selectedItems.map(product => {
            let pId = (product.id).split("/")[(product.id).split("/").length-1];
            return {
                "title": product.title,
                "handle": product.handle,
                "images": product.images.length > 0 ? [{originalSrc: product.images[0].originalSrc}] : '',
                "id": pId,
                "gid": product.id,
                "type": product.productType || '',
                "productHideIf": product.productHideIf || [],
                "hideWhenTotalSelected": product.hideWhenTotalSelected || '',
                "variants": product.variants.map((v) => {
                    let vId = (v.id).split("/")[(v.id).split("/").length-1];
                    return {
                        "id": vId
                    }
                })
            };
        });

        let collections = selectedCollections.map(collection => {
            let cId = (collection.id).split("/")[(collection.id).split("/").length-1];
            return {
                "title": collection.title,
                "handle": collection.handle,
                "id": cId
            };
        });
        props.addStep(props.editing, {
            title,
            description,
            columns,
            displayOrder,
            products,
            required,
            minimum,
            maximum,
            type: 'product',
            productDisplay,
            sortby,
            filterCollection,
            filterTitle,
            filterTags,
            selectedCollections: collections,
            quantity,
            skipIf,
            collection: collectionItem[0],
            shortTitle,
            showSummary,
            availableTags,
            hideFromProgress
        });
        //toast.dispatch(Toast.Action.SHOW);
    }

    const removeSelection = (i) => {
        let newItems = selectedItems.filter((item, x) => x !== i);
        setSelectedItems(newItems);
    }

    useEffect(() => {
        if (editing) {
            picker.dispatch(ResourcePicker.Action.SELECT, selectedItems);
        }
    }, []);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          selectedItems,
          result.source.index,
          result.destination.index
        );
        setSelectedItems(items);
    }

    const reorderProducts = (order) => {
        if (order) {
            let items = selectedItems;
            let canOrder = true;
            items.sort(function(a, b) {
                if (order === 'type') {
                    if (a.type === undefined) {
                        canOrder = false;
                    } else {
                        var keyA = a.type.toUpperCase(),
                        keyB = b.type.toUpperCase();
                    }
                } else {
                    var keyA = a.type.toUpperCase(),
                    keyB = b.type.toUpperCase();
                }
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            if (order === 'type' && !canOrder) {
                alert("As this is a new function, you will need to add your items again to order by Product Type.");
            }

            setSelectedItems(items);
            setReorder('');
        }
    }

    const setHideConditions = (indx, product) => {
        setProductHideIf(product.productHideIf || []);
        setHideWhenTotalSelected(product.hideWhenTotalSelected || '');
        setHidingProductIndex(indx);
        setHidingProductModal(true);
    }

    const saveHideConditions = () => {
        let selectedProducts = [...selectedItems];
        selectedProducts[hidingProductIndex].productHideIf = productHideIf;
        selectedProducts[hidingProductIndex].hideWhenTotalSelected = hideWhenTotalSelected;
        setSelectedItems(selectedProducts);
        setHidingProductModal(false);
        setProductHideIf([]);
        setHideWhenTotalSelected('');
    }

    const pTabs = [
        {
            id: 'collection',
            component: <div style={{paddingTop:'20px'}}>
                <Button onClick={() => {
                    if (selectedItems.length > 0) {
                        setConfirmCollection(true)
                    } else {
                        collectionPicker.dispatch(ResourcePicker.Action.OPEN);
                    }
                }}>
                   Pick Collection
                </Button>
                {collectionItem.length > 0 && collectionItem[0].handle &&
                <div style={{paddingTop:'15px'}}>
                    <ResourceList
                        resourceName={{singular: 'Collection', plural: 'Collection'}}
                        items={collectionItem}
                        renderItem={(item, itemId, i) => {
                            const { id, title, image } = item;
                            return (
                                <ResourceItem
                                    id={id}
                                    media={<Thumbnail alt={title} source={image || 'https://builder-front.boxup.io/img/placeholder.png'}/>}
                                    shortcutActions={[
                                        {
                                          content: 'Remove',
                                          accessibilityLabel: 'Remove',
                                          onAction: () => setCollectionItem({}),
                                        }
                                    ]}
                                    persistActions
                                    >
                                    <h3>
                                        <TextStyle variation="strong">{title} selected.</TextStyle>
                                    </h3>
                                    <div>Products will load in the order defined in the collection.</div>

                                </ResourceItem>
                            );
                        }}
                    />
                </div>
                }
            </div>,
            content: 'Use Collection',
            panelID: 'collection'
        }
    ];

    if (!props.builder.paginated) {
        pTabs.push(
            {
                id: 'product',
                component: <div style={{paddingTop:'20px'}}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef}>
                                <ResourceList
                                    resourceName={{singular: 'Product', plural: 'Products'}}
                                    items={selectedItems}
                                    renderItem={(item, itemId, i) => {
                                        const shortcutActions = [{content: 'Remove', onAction: () => removeSelection(i)}];
                                        if (displayOrder > 1) shortcutActions.push({ content: `Hide Options (${(item.productHideIf) ? item.productHideIf.length : 0})`, onAction: () => setHideConditions(i, item)});
                                        const { id, images, title, variants } = item;
                                        const media = (images.length > 0) ? <Thumbnail
                                            source={images[0].originalSrc}
                                            alt={title}
                                        />
                                        : <Avatar size="medium" initials="NA" />;
                                        return (
                                            <Draggable key={id} draggableId={id} index={i}>
                                                {(provided, snapshot) => (
                                                    <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    >
                                                    <ResourceItem
                                                        
                                                        id={id}
                                                        media={media}
                                                        shortcutActions={shortcutActions}
                                                        persistActions
                                                        >
                                                        <h3>
                                                            <TextStyle variation="strong">{title}</TextStyle>
                                                        </h3>
                                                        <div>{`${variants.length} variant(s) available for selection.`}</div>
                                                    </ResourceItem>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    }}
                                />
                                {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>,
                content: 'Use Selected Products',
                panelID: 'product'
            }
        )
    }

    const hideOptionTabs = [
        {
            id: 'whenProductSelected',
            component: <div style={{paddingTop:'20px'}}>
                <ChoiceList
                    title="Hide this product if any of these products are selected on previous steps."
                    allowMultiple
                    choices={allProducts}
                    onChange={(sel) => {
                        let newArr = [];
                        sel.map(f => {
                            let exists = allFields.filter(af => af.value === f);
                            let existProds = allProducts.filter(af => af.value === f);
                            if (exists.length > 0 || existProds.length > 0) {
                                newArr.push(f);
                            }
                        });
                        setProductHideIf(newArr);
                    }}
                    selected={productHideIf}
                />
            </div>,
            content: 'Use Selected Products',
            panelID: 'whenProductSelected'
        },
        {
            id: 'totalSelected',
            component: <div style={{paddingTop:'20px'}}>
                <div style={{marginBottom:'20px'}}>
                <Banner
                    title="This only applies to the steps prior to this one."
                    status="info"
                    >
                    <p>We will count all the products selected on previous steps to see if it is equal or greater to the number entered.</p>
                </Banner>
                </div>
                <TextField
                    label="Number of selections required to hide this product."
                    value={hideWhenTotalSelected}
                    onChange={(val) => setHideWhenTotalSelected(val)}
                    type="number"
                    placeholder="e.g. enter '5' to hide this product once 5 items are selected by the customer."
                />
            </div>,
            content: 'Selection Limit',
            panelID: 'totalSelected'
        }
    ];

    const loadProducts = async() => {
        setLoadingProducts(true);
        let products = [];
        if (collectionsProducts.length === 0) {
            for await (const collection of allCollections) {
                let collectionId = collection.id.split("/")[collection.id.split("/").length-1];
                let prods = await collectionProducts(props.shop.info.id, collectionId);
                prods.map(p => {
                    products.push({
                        label: p.productName + " - " + p.variantTitle,
                        value: p.variantId.toString()
                    });
                });
                setCollectionsProducts(products);
            }
        }
        setLoadingProducts(false);
    }
    
    return (
        <>
        <Card 
            title="Adding a Product Step" 
            sectioned
            secondaryFooterActions={[{
                content: 'Back',
                onAction: () => props.hideForm(false),
                loading: false
            }]}
            primaryFooterAction={{
                content: 'Save Step',
                onAction: () => addStep(),
                loading: false
            }}
        >
            <Card.Section>
                <Form>
                    <FormLayout>
                        {error !== "" &&
                        <InlineError message={error} />
                        }
                        <TextField
                            label="Step Title"
                            value={title}
                            onChange={(val) => setTitle(val)}
                            type="text"
                            placeholder="e.g. Select Your Hard Drive"
                        />
                        <TextField
                            label="Step Description"
                            value={description}
                            onChange={(val) => setDescription(val)}
                            type="text"
                            placeholder="e.g. You can only select one hard drive "
                            helpText={
                                <span>
                                Additional text to help the customer understand what's required from this step.
                                </span>
                            }
                        />
                        <TextField
                            label="Step Short Title"
                            value={shortTitle}
                            onChange={(val) => setShortTitle(val)}
                            type="text"
                            placeholder="e.g. Gift Box "
                            helpText={
                                <span>
                                If you're using the step progress bar, you can add a shorter title for this step to show there.
                                </span>
                            }
                        />
                        <Checkbox
                            label="Allow more than 1 of the same product to be selected"
                            checked={quantity}
                            onChange={setQuantity}
                            helpText={
                                <span>
                                The maximum quantity allowed, per item, will take into account your settings below.
                                </span>
                            }
                        />
                        <FormLayout.Group>
                            <TextField
                                label="Display Order"
                                type="number"
                                placeholder="e.g. 1"
                                value={displayOrder}
                                onChange={(val) => setDisplayOrder(val)}
                                helpText={
                                    <span>
                                    Set the order the step will display within this builder.
                                    </span>
                                }
                            />
                            <TextField
                                label="Minimum Selections"
                                type="number"
                                placeholder="e.g. 1"
                                value={minimum}
                                onChange={(val) => setMinimum(val)}
                                helpText={
                                    <span>
                                    How many products does the customer have to select on this step?
                                    </span>
                                }
                            />
                            <TextField
                                label="Maximum Selections"
                                type="number"
                                placeholder="e.g. 1"
                                value={maximum}
                                onChange={(val) => setMaximum(val)}
                                helpText={
                                    <span>
                                    Can the customer select more than 1 product on this step?
                                    </span>
                                }
                            />
                            <hr/>
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="A Required Step"
                                checked={required}
                                onChange={setRequired}
                                helpText={
                                    <span>
                                    Requires at least 1 item selection from the customer
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show Box Contents/Summary"
                                checked={showSummary}
                                onChange={setShowSummary}
                                helpText={
                                    <span>
                                    This will show the currently selected products below the step title.
                                    </span>
                                }
                            />
                            {props.builder.theme === 'full' &&
                            <Select
                                label="Products per row"
                                options={[
                                    {label: '3', value: '4'},
                                    {label: '4', value: '3'}
                                ]}
                                onChange={(val) => setColumns(val)}
                                value={columns}
                                helpText=""
                            />
                            }
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Hide from Step Progress"
                                checked={hideFromProgress}
                                onChange={setHideFromProgress}
                                helpText={
                                    <span>
                                    Hide this step from the list of steps in the progress bar.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Stack distribution="fill">
                                <RadioButton
                                    label="Show each variant separately"
                                    helpText="Each variant will have it's own item available for selection."
                                    checked={productDisplay === 'variant'}
                                    id="variant"
                                    name="variant"
                                    onChange={() => setProductDisplay('variant')}
                                />
                                <RadioButton
                                    label="Show a variant select box beneath product"
                                    helpText="One item per product with a dropdown menu for the variant"
                                    id="product"
                                    name="product"
                                    checked={productDisplay === 'product'}
                                    onChange={() => setProductDisplay('product')}
                                />
                            </Stack>
                        </FormLayout.Group>
                        <hr/>
                        {!props.builder.paginated &&
                        <ButtonGroup>
                            <Button onClick={() => picker.dispatch(ResourcePicker.Action.OPEN)}>Choose selectable products for this step</Button>
                            <Button onClick={() => addPicker.dispatch(ResourcePicker.Action.OPEN)}>Add more products</Button>
                        </ButtonGroup>
                        }
                        <FormLayout.Group>
                            {displayOrder !== "" && Number(displayOrder) > 1 &&
                                <Button
                                    onClick={() => {
                                        setSkip(!skip);
                                        if (!skip) {
                                            loadProducts();
                                        }
                                    }}
                                    ariaExpanded={skip}
                                    ariaControls="set-skip"
                                >
                                    Set conditions that will skip this step
                                </Button>
                            }
                        </FormLayout.Group>
                        {!props.builder.paginated &&
                        <>
                        <Banner
                            title="Link a collection to products you wish to show"
                            status="info"
                            >
                            <p>Products with no collections will not show. If you have products like this, simply create a collection and attach all products before completing this step.</p>
                        </Banner>
                        <hr/>
                        </>
                        }
                        
                        <Tabs tabs={pTabs} selected={ptab} onSelect={setpTab} fitted>
                            {pTabs[ptab].component}
                        </Tabs>
                        <hr/>
                        <FormLayout.Group>
                            <Checkbox
                                label="Show sort by drop down menu"
                                checked={sortby}
                                onChange={setSortby}
                                helpText={
                                    <span>
                                    Allows customers to sort by product title, collections and price
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show title filter"
                                checked={filterTitle}
                                onChange={setFilterTitle}
                                helpText={
                                    <span>
                                    Allows customers to filter by product title for this step
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Show collection filter"
                                checked={filterCollection}
                                onChange={setFilterCollection}
                                helpText={
                                    <span>
                                    Allows customers to filter by collection for this step
                                    </span>
                                }
                            />
                            {filterCollection &&
                            <>
                            <p>{selectedCollections.length} collection(s) selected</p>
                            <Button onClick={() => collPicker.dispatch(ResourcePicker.Action.OPEN)}>
                                Set collections for the collection filter
                            </Button>
                            </>
                            }
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Show tags filter"
                                checked={filterTags}
                                onChange={setFilterTags}
                                helpText={
                                    <span>
                                    Allows customers to sort by product tags
                                    </span>
                                }
                            />
                            {filterTags &&
                            <TextField
                                label={props.builder.paginated 
                                    ? "Set the tags to show in the drop down menu" 
                                    : "Limit to these tags only (optional)"}
                                type="text"
                                placeholder="e.g. Bags, Shoes, Necklaces"
                                value={availableTags}
                                onChange={(val) => setAvailableTags(val)}
                                helpText={
                                    props.builder.paginated 
                                    ? <span>A comma-separated list of tags to show within the drop down menu.</span>
                                    : <span>
                                    A comma-separated list of tags to show. If used, all other tags will be hidden. Please make sure to capitalize tags where necessary.
                                    </span>
                                }
                            />
                            }
                        </FormLayout.Group>
                    </FormLayout>
                </Form>
            </Card.Section>
        </Card>
        <div style={{height: '500px'}}>
            <Modal
                open={skip}
                onClose={() => setSkip(false)}
                title="Set conditions that will skip this step"
                secondaryActions={[
                    {
                      content: 'Close',
                      onAction: () => setSkip(false)
                    },
                ]}
            >
                <Modal.Section>
                    <Tabs tabs={tabs} selected={tab} onSelect={setTab} fitted>
                        {tabs[tab].component}
                    </Tabs>
                </Modal.Section>
            </Modal>
        </div>
        <div style={{height: '500px'}}>
            <Modal
                open={confirmCollection}
                onClose={() => setConfirmCollection(false)}
                title="Are you sure?"
                primaryAction={{
                    content: 'Okay',
                    onAction: () => {
                        setConfirmCollection(false);
                        collectionPicker.dispatch(ResourcePicker.Action.OPEN);
                    },
                }}
                secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: () => {
                        setConfirmCollection(false);
                    },
                },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>
                        Selecting a collection will remove any selected individual 
                        products and only use products in the collection you choose.
                        </p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
        <div style={{height: '500px'}}>
            <Modal
                open={hidingProductModal}
                onClose={() => setHidingProductModal(false)}
                title="When To Hide This Product "
                primaryAction={{
                    content: 'Set Conditions',
                    onAction: () => {
                        saveHideConditions();
                    },
                }}
                secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: () => {
                        setHidingProductModal(false);
                    },
                },
                ]}
            >
                <Modal.Section>
                    <Tabs tabs={hideOptionTabs} selected={hideTab} onSelect={setHideTab} fitted>
                        {hideOptionTabs[hideTab].component}
                    </Tabs>
                </Modal.Section>
            </Modal>
        </div>
        </>
    );
});

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(withRouter(ProductStep));	