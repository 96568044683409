import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
    Form, FormLayout, TextField, Button, Modal, Card,
    Select, DataTable, Checkbox, InlineError, DatePicker, 
    RadioButton, Stack, ChoiceList, DropZone, Thumbnail,
    ResourceList, ResourceItem, Banner, OptionList, Tabs
} from '@shopify/polaris';
import { createApp } from '@shopify/app-bridge';
import { setupModalAutoSizing } from '@shopify/app-bridge-utils';
import { Toast } from '@shopify/app-bridge/actions';
import { ChromePicker } from 'react-color';
import { resizeImage, collectionProducts } from './../../../actions';
import { storage } from './../../../actions/settings';

var randomstring = require('randomstring');

////////////////////
// Bulky Builder
// Store uses this screen when setting up/editing a Form step
// A store can add as many different types of form fields as they like.
////////////////////

const FormStep = (props) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [modal, openModal] = useState(false);
    const [editing] = useState(props.editing !== null ? props.builder.steps[props.editing] : {});
    const [title, setTitle] = useState(editing.title || '');
    const [description, setDescription] = useState(editing.description || '');
    const [shortTitle, setShortTitle] = useState(editing.shortTitle || '');
    const [displayOrder, setDisplayOrder] = useState(editing.displayOrder || '');
    const [required, setRequired] = useState((editing.required) === undefined  ? false : editing.required);
    const [fields, setFields] = useState(editing.fields || []);
    const [label, setLabel] = useState('');
    const [type, setType] = useState('text');
    const [options, setOptions] = useState("");
    const [fieldDisplay, setOrder] = useState('');
    const [fieldRequired, setFieldRequired] = useState(false);
    const [modalError, setModalError] = useState('');
    const [condition, setCondition] = useState('all');
    const [conditionProducts, setConditionProducts] = useState([]);

    const [datePicker, setDatePicker] = useState(false);
    const [blockDates, setBlockDates] = useState(false);
    const [blockDays, setBlockDays] = useState([]);
    const [blockedWeekdays, setBlockedWeekdays] = useState([]);
    const [blockedDate, setBlockedDate] = useState(null);
    const [leadTime, setLeadTime] = useState('');
    const [datePickerMonth, setMonth] = useState((new Date()).getMonth());
    const [datePickerYear, setYear] = useState((new Date()).getFullYear());
    const [dateLanguage, setDateLanguage] = useState('en-GB');
    const [dateFormat, setDateFormat] = useState('mm/dd/yyyy');

    const [skip, setSkip] = useState(false);
    const [skipIf, setSkifIf] = useState(editing.skipIf || []);
    const [colors, setColors] = useState([]);
    const [color, setColor] = useState('#000000');
    const [images, setImages] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [fileType, setFileType] = useState('any');
    const [tab, setTab] = useState(0);
    const [minLength, setMinLength] = useState(editing.minLength || '');
    const [maxLength, setMaxLength] = useState(editing.maxLength || '');
    const [editField, setEditField] = useState('');
    const [showSummary, setShowSummary] = useState((editing.showSummary) === undefined  ? false : editing.showSummary);
    const [hideFromProgress, setHideFromProgress] = useState(editing.hideFromProgress === undefined  ? false : editing.hideFromProgress);

    const [collectionsProducts, setCollectionsProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(false);

    const allFields = [], allCollections = [], tempProducts = [];
    props.builder.steps.map((s, x) => {
        if (Number(s.displayOrder) < Number(displayOrder)) {
            if (s.type === "product") {
                s.products.map(p => {
                    p.variants.map((v, i) => {
                        let index = i+1;
                        let vId = (v.id).split("/")[(v.id).split("/").length-1];
                        let step = x+1;
                        tempProducts.push({
                            label: "Step " + step + " - " + p.title + " - variant " + index,
                            value: vId
                        });
                    });
                });
                if (s.collection) {
                    allCollections.push(s.collection);
                }
            }
        }
    });

    useEffect(() => {
        setAllProducts(tempProducts);
    }, []);

    props.builder.steps.map((s, i) => {
        if (Number(s.displayOrder) < Number(displayOrder)) {
            if (s.type === "form") {
                s.fields.map(f => {
                    let val = '', lab = '';
                    if (f.type === 'checkbox') {
                        lab = f.label + " is checked";
                        allFields.push({
                            label: lab,
                            value: f.label+"@@true"
                        });
                    } else if (f.type === 'text' || f.type === 'number' || f.type === 'date') {
                        lab = f.label + " has a value";
                        allFields.push({
                            label: lab,
                            value: f.label+"@@"
                        });
                    } else if (f.type === 'select') {
                        f.options.split(",").map(o => {
                            allFields.push({
                                label: f.label + ": "+o+" is chosen",
                                value: f.label+"@@"+o
                            });
                        });
                    } else if (f.type === 'color') {
                        f.colors.map(o => {
                            allFields.push({
                                label: f.label + ": "+o+" is chosen",
                                value: f.label+"@@Color Selected: "+o
                            });
                        });
                    } else if (f.type === 'file') {
                        allFields.push({
                            label: f.label + " is uploaded",
                            value: f.label+"@@"
                        });
                    }
                    else if (f.type === 'image') {
                        f.images.map(o => {
                            allFields.push({
                                label: f.label + ": "+o.title+" is chosen",
                                value: f.label+"@@Image Selected: "+o.title
                            });
                        });
                    }
                });
            }
        }
    });

    const tabs = [
        {
            id: 'products',
            component: <div style={{paddingTop:'20px'}}>
                {loadingProducts && 
                    <p>Loading products, this may take a little while...</p>
                }
                {!loadingProducts &&
                <ChoiceList
                    title="Skip this step if any of these products are selected on previous steps"
                    allowMultiple
                    choices={collectionsProducts.length > 0 ? collectionsProducts : allProducts}
                    onChange={(sel) => {
                        let newArr = [];
                        let useProducts = collectionsProducts.length > 0 ? collectionsProducts : allProducts;
                        sel.map(f => {
                            let exists = allFields.filter(af => af.value === f);
                            let existProds = useProducts.filter(af => af.value === f);
                            if (exists.length > 0 || existProds.length > 0) {
                                newArr.push(f);
                            }
                        });
                        setSkifIf(newArr);
                    }}
                    selected={skipIf}
                />
                }
            </div>,
            content: 'Based on Products',
            panelID: 'products'
        }
        ,
        {
            id: 'fields',
            component: <div style={{paddingTop:'20px'}}>
                <ChoiceList
                    title="Skip this step if any of these values are chosen"
                    allowMultiple
                    choices={allFields}
                    onChange={(sel) => {
                        let newArr = [];
                        sel.map(f => {
                            let exists = allFields.filter(af => af.value === f);
                            let existProds = allProducts.filter(af => af.value === f);
                            if (exists.length > 0 || existProds.length > 0) {
                                newArr.push(f);
                            }
                        });
                        setSkifIf(newArr);
                    }}
                    selected={skipIf}
                />
            </div>,
            content: 'Based on Fields',
            panelID: 'fields'
        }
    ];

    const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        shopOrigin: props.shop.info.shop,
    });

    setupModalAutoSizing(app);

    const toastOptions = {
        message: 'Step saved',
        duration: 3000
    };
    const toast = Toast.create(app, toastOptions);

    const showError = (msg) => {
        setError(msg);
        const errToastOptions = {
            message: msg,
            duration: 3000,
            isError: true
        };
        const errToast = Toast.create(app, errToastOptions);
        errToast.dispatch(Toast.Action.SHOW);
    }

    const addStep = () => {
        setError("");
        if (!title) return showError("Please enter a step title.");
        if (!displayOrder) return showError("Please enter a display order.");
        if (fields.length === 0) return showError("Please add at least 1 field.");

        props.addStep(props.editing, {
            title,
            description,
            displayOrder,
            required,
            type: 'form',
            fields,
            skipIf,
            shortTitle,
            showSummary,
            hideFromProgress
        });
        toast.dispatch(Toast.Action.SHOW);
    }

    const removeIndex = (index) => {
        let newFields = fields.filter((s,i) => i !== index);
        setFields(newFields);
    }

    const uploadImages = (useImages) => {
        return new Promise((resolve) => {
            for (let x = 0, p = Promise.resolve(); x < useImages.length; x++) {
                p = p.then(_ => new Promise(async(pResolve) => {
                    if (useImages[x].file) {
                        let image = useImages[x];
                        var file = image.file;
                        var newFilename = randomstring.generate(24);
                        var storageRef = storage.ref(`builders/${props.builder.builderId}/${newFilename}`);
                        try {
                            let blob = await resizeImage(file, 400);
                            await storageRef.put(blob);
                            let url = await storageRef.getDownloadURL();
                            delete useImages[x]['file'];
                            useImages[x].source = url;
                            pResolve();
                        } catch(e) {
                            console.log(e);
                            setError(`Unable to upload ${x}, please try again.`);
                            resolve(useImages);
                        }
                    } else {
                        pResolve();
                    }
                    if (x === useImages.length-1) resolve(useImages);
                }));
            }
        });
    }

    const addField = async() => {
        if (label === "") return showError("Please enter a field title.");
        if (type === "") return showError("Please enter a field type.");
        if (type === "select" && options.length === 0) return showError("Please enter options for the dropdown menu.");
        if (fieldDisplay === "") return showError("Please enter a field display order.");
        if (condition === 'product' && conditionProducts.length === 0) return showError("Please select a product to base this field's condition on.");
        if (type === 'color' && colors.length === 0) return showError("Please add at least 1 color swatch.");
        if (type === 'image' && images.length === 0) return showError("Please upload at least 1 image");
        let noImages = images.filter(image => !image.title).length
        if (noImages > 0) return showError("Please enter an image name for all images.");

        let useImages = images;
        if (type === 'image') {
            setLoading(true);
            useImages = await uploadImages(useImages);
            setLoading(false);
        }

        let thisField = {
            label,
            type,
            options,
            displayOrder: fieldDisplay,
            fieldRequired,
            condition,
            conditionProducts,
            colors,
            images: useImages,
            fileType,
            maxLength,
            minLength,
            blockDates,
            blockDays,
            blockedWeekdays,
            blockedDate,
            dateLanguage,
            dateFormat,
            leadTime
        };

        if (editField === '') {
            setFields([...fields, thisField]);
        } else {
            let f = fields;
            f[editField] = thisField;
            setFields(f);
            setEditField('');
        }

        openModal(false);
        setError("");
        setType('text');
        setFieldRequired(false);
        setOptions('');
        setOrder('');
        setMinLength('');
        setMaxLength('');
        setLabel('');
        setFileType('any');
        setColors([]);
        setImages([]);
        setCondition('all');
        setConditionProducts([]);
    }

    const addColor = () => {
        let oldColors = colors;
        setColors([...oldColors, color]);
    }

    const removeColor = (i) => {
        let newColors = colors.filter((c,x) => x !== i);
        setColors(newColors);
    }

    const handleDropZoneDrop = useCallback(
        (_droppedFiles, acceptedFiles, rejectedFiles) => {
            let newFiles = acceptedFiles.map(file => {
                return {
                    file: file,
                    source: window.URL.createObjectURL(file),
                    title: ''
                }
            })
            setImages((images) => [...images, ...newFiles]);
            setRejectedFiles(rejectedFiles);
        },
        [],
    );

    const setImageName = (i, val) => {
        let newImages = [...images];
        newImages[i].title = val;
        setImages(newImages);
    }

    const removeImage = (index) => {
        let newImages = [...images];
        let useImages = newImages.filter((i,x) => x !== Number(index));
        setImages(useImages);
    }

    const handleMonthChange = useCallback(
        (month, year) => {
            setMonth(month);
            setYear(year);
        },
        [],
    );

    const addBlockedDate = () => {
        if (blockedDate) {
            if (blockDays.filter(a => a.start.toString().substr(0,15) === blockedDate.start.toString().substr(0,15)).length === 0) {
                setBlockDays([...blockDays, blockedDate]);
            }
            setBlockedDate(null);
            setDatePicker(false);
        }
    }
    
    const removeBlockedDay = (indx) => {
        let newDays = blockDays.filter((d,i) => i !== indx);
        setBlockDays(newDays);
    }

    const loadProducts = async() => {
        setLoadingProducts(true);
        let products = [];
        if (collectionsProducts.length === 0) {
            for await (const collection of allCollections) {
                let collectionId = collection.id.split("/")[collection.id.split("/").length-1];
                let prods = await collectionProducts(props.shop.info.id, collectionId);
                prods.map(p => {
                    products.push({
                        label: p.productName + " - " + p.variantTitle,
                        value: p.variantId.toString()
                    });
                });
                setCollectionsProducts(products);
                setAllProducts(products);
            }
        }
        setLoadingProducts(false);
    }

    return (
        <>
        <Card 
            title="Adding a Form Step" 
            sectioned
            secondaryFooterActions={[{
                content: 'Cancel Step',
                onAction: () => props.hideForm(false),
                loading: false
            }]}
            primaryFooterAction={{
                content: 'Save Step',
                onAction: () => addStep(),
                loading: false
            }}
        >
            <Card.Section>
                <Form>
                    <FormLayout>
                        {error !== "" &&
                        <InlineError message={error} />
                        }
                        <TextField
                            label="Step Title"
                            value={title}
                            onChange={(val) => setTitle(val)}
                            type="text"
                            placeholder="e.g. Some information about you"
                        />
                        <TextField
                            label="Step Description"
                            value={description}
                            onChange={(val) => setDescription(val)}
                            type="text"
                            placeholder="e.g. You can personalize this order by providing your information below. "
                            helpText={
                                <span>
                                Additional text to help the customer understand what's required from this step.
                                </span>
                            }
                        />
                        <TextField
                            label="Step Short Title"
                            value={shortTitle}
                            onChange={(val) => setShortTitle(val)}
                            type="text"
                            placeholder="e.g. Gift Box "
                            helpText={
                                <span>
                                If you're using the step progress bar, you can add a shorter title for this step to show there.
                                </span>
                            }
                        />
                        <FormLayout.Group>
                            <TextField
                                label="Display Order"
                                type="number"
                                placeholder="e.g. 1"
                                value={displayOrder}
                                onChange={(val) => setDisplayOrder(val)}
                                helpText={
                                    <span>
                                    Set the order the step will display within this builder.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="A Required Step"
                                checked={required}
                                onChange={setRequired}
                                helpText={
                                    <span>
                                    Requires at least 1 item selection from the customer
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show Box Contents/Summary"
                                checked={showSummary}
                                onChange={setShowSummary}
                                helpText={
                                    <span>
                                    This will show the currently selected products below the step title.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Hide from Step Progress"
                                checked={hideFromProgress}
                                onChange={setHideFromProgress}
                                helpText={
                                    <span>
                                    Hide this step from the list of steps in the progress bar.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            {displayOrder !== "" && Number(displayOrder) > 1 &&
                            <>
                                <Button
                                    onClick={() => {
                                        setSkip(!skip);
                                        if (!skip && props.builder.paginated) {
                                            loadProducts();
                                        }
                                    }}
                                    ariaExpanded={skip}
                                    ariaControls="set-skip"
                                >
                                    Set conditions that will skip this step
                                </Button>
                            </>
                            }
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Button onClick={() => openModal(true)}>Add a field</Button>
                        </FormLayout.Group>
                        <Card.Section>
                            <DataTable
                                columnContentTypes={[
                                    'text',
                                    'numeric',
                                    'text',
                                    'text',
                                    'text'
                                ]}
                                headings={[
                                    'Field',
                                    'Type',
                                    'Order',
                                    'Edit',
                                    'Remove',
                                ]}
                                rows={fields.map((i, x) => {
                                    return [
                                        i.label, 
                                        i.type, 
                                        i.displayOrder,
                                        <Button onClick={() => {
                                            setType(i.type || 'text');
                                            setFieldRequired(i.fieldRequired || false);
                                            setOptions(i.options || '');
                                            setOrder(i.displayOrder || '');
                                            setMinLength(i.minLength || '');
                                            setMaxLength(i.maxLength || '');
                                            setLabel(i.label || '');
                                            setFileType(i.fileType || 'any');
                                            setColors(i.colors || []);
                                            setImages(i.images || []);
                                            setCondition(i.condition || 'all');
                                            setConditionProducts(i.conditionProducts || []);
                                            setEditField(x);
                                            openModal(true);
                                            setLeadTime(i.leadTime || '');
                                            setBlockDates(i.blockDates || false);
                                            setBlockDays(i.blockDays || []);
                                            setBlockedWeekdays(i.blockedWeekdays || []);
                                            setBlockedDate(i.blockedDate || null);
                                            setDateLanguage(i.dateLanguage || 'en-GB');
                                            setDateFormat(i.dateFormat || 'mm/dd/yyyy');
                                        }} size="slim">Edit</Button>,
                                        <Button onClick={() => removeIndex(x)} destructive size="slim">Remove</Button>
                                    ]
                                })}
                                footerContent={`${fields.length} fields added`}
                            />
                        </Card.Section>
                    </FormLayout>
                </Form>
            </Card.Section>
        </Card>
        <div style={{height: '500px'}}>
            <Modal
                open={modal}
                onClose={() => openModal(false)}
                title="Add a field"
                primaryAction={{
                    content: 'Add Field',
                    onAction: addField,
                    loading: loading
                  }}
                  secondaryActions={[
                    {
                      content: 'Cancel',
                      onAction: () => openModal(false),
                      loading: loading
                    },
                ]}
            >
                <Modal.Section>
                    <Form>
                        <FormLayout>
                            <Select
                                label="Field Type"
                                options={[
                                    {label: 'Text Entry', value: 'text'},
                                    {label: 'Text Entry (multi-line)', value: 'textarea'},
                                    {label: 'Dropdown Box', value: 'select'},
                                    {label: 'Number Entry', value: 'number'},
                                    {label: 'Date Entry', value: 'date'},
                                    {label: 'Custom Date Picker', value: 'custom-date'},
                                    {label: 'Swatch Color Picker', value: 'color'},
                                    {label: 'Image Selection', value: 'image'},
                                    {label: 'File/Image Upload', value: 'file'},
                                    {label: 'Checkbox', value: 'checkbox'},
                                    {label: 'Color Picker', value: 'color-picker'}
                                ]}
                                onChange={setType}
                                value={type}
                            />
                            {type === "select" && 
                            <TextField
                                label="Dropdown Options"
                                type="text"
                                placeholder="e.g. Yes, No, Maybe"
                                value={options}
                                onChange={(val) => setOptions(val)}
                                helpText={
                                    <span>
                                    A comma separated list of options for the dropdown box.
                                    </span>
                                }
                            />
                            }
                            {(type === "textarea" || type === "text") && 
                            <FormLayout.Group>
                                <TextField
                                    label="Min Length (optional)"
                                    type="number"
                                    placeholder="e.g. 5"
                                    value={minLength}
                                    onChange={(val) => setMinLength(val)}
                                    helpText={
                                        <span>
                                        Leave blank if no minimum required.
                                        </span>
                                    }
                                />
                                <TextField
                                    label="Max Length (optional)"
                                    type="number"
                                    placeholder="e.g. 10"
                                    value={maxLength}
                                    onChange={(val) => setMaxLength(val)}
                                    helpText={
                                        <span>
                                        Leave blank if no maximum required.
                                        </span>
                                    }
                                />
                            </FormLayout.Group>
                            }
                            <TextField
                                label="Field Title"
                                type="text"
                                placeholder="e.g. Would you like to engrave this product?"
                                value={label}
                                onChange={(val) => setLabel(val)}
                                helpText={
                                    <span>
                                    Text to appear above the field.
                                    </span>
                                }
                            />
                            {type === "file" &&
                            <>
                            <Banner
                                title="Shopify only allow 1 file upload per field."
                                status="info"
                                >
                                <p>Add more file fields for more uploads.</p>
                            </Banner>
                            <Select
                                label="File Type"
                                options={[
                                    {label: 'Any', value: 'any'},
                                    {label: 'Only Images', value: 'image'}
                                ]}
                                onChange={setFileType}
                                value={fileType}
                            />
                            </>
                            }
                            {type === "color" &&
                            <div style={{width: '100%', marginTop: '30px', marginBottom: '30px'}}>
                                <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <ChromePicker color={color} onChange={(c) => setColor(c.hex)}/>
                                </div>
                                <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <Button onClick={() => addColor()} destructive size="slim">Add Selected Color</Button>
                                    <div style={{marginTop: '20px', maxWidth: '100%'}}>
                                        {colors.map((color, i) => {
                                            let cObj = color;
                                            if (typeof color !== 'object') {
                                                cObj = {
                                                    color: color,
                                                    name: ''
                                                }
                                            }
                                            return <React.Fragment key={i}>
                                                <div style={{display: 'table'}}>
                                                    <div className="swatch-color" style={{backgroundColor: cObj.color, display: 'table-cell'}}>
                                                        <div className="remove-color"><a onClick={() => removeColor(i)}>X</a></div>
                                                    </div>
                                                    <div style={{display: 'table-cell'}}>
                                                        <TextField
                                                            label="Color Name"
                                                            type="text"
                                                            placeholder="e.g. Red"
                                                            value={cObj.name}
                                                            onChange={(val) => {
                                                                let current = [...colors];
                                                                let clr =  (typeof current[i] === 'object') ? current[i].color : current[i];
                                                                current[i] = {
                                                                    color: clr,
                                                                    name: val
                                                                };
                                                                setColors(current);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        })}
                                    </div>
                                </div>
                            </div>
                            }
                            {type === "image" &&
                            <>
                                <hr/>
                                <DropZone 
                                    label="Upload the images the customer can choose from" 
                                    accept="image/*" type="image" 
                                    allowMultiple={true} onDrop={handleDropZoneDrop}
                                >
                                    <DropZone.FileUpload />
                                </DropZone>
                                <ResourceList
                                    resourceName={{singular: 'image', plural: 'images'}}
                                    items={images}
                                    renderItem={(item, i) => {
                                        const media = <Thumbnail
                                            source={item.source}
                                            alt={item.title}
                                        />;
                                        
                                        return (
                                            <ResourceItem
                                                id={i}
                                                media={media}
                                                shortcutActions={[
                                                    {
                                                      content: 'Remove',
                                                      onAction: () => removeImage(i)
                                                    }
                                                ]}
                                                persistActions
                                            >
                                                <TextField
                                                    placeholder="Image name"
                                                    type="text"
                                                    value={item.title}
                                                    onChange={(val) => setImageName(i, val)}
                                                    helpText={
                                                        <span>
                                                        The name appears on the order details, not the image.
                                                        </span>
                                                    }
                                                />
                                            </ResourceItem>
                                        );
                                    }}
                                />
                                <hr/>
                            </>
                            }
                            {type === "custom-date" &&
                            <>
                                <div style={{marginBottom: '20px'}}>
                                    <div style={{marginLeft: '-2rem', marginBottom: '20px'}}>
                                    <FormLayout.Group>
                                        <Select
                                            label="Date Format"
                                            options={[
                                                {value: 'mm/dd/yyyy', label: 'mm/dd/yyyy'},
                                                {value: 'dd/mm/yyyy', label: 'dd/mm/yyyy'},
                                                {value: 'yyyy/mm/dd', label: 'yyyy/mm/dd'},
                                            ]}
                                            onChange={setDateFormat}
                                            value={dateFormat}
                                        />
                                        <Select
                                            label="Language"
                                            options={[
                                                {value: 'ar-tn', label: 'Arabic-Tunisia'},
                                                {value: 'ar', label: 'Arabic'},
                                                {value: 'az', label: 'Azerbaijani'},
                                                {value: 'bg', label: 'Bulgarian'},
                                                {value: 'bm', label: 'Bamanankan'},
                                                {value: 'bn', label: 'Bengali (Bangla)'},
                                                {value: 'br', label: 'Breton'},
                                                {value: 'bs', label: 'Bosnian'},
                                                {value: 'ca', label: 'Catalan'},
                                                {value: 'cs', label: 'Czech'},
                                                {value: 'cy', label: 'Welsh'},
                                                {value: 'da', label: 'Danish'},
                                                {value: 'de', label: 'German'},
                                                {value: 'el', label: 'Greek'},
                                                {value: 'en-AU', label: 'Australian English'},
                                                {value: 'en-CA', label: 'Canadian English'},
                                                {value: 'en-GB', label: 'British/US English'},
                                                {value: 'en-IE', label: 'Irish English'},
                                                {value: 'en-NZ', label: 'New Zealand English'},
                                                {value: 'en-ZA', label: 'South African English'},
                                                {value: 'eo', label: 'Esperanto'},
                                                {value: 'es', label: 'Spanish'},
                                                {value: 'et', label: 'Estonian'},
                                                {value: 'eu', label: 'Basque'},
                                                {value: 'fa', label: 'Persian'},
                                                {value: 'fi', label: 'Finnish'},
                                                {value: 'fo', label: 'Faroese'},
                                                {value: 'fr-CH', label: 'French (Switzerland)'},
                                                {value: 'fr', label: 'French'},
                                                {value: 'gl', label: 'Galician'},
                                                {value: 'he', label: 'Hebrew'},
                                                {value: 'hi', label: 'Hindi'},
                                                {value: 'hr', label: 'Croatian'},
                                                {value: 'hu', label: 'Hungarian'},
                                                {value: 'hy', label: 'Armenian'},
                                                {value: 'id', label: 'Bahasa'},
                                                {value: 'is', label: 'Icelandic'},
                                                {value: 'it-CH', label: 'Italian (Switzerland)'},
                                                {value: 'it', label: 'Italian'},
                                                {value: 'ja', label: 'Japanese'},
                                                {value: 'ka', label: 'Georgian'},
                                                {value: 'kk', label: 'Kazakh'},
                                                {value: 'km', label: 'Khmer'},
                                                {value: 'ko', label: 'Korean'},
                                                {value: 'lt', label: 'Lithuanian'},
                                                {value: 'lv', label: 'Latvian'},
                                                {value: 'me', label: 'Montenegrin'},
                                                {value: 'mk', label: 'Macedonian'},
                                                {value: 'mn', label: 'Mongolian'},
                                                {value: 'mr', label: 'Marathi'},
                                                {value: 'ms', label: 'Malay'},
                                                {value: 'nl-BE', label: 'Belgium-Dutch'},
                                                {value: 'nl', label: 'Dutch'},
                                                {value: 'no', label: 'Norwegian'},
                                                {value: 'oc', label: 'Occitan'},
                                                {value: 'pl', label: 'Polish'},
                                                {value: 'pt-BR', label: 'Brazilian'},
                                                {value: 'pt', label: 'Portuguese'},
                                                {value: 'ro', label: 'Romanian'},
                                                {value: 'ru', label: 'Russian'},
                                                {value: 'si', label: 'Sinhala'},
                                                {value: 'sk', label: 'Slovak'},
                                                {value: 'sl', label: 'Slovene'},
                                                {value: 'sq', label: 'Albanian'},
                                                {value: 'sr-latn', label: 'Serbian latin'},
                                                {value: 'sr', label: 'Serbian cyrillic'},
                                                {value: 'sv', label: 'Swedish'},
                                                {value: 'sw', label: 'Swahili'},
                                                {value: 'ta', label: 'Tamil'},
                                                {value: 'tg', label: 'Tajik'},
                                                {value: 'th', label: 'Thai'},
                                                {value: 'tk', label: 'Turkmen'},
                                                {value: 'tr', label: 'Turkish'},
                                                {value: 'uk', label: 'Ukrainian'},
                                                {value: 'uz-cyrl', label: 'Uzbek cyrillic'},
                                                {value: 'uz-latn', label: 'Uzbek latin'},
                                                {value: 'vi', label: 'Vietnamese'},
                                                {value: 'zh-CN', label: 'Simplified Chinese'},
                                                {value: 'zh-TW', label: 'Traditional Chinese'}
                                            ]}
                                            onChange={setDateLanguage}
                                            value={dateLanguage}
                                        />
                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                        <TextField
                                            label="Lead Time"
                                            placeholder="e.g. 3"
                                            type="number"
                                            value={leadTime}
                                            onChange={(val) => setLeadTime(val)}
                                            helpText={
                                                <span>
                                                The number of days to block from the current date onwards.
                                                </span>
                                            }
                                        />
                                    </FormLayout.Group>
                                    </div>
                                    <Checkbox
                                        label="Only allow future dates"
                                        checked={blockDates}
                                        onChange={(checked) => setBlockDates(checked)}
                                        helpText={
                                            <span>
                                            If selected, customers cannot select today's date or a date in the past
                                            </span>
                                        }
                                    />
                                    <div style={{marginTop: '10px'}}>
                                        <OptionList
                                            title="Select Days to Block"
                                            onChange={setBlockedWeekdays}
                                            options={[
                                                {value: 1, label: 'Monday'},
                                                {value: 2, label: 'Tuesday'},
                                                {value: 3, label: 'Wednesday'},
                                                {value: 4, label: 'Thursday'},
                                                {value: 5, label: 'Friday'},
                                                {value: 6, label: 'Saturday'},
                                                {value: 0, label: 'Sunday'}
                                            ]}
                                            selected={blockedWeekdays}
                                            allowMultiple
                                            helpText
                                        />
                                    </div>
                                </div>
                                <p>Block Specific Dates</p>
                                <Stack vertical>
                                    <Button
                                        onClick={() => setDatePicker(!datePicker)}
                                        ariaExpanded={datePicker}
                                        ariaControls="set-blocked"
                                    >
                                        Set Blocked Dates
                                    </Button>
                                </Stack>
                                <div style={{marginTop: "20px"}}>
                                    {blockDays.map((day, i) => {
                                        let dteToUse = (day.start.seconds) 
                                            ? new Date(day.start.seconds*1000).toString()
                                            : day.start.toString();
                                        return <div key={`blocked+${i}`} style={{
                                            border: '1px solid #dfe3e8',
                                            display: 'inline-block',
                                            padding: '5px',
                                            borderRadius: '5px'
                                        }}>
                                            {dteToUse.substr(0,15)}{` `}
                                            <Button plain monochrome onClick={() => removeBlockedDay(i)}>
                                            remove
                                            </Button>
                                        </div>
                                    })}
                                </div>
                                <hr/>
                            </>
                            }
                            <FormLayout.Group>
                                <TextField
                                    label="Display Order"
                                    type="number"
                                    placeholder="e.g. 1"
                                    value={fieldDisplay}
                                    onChange={(val) => setOrder(val)}
                                    helpText={
                                        <span>
                                        Set the order the field will display within this step.
                                        </span>
                                    }
                                />
                                {type !== "checkbox" &&
                                <Checkbox
                                    label="Required Field"
                                    checked={fieldRequired}
                                    onChange={(checked) => setFieldRequired(checked)}
                                    helpText={
                                        <span>
                                        Requires an answer/entry from the customer.
                                        </span>
                                    }
                                />
                                }
                            </FormLayout.Group>
                            <p><b>Conditions</b></p>
                            <Stack vertical>
                                <RadioButton
                                    label="Show all the time"
                                    helpText="Always shown on the form"
                                    checked={condition === 'all'}
                                    name="condition"
                                    onChange={() => setCondition('all')}
                                />
                                
                                <RadioButton
                                    label="Only show when one of these products are selected"
                                    name="condition"
                                    checked={condition === 'product'}
                                    onChange={() => {
                                        if (props.builder.paginated) {
                                            loadProducts();
                                        }
                                        setCondition('product');
                                    }}
                                />
                                {condition === "product" && 
                                <>
                                    {loadingProducts && 
                                        <p>Loading products, this may take a little while...</p>
                                    }
                                    {!loadingProducts &&
                                    <ChoiceList
                                        titleHidden
                                        allowMultiple
                                        choices={allProducts}
                                        onChange={setConditionProducts}
                                        selected={conditionProducts}
                                    />
                                    }
                                </>
                                }
                            </Stack>
                            {modalError !== "" &&
                            <InlineError message={modalError} />
                            }
                        </FormLayout>
                    </Form>
                </Modal.Section>
            </Modal>
            </div>
            <div style={{height: '500px'}}>
                <Modal
                    open={skip}
                    onClose={() => setSkip(false)}
                    title="Set conditions that will skip this step"
                    secondaryActions={[
                        {
                        content: 'Close',
                        onAction: () => setSkip(false)
                        },
                    ]}
                >
                    <Modal.Section>
                        <Tabs tabs={tabs} selected={tab} onSelect={setTab} fitted>
                            {tabs[tab].component}
                        </Tabs>
                    </Modal.Section>
                </Modal>
            </div>
            <div style={{height: '500px'}}>
                <Modal
                    open={datePicker}
                    onClose={() => setDatePicker(false)}
                    title="Pick specific dates to block"
                    primaryAction={{
                        content: 'Add Date',
                        disabled: (blockedDate) ? false : true,
                        onAction: () => addBlockedDate()
                    }}
                    secondaryActions={[
                        {
                        content: 'Cancel',
                        onAction: () => setDatePicker(false)
                        },
                    ]}
                >
                    <Modal.Section>
                        <DatePicker
                            month={datePickerMonth}
                            year={datePickerYear}
                            onChange={setBlockedDate}
                            onMonthChange={handleMonthChange}
                            selected={blockedDate}
                        />
                    </Modal.Section>
                </Modal>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(withRouter(FormStep));