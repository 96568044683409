import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "./../../actions/";
import { LoadingPage } from "./../../screens/loading";

////////////////////
// See the version.js file in this folder
////////////////////

class UpdateVersion extends React.Component {
  state = {
    loading: true,
    error: false,
  };

  componentDidMount = async () => {
    const API_URL = process.env.REACT_APP_API_URL;

    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const shop = params.get("shop");
    const hmac = params.get("hmac");
    const timestamp = params.get("timestamp");
    const state = params.get("state");
    const host = params.get("host");

    let endpoint = `${API_URL}/auth/token?shop=${shop}&hmac=${hmac}&timestamp=${timestamp}&code=${code}&state=${state}`;
    if (host) endpoint = endpoint + `&host=${host}`;

    try {
      const response = await fetch(endpoint)

      if (response.status === 200) {
        const responseBody = await response.json();
        const shopData = responseBody.shop;
        this.props.login({
          id: shopData.id,
          shop: shopData.shop,
          access: shopData.access,
          email: shopData.email,
          supportemail: shopData.supportemail,
          version: shopData.version,
        });

        this.setState({ loading: false, error: false });
        window.top.location.href = `https://admin.shopify.com/store/${shopData.shop.replace('.myshopify.com', '')}/apps/${process.env.REACT_APP_NAME}`;
      } else {
        this.setState({ loading: false, error: true });
      }
    } catch (error) {
      this.setState({ loading: false, error: true });
      return;
    }
  };

  render() {
    let { error, loading } = this.state;
    if (loading) {
      return <LoadingPage shop="" />;
    } else if (error) {
      return <Redirect to="/error/" />;
    } else {
      return <></>
    }
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  login,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateVersion));
