import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ColorPicker from "react-pick-color";
import { setStep, saveSettings, closeBuilder, exitBuilder, getFulfilmentServices } from './../../actions/';
import {
    Card, Form, FormLayout, Stack, RadioButton, TextField, Layout, Link,
    Heading, InlineError, Checkbox, Collapsible, Button, Modal, Select, Banner
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { Toast } from '@shopify/app-bridge/actions';
import { db } from './../../actions/settings';
import { translations } from './../../actions/settings';
import { LoadTranslations } from './../builder/translations';

////////////////////
// This component renders the builder settings page (adding the builder name, etc.)
////////////////////

class BuilderSettings extends React.Component {

    state = {
        builderId: this.props.builder.builderId,
        builderName: this.props.builder.builderName,
        error: '',
        saving: false,
        active: (this.props.builder.active === undefined) ? true : this.props.builder.active,
        useVariantInfo: false,
        theme: this.props.builder.theme,
        lightbox: this.props.builder.lightbox,
        productId: this.props.builder.productId,
        editTranslations: false,
        showStyle: false,
        showSEO: false,
        showShipping: false,
        showDomain: false,
        fulfilmentOptions: [],
        customDomainError: false,
        primary: this.props.builder.primary || '#3a3a3a',
        secondary: this.props.builder.secondary || '#e8e8e8',
        selectedColor: this.props.builder.selectedColor || '#acd373',
        useVariants: (this.props.builder.useVariants === undefined) ? false : this.props.builder.useVariants,
        taxAble: (this.props.builder.taxAble === undefined) ? true : this.props.builder.taxAble,
        isFixed: (this.props.builder.isFixed === undefined) ? false : this.props.builder.isFixed,
        fixedPrice: (this.props.builder.fixedPrice === undefined) ? '' : this.props.builder.fixedPrice,
        showThumbnails: (this.props.builder.showThumbnails === undefined) ? false : this.props.builder.showThumbnails,
        addText: (this.props.builder.addText === undefined) ? '' : this.props.builder.addText,
        seoTitle: this.props.builder.seoTitle || '',
        seoDescription: this.props.builder.seoDescription || '',
        seoKeywords : this.props.builder.seoKeywords || '',
        showPrices: (this.props.builder.showPrices === undefined) ? true : this.props.builder.showPrices,
        formatDescription: (this.props.builder.formatDescription === undefined) ? false : this.props.builder.formatDescription,
        borderColor: this.props.builder.borderColor || '#d9e1e6',
        lightboxBg: this.props.builder.lightboxBg || 'rgba(0,0,0,0.8)',
        showCarousel: (this.props.builder.showCarousel === undefined) ? true : this.props.builder.showCarousel,
        showProgress: (this.props.builder.showProgress === undefined) ? false : this.props.builder.showProgress,
        progressBarColor: this.props.builder.progressBarColor || '#d9e1e6',
        progressTextColor: this.props.builder.progressTextColor || '#3a3a3a',
        translations: this.props.builder.translations || translations,
        totalBoxBackground: this.props.builder.totalBoxBackground || '#fff',
        totalColor: this.props.builder.totalColor || '#ccc',
        priceColor: this.props.builder.priceColor || '#000',
        boxContentsText: this.props.builder.boxContentsText || '#555',
        customJS: (this.props.builder.customJS === undefined) ? '' : this.props.builder.customJS,
        customHTML: (this.props.builder.customHTML === undefined) ? '' : this.props.builder.customHTML,
        modalColor: (this.props.builder.modalColor === undefined) ? '#fff' : this.props.builder.modalColor,
        startPrice: (this.props.builder.startPrice === undefined) ? 0.00 : this.props.builder.startPrice,
        showBuilding: (this.props.builder.showBuilding === undefined) ? false : this.props.builder.showBuilding,
        buildingGif: this.props.builder.buildingGif || 'https://assets9.lottiefiles.com/packages/lf30_editor_hdzgigho.json',
        buildingBackground: this.props.builder.buildingBackground || '#fff',
        buildingTitle: this.props.builder.buildingTitle || '#000',
        buildingText: this.props.builder.buildingText || '#000',
        hideSoldOut: (this.props.builder.hideSoldOut === undefined) ? false : this.props.builder.hideSoldOut,
        redirectTo: this.props.builder.redirectTo || '/cart',
        requiresShipping: (this.props.builder.requiresShipping === undefined) ? true : this.props.builder.requiresShipping,
        fulfilmentOption: this.props.builder.fulfilmentOption || 'manual',
        customDomain: this.props.builder.customDomain || '',
        rememberSelections: (this.props.builder.rememberSelections === undefined) ? true : this.props.builder.rememberSelections,
        keepBuilderOnOrder: (this.props.builder.keepBuilderOnOrder === undefined) ? false : this.props.builder.keepBuilderOnOrder,
        singleProducts: (this.props.builder.singleProducts === undefined) ? false : this.props.builder.singleProducts
    }

    app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        shopOrigin: this.props.shop.info.shop,
    });

    saveSettings = async() => {
        try {
            this.setState({saving: true}, async() => {
                let {
                    builderName, active, theme, lightbox, productId, primary,
                    secondary, useVariants, taxAble, fixedPrice, isFixed, addText,
                    selectedColor, seoTitle, seoDescription, seoKeywords,
                    showPrices, formatDescription, borderColor, lightboxBg,
                    showCarousel, showProgress, progressBarColor, progressTextColor,
                    translations, totalBoxBackground, totalColor, priceColor,
                    boxContentsText, customJS, customHTML, modalColor, showThumbnails, startPrice,
                    showBuilding, buildingGif, buildingBackground, buildingTitle, buildingText,
                    hideSoldOut, redirectTo, requiresShipping, fulfilmentOption, customDomain,
                    rememberSelections, keepBuilderOnOrder, singleProducts
                } = this.state;
                if (isFixed && !fixedPrice) {
                    this.setState({
                        error: 'Please enter a fixed price.',
                        saving: false
                    });
                    return;
                }
                if (!/^[a-z\-]+$/.test(customDomain) && customDomain !== '') {
                    this.setState({
                        error: "Please enter a valid custom URL or leave it blank.",
                        saving: false,
                        customDomainError: true
                    });
                    return;
                }
                if (startPrice === "" || startPrice === null) {
                    this.setState({
                        error: "Please enter a start price. Enter 0.00 if you don't require one.",
                        saving: false
                    });
                    return;
                }
                if (!builderName) {
                    this.setState({
                        error: 'A builder name is required.',
                        saving: false
                    });
                } else {
                    await this.props.saveSettings({
                        builderName, active, theme: theme, lightbox, productId,
                        primary, secondary, useVariants, taxAble, isFixed, fixedPrice,
                        addText, selectedColor, seoTitle, seoDescription, seoKeywords,
                        showPrices, formatDescription, borderColor, lightboxBg,
                        showCarousel, showProgress, progressBarColor, progressTextColor,
                        translations, totalBoxBackground, totalColor, priceColor,
                        boxContentsText, customJS, customHTML, modalColor, showThumbnails, startPrice,
                        showBuilding, buildingGif, buildingBackground, buildingTitle, buildingText,
                        hideSoldOut, redirectTo, requiresShipping, fulfilmentOption, customDomain,
                        rememberSelections, keepBuilderOnOrder, singleProducts
                    });
                    this.props.setStep(1);
                    this.props.parent.setState({tabSelected: 1});
                }
            });
        } catch(e) {
            console.log(e.message);
            this.setState({
                error: 'An error occurred, please try again.',
                saving: false
            });
        }
    }

    updateSettings = async() => {
        this.setState({saving: true}, async() => {
            let {
                builderId, builderName, active, theme, lightbox, productId, primary,
                secondary, useVariants, taxAble, fixedPrice, isFixed, addText,
                selectedColor, seoTitle, seoDescription, seoKeywords,
                showPrices, formatDescription, borderColor, lightboxBg,
                showCarousel, showProgress, progressBarColor, progressTextColor,
                translations, totalBoxBackground, totalColor, priceColor, boxContentsText,
                customJS, customHTML, modalColor, showThumbnails, startPrice,
                showBuilding, buildingGif, buildingBackground, buildingTitle, buildingText,
                hideSoldOut, redirectTo, requiresShipping, fulfilmentOption, customDomain,
                rememberSelections, keepBuilderOnOrder, singleProducts
            } = this.state;
            await db
                .collection("Builders")
                .doc(builderId)
                .update({
                    builderName,
                    builderId,
                    active,
                    shopId: this.props.shop.info.id,
                    shop: this.props.shop.info.shop,
                    theme: theme,
                    deleted: false,
                    lightbox,
                    productId,
                    primary,
                    selectedColor,
                    secondary,
                    useVariants: (useVariants === undefined) ? false : useVariants,
                    taxAble: (taxAble === undefined) ? true : taxAble,
                    isFixed: (isFixed === undefined) ? false : isFixed,
                    fixedPrice: (fixedPrice === undefined) ? '' : fixedPrice,
                    addText: (addText === undefined) ? '' : addText,
                    seoTitle, seoKeywords, seoDescription,
                    showPrices: (showPrices === undefined) ? true : showPrices,
                    formatDescription: (formatDescription === undefined) ? false : formatDescription,
                    borderColor: borderColor,
                    lightboxBg: lightboxBg,
                    showCarousel,
                    showProgress, progressBarColor, progressTextColor,
                    translations, totalBoxBackground, totalColor, priceColor,
                    boxContentsText,
                    customJS : (customJS === undefined) ? '' : customJS,
                    customHTML : (customHTML === undefined) ? '' : customHTML,
                    modalColor: (modalColor === undefined) ? '#fff' : modalColor,
                    showThumbnails: (showThumbnails === undefined) ? false : showThumbnails,
                    startPrice: (startPrice === undefined) ? 0.00 : startPrice,
                    showBuilding, buildingGif, buildingBackground, buildingTitle, buildingText,
                    hideSoldOut: (hideSoldOut === undefined) ? false : hideSoldOut,
                    redirectTo, fulfilmentOption, customDomain,
                    requiresShipping: (requiresShipping === undefined) ? true : requiresShipping,
                    rememberSelections: (rememberSelections === undefined) ? true : rememberSelections,
                    singleProducts: (singleProducts === undefined) ? false : singleProducts,
                    keepBuilderOnOrder: (keepBuilderOnOrder === undefined) ? false : keepBuilderOnOrder
                });
            const toastOptions = {
                message: 'Settings Saved',
                duration: 3000
            };
            const toast = Toast.create(this.app, toastOptions);
            toast.dispatch(Toast.Action.SHOW);
            this.setState({saving: false});
        });
    }

    componentDidMount = async() => {
        let services = await getFulfilmentServices(this.props.shop.info.id);
        console.log("services", services);
        if (!services.error) {
            let options = services.map((s) => {
                return {
                    label: s.name, value: s.location_id.toString()
                }
            })
            this.setState({fulfilmentOptions: [{label: 'Default', value: 'manual'}, ...options]});
        } else {
            this.setState({fulfilmentOptions: [{label: 'Default', value: 'manual'}]});
        }
    }

    updateVersion = async() => {
        let rUrl = 'https://builder.boxup.io/admin/update';
        let url = `https://${this.props.shop.info.shop}/admin/oauth/request_grant?client_id=` +
            `${process.env.REACT_APP_API_KEY}&scope=${process.env.REACT_APP_SCOPE}&redirect_uri=${rUrl}`;
        window.top.location.href = url;
    }

    checkURL = (val) => {
        if (/^[a-z\-]+$/.test(val) || val == '') {
            this.setState({
                customDomain: val.replace(" ", "-"),
                customDomainError: false
            });
        } else {
            this.setState({
                customDomain: val.replace(" ", "-"),
                customDomainError: true
            });
        }
    }

    render() {
        let actions = [{
            content: 'Exit Builder',
            onAction: () => closeBuilder(this.props),
            loading: this.state.saving
        }];
        if (this.state.productId) {
            actions.push({
                content: 'Save Settings',
                onAction: () => this.updateSettings(),
                loading: this.state.saving
            });
        }
        let { customDomain } = this.state;

        let gifs = [
            {source:'/assets/image/8.gif', file: 'https://builder-front.boxup.io/img/gifs/lf30_editor_hdzgigho.json'},
            {source:'/assets/image/2.gif', file: 'https://assets9.lottiefiles.com/packages/lf20_b7iyilyf.json'},
            {source:'/assets/image/3.gif', file: 'https://builder-front.boxup.io/img/gifs/lf30_editor_rgb63o6l.json'},
            {source:'/assets/image/4.gif', file: 'https://assets8.lottiefiles.com/packages/lf20_t5z8wphn.json'},
            {source:'/assets/image/5.gif', file: 'https://assets10.lottiefiles.com/packages/lf20_3g23tmsd.json'},
            {source:'/assets/image/6.gif', file: 'https://assets2.lottiefiles.com/packages/lf20_tespsg6u.json'},
            {source:'/assets/image/9.gif', file: 'https://builder-front.boxup.io/img/gifs/lf30_editor_kelpcawt.json'},
            {source:'/assets/image/7.gif', file: 'https://assets10.lottiefiles.com/packages/lf20_vhs9gkyp.json'},
            {source:'/assets/image/1.gif', file: 'https://builder-front.boxup.io/img/gifs/lf30_editor_dgs9bkly.json'}
        ];

        let fulfilmentOptions = this.state.fulfilmentOptions;

        return <>
        <Card
            title=""
            sectioned
            secondaryFooterActionsDisclosureText="Exit/Update"
            secondaryFooterActions={actions}
            primaryFooterAction={{
                content: 'Next',
                onAction: () => this.saveSettings(),
                loading: this.state.saving
            }}
        >
            <Card.Section>
                <Form seperator>
                    <FormLayout>
                        <Heading>Builder's Settings</Heading>
                        {this.state.error &&
                        <InlineError message={this.state.error} fieldID="error" />
                        }
                        <TextField
                            value={this.state.builderName}
                            onChange={(val) => this.setState({builderName: val})}
                            placeholder="e.g. Build Your Custom Laptop"
                            label="Builder Name"
                            type="email"
                        />
                        <FormLayout.Group>
                            <Checkbox
                                label="Live"
                                checked={this.state.active}
                                onChange={(checked) => this.setState({active: checked})}
                                helpText={
                                    <span>
                                    Check this box to make your builder active. Uncheck the box to deactivate and keep as a draft.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Charge Tax"
                                checked={this.state.taxAble}
                                onChange={(checked) => this.setState({taxAble: checked})}
                                helpText={
                                    <span>
                                    If you want to charge tax on your builder, check this box.
                                    </span>
                                }
                            />
                            <Select
                                label="When complete, send customers to the:"
                                options={[
                                    {label: 'Cart', value: '/cart'},
                                    {label: 'Checkout', value: '/checkout'}
                                ]}
                                value={this.state.redirectTo}
                                onChange={(val) => this.setState({redirectTo: val})}
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Use a Fixed Price"
                                checked={this.state.isFixed}
                                onChange={(checked) => {
                                    this.setState({
                                        isFixed: checked
                                    });
                                }}
                                helpText={
                                    <span>
                                    If checked, the 'Use Your Products on new Orders' option above is unavailable.
                                    </span>
                                }
                            />
                            {this.state.isFixed &&
                            <TextField
                                value={this.state.fixedPrice}
                                onChange={(val) => this.setState({fixedPrice: val})}
                                placeholder="e.g. 99.99"
                                label="Fixed Price"
                                type="number"
                                helpText={
                                    <span>
                                    The price of the box will always remain at this fixed price.
                                    </span>
                                }
                            />
                            }
                            <TextField
                                value={this.state.startPrice}
                                onChange={(val) => this.setState({startPrice: val})}
                                placeholder="e.g. 19.99"
                                label="Start Price"
                                type="number"
                                helpText={
                                    <span>
                                    If you want to have your builder start at a certain price, input that here. Otherwise, keep this at $0.00.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </Form>
            </Card.Section>
            <Card.Section>
                <Form seperator>
                    <FormLayout>
                        <Heading>Order Product/SKU Settings</Heading>
                        <FormLayout.Group>
                            <Checkbox
                                label="Use Your Products on new Orders"
                                checked={this.state.useVariants}
                                onChange={(checked) => this.setState({useVariants: checked})}
                                helpText={
                                    <span>
                                    <Button plain onClick={() => this.setState({useVariantInfo: true})}>More Info</Button>
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Use fixed-price or discounted?"
                                checked={this.state.keepBuilderOnOrder}
                                onChange={(checked) => this.setState({keepBuilderOnOrder: checked})}
                                helpText={
                                    <span>
                                    If you're using the Fixed Price option, meaning the price of the builder isn't determined by the selected products, this option is recommended. <Button plain onClick={() => this.setState({useVariantInfo: true})}>More Info</Button>
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </Form>
            </Card.Section>
            <Card.Section>
                <Form seperator>
                    <FormLayout>
                        <Heading>Display Settings</Heading>
                        <FormLayout.Group>
                            <Checkbox
                                label="Enable Image Lightbox"
                                checked={this.state.lightbox}
                                onChange={(checked) => this.setState({lightbox: checked})}
                                helpText={
                                    <span>
                                    See the bigger picture! This will allow customers to click product images to enlarge them.
                                    </span>
                                }
                            />

                            <Checkbox
                                label="Show Prices in the Button"
                                checked={this.state.showPrices}
                                onChange={(checked) => {
                                    this.setState({showPrices: checked});
                                }}
                                helpText={
                                    <span>
                                    Check this box to show the prices of items. Leave unchecked to leave prices out, useful if you are creating a fixed price bundle!
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show Formatted Descriptions"
                                checked={this.state.formatDescription}
                                onChange={(checked) => {
                                    this.setState({formatDescription: checked});
                                }}
                                helpText={
                                    <span>
                                    Copy the formatted descriptions that you've customized for each product. Note that some formatting may not be ideal on mobile devices.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Hide Sold Out Products"
                                checked={this.state.hideSoldOut}
                                onChange={(checked) => {
                                    this.setState({hideSoldOut: checked});
                                }}
                                helpText={
                                    <span>
                                    Check that box if you want to hide sold-out products. <strong>We recommend hiding sold out products to ensure accurate inventory management.</strong>
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show a message when adding to cart"
                                checked={this.state.showBuilding}
                                onChange={(checked) => {
                                    this.setState({showBuilding: checked});
                                }}
                                helpText={
                                    <span>
                                    A small popup gif will display when the customer waits a few seconds for the builder to be added to the cart. We can input a custom gif if you have one, shoot us an email, support@boxup.io and we will customize it for you.
                                    </span>
                                }
                            />
                            <TextField
                                value={this.state.addText}
                                onChange={(val) => this.setState({addText: val})}
                                placeholder="e.g. Add:"
                                label="Add Button Text"
                                type="text"
                                helpText={
                                    <span>
                                    The price will appear next to this text. Note that long text may not be ideal on mobile devices.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Remember Selections"
                                checked={this.state.rememberSelections}
                                onChange={(checked) => this.setState({rememberSelections: checked})}
                                helpText={
                                    <span>
                                    Your customers can be busy people closing browser tabs faster than the speed of light. Make sure your Box Builder remembers your customer's selection upon their return.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show Image Carousels"
                                checked={this.state.showCarousel}
                                onChange={(checked) => this.setState({showCarousel: checked})}
                                helpText={
                                    <span>
                                    Show/hide image carousels when there are multiple images for the product.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show Step Progress"
                                checked={this.state.showProgress}
                                onChange={(checked) => this.setState({showProgress: checked})}
                                helpText={
                                    <span>
                                    Check this to show a progress bar and step numbers above each step.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        {this.state.showBuilding &&
                        <>
                        <label><b>Select a gif to include on the popup.</b></label>
                        <FormLayout.Group>

                            <Stack horizontal>
                                {gifs.map((gif, i) => {
                                    return <RadioButton
                                        key={i}
                                        label={<img height="150px" src={gif.source}/>}
                                        checked={this.state.buildingGif === gif.file}
                                        onChange={(value) => {
                                            this.setState({buildingGif: gif.file});
                                        }}
                                    />
                                })}
                            </Stack>
                        </FormLayout.Group>
                        </>
                        }
                    </FormLayout>
                </Form>
            </Card.Section>
            <Card.Section>
                <Button plain
                    onClick={() => this.setState({showStyle: !this.state.showStyle})}
                >Show style settings</Button>
                <Collapsible
                    open={this.state.showStyle}
                    id="show-seo"
                    transition={{duration: '150ms', timingFunction: 'ease'}}
                >
                    <div style={{marginTop:'20px'}}>
                    <Layout>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Primary Button Color</b></label>
                                    <ColorPicker hideInputs={false} color={this.state.primary} onChange={(c) => this.setState({primary: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Secondary Button Color</b></label>
                                    <ColorPicker hideInputs={false} color={this.state.secondary} onChange={(c) => this.setState({secondary: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Product Select Color</b></label>
                                    <ColorPicker hideInputs={false} color={this.state.selectedColor} onChange={(c) => this.setState({selectedColor: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Product Border Color</b></label>
                                    <ColorPicker hideInputs={false} color={this.state.borderColor} onChange={(c) => this.setState({borderColor: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Lightbox Background Color</b></label>
                                    <ColorPicker hideInputs={false} color={this.state.lightboxBg} onChange={(c) => this.setState({lightboxBg: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Lightbox Text Color</b></label>
                                    <ColorPicker hideInputs={false} color={this.state.modalColor} onChange={(c) => this.setState({modalColor: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Progress Bar Text Color</b></label>
                                    <ColorPicker hideAlpha={true} hideInputs={false} color={this.state.progressTextColor} onChange={(c) => this.setState({progressTextColor: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Progress Bar Color</b></label>
                                    <ColorPicker hideAlpha={true} hideInputs={false} color={this.state.progressBarColor} onChange={(c) => this.setState({progressBarColor: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Total Box Background Color</b></label>
                                    <ColorPicker hideAlpha={true} hideInputs={false} color={this.state.totalBoxBackground} onChange={(c) => this.setState({totalBoxBackground: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>'Total' Text Color</b></label>
                                    <ColorPicker hideAlpha={true} hideInputs={false} color={this.state.totalColor} onChange={(c) => this.setState({totalColor: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Total Price Text Color</b></label>
                                    <ColorPicker hideAlpha={true} hideInputs={false} color={this.state.priceColor} onChange={(c) => this.setState({priceColor: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Order/Box Details Product Text Color</b></label>
                                    <ColorPicker hideAlpha={true} hideInputs={false} color={this.state.boxContentsText} onChange={(c) => this.setState({boxContentsText: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Adding to cart popup background color</b></label>
                                    <ColorPicker hideAlpha={true} hideInputs={false} color={this.state.buildingBackground} onChange={(c) => this.setState({buildingBackground: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Adding to cart popup title Color</b></label>
                                    <ColorPicker hideAlpha={true} hideInputs={false} color={this.state.buildingTitle} onChange={(c) => this.setState({buildingTitle: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                            <Layout.Section oneThird>
                                <div style={{width: '100%', display: 'inline-block', verticalAlign: 'top'}}>
                                    <label style={{paddingBottom: '10px'}}><b>Adding to cart popup text color</b></label>
                                    <ColorPicker hideAlpha={true} hideInputs={false} color={this.state.buildingText} onChange={(c) => this.setState({buildingText: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`})} />
                                </div>
                            </Layout.Section>
                        </Layout>
                    </div>
                </Collapsible>
            </Card.Section>
            <Card.Section>
                <Button plain
                    onClick={() => this.setState({showSEO: !this.state.showSEO})}
                >Show SEO Settings</Button>
                <Collapsible
                    open={this.state.showSEO}
                    id="show-style"
                    transition={{duration: '150ms', timingFunction: 'ease'}}
                >
                    <div style={{marginTop:'20px'}}>
                        <FormLayout.Group>
                            <TextField
                                value={this.state.seoTitle}
                                onChange={(val) => this.setState({seoTitle: val})}
                                placeholder="e.g. Custom Gift Box by My Company"
                                label="SEO Title"
                                type="text"
                                helpText={
                                    <span>
                                    Rewrite the SEO title of your Box Builder here for better ranking and discovery on search engines.
                                    </span>
                                }
                            />
                            <TextField
                                value={this.state.seoKeywords}
                                onChange={(val) => this.setState({seoKeywords: val})}
                                label="SEO Keywords"
                                type="text"
                                helpText={
                                    <span>
                                    Write all the SEO keywords you need here by separating them with a comma.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <TextField
                                value={this.state.seoDescription}
                                onChange={(val) => this.setState({seoDescription: val})}
                                label="SEO Description"
                                type="text"
                                helpText={
                                    <span>
                                    The SEO description is one of the most important elements of SEO. It is shown in search engine results and provides information to people who are looking for things related to your products. A good description encourages customers to click the link in search results to visit your store.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                    </div>
                </Collapsible>
            </Card.Section>
            <Card.Section>
                <Button plain
                    onClick={() => this.setState({showShipping: !this.state.showShipping})}
                >Shipping & Fulfillment</Button>
                <Collapsible
                    open={this.state.showShipping}
                    id="show-shipping"
                    transition={{duration: '150ms', timingFunction: 'ease'}}
                >
                    <div style={{marginTop:'20px'}}>
                        <FormLayout.Group>
                            <Select
                                label="Fulfillment Service"
                                options={fulfilmentOptions}
                                value={this.state.fulfilmentOption}
                                onChange={(val) => this.setState({fulfilmentOption: val})}
                                helpText={
                                    <span>
                                    If you have several shipping settings, you may specify which profile to use here. Keep as Default to use the standard Shopify fulfillment method. <br/>
                                    If your fulfillment options aren't showing, you may need to <Link onClick={() => this.updateVersion()}>enable permission</Link> for our app to access them.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Requires Shipping"
                                checked={this.state.requiresShipping}
                                onChange={(checked) => {
                                    this.setState({requiresShipping: checked});
                                }}
                                helpText={
                                    <span>
                                    Only uncheck this for digital items that don't need to be shipped! Otherwise ensure this stays checked so you collect the customer's address for shipping.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                    </div>
                </Collapsible>
            </Card.Section>
            <Card.Section>
                <Button plain
                    onClick={() => this.setState({showDomain: !this.state.showDomain})}
                >Customize URL Path</Button>
                <Collapsible
                    open={this.state.showDomain}
                    id="show-domain"
                    transition={{duration: '150ms', timingFunction: 'ease'}}
                >
                    <div style={{marginTop:'20px'}}>
                        <Banner
                            status="info"
                            >
                            <p>
                                The custom URL path allows you to set your page title to something more custom than the original URL.{` `}
                                It also allows you to get more keywords for search engine optimization (SEO).{` `}
                                The text you enter below will be used in the URL,{` `}
                                so no spaces or non alphabetic characters are allowed except for hyphens (dashes).{` `}
                                Some examples might be 'build-a-box' or 'gift-box'.
                            </p>
                        </Banner>
                        {this.state.customDomainError &&
                        <InlineError message="Only letters or hyphens allowed"/>
                        }
                        <FormLayout.Group>
                            <TextField
                                value={this.state.customDomain}
                                onChange={(val) => this.checkURL(val)}
                                label={"Custom URL - https://" + this.props.shop.info.shop+ '/apps/builder/' + customDomain}
                                type="text"
                                helpText={
                                    <span>
                                    Start typing to view the URL.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                    </div>
                </Collapsible>
            </Card.Section>
            <Card.Section>
                <Button
                    onClick={() => this.setState({editTranslations: !this.state.editTranslations})}
                >Edit Translations</Button>
            </Card.Section>
            <Card.Section>
            <Heading><span style={{marginBottom: 15+'px', display: 'block'}}>Select which theme the builder will use</span></Heading>
                <Stack distribution="fill">
                    <RadioButton
                        label="Full width with fixed bottom pricing bar"
                        helpText={
                            <>
                            <div className="Polaris-Choice__HelpText" id="fullHelpText">Show only product images and price, doesn't show product descriptions.</div>
                            <img src="https://builder.boxup.io/assets/image/full.png" style={{maxHeight:'250px', width:'auto',marginTop: '10px', marginBottom: '30px'}}/>
                            </>
                        }
                        checked={this.state.theme === 'full'}
                        id="full"
                        name="full"
                        onChange={() => this.setState({theme: 'full'})}
                    />
                    <RadioButton
                        label="Fixed right side total with vertical scroll"
                        helpText={
                            <>
                            <div className="Polaris-Choice__HelpText" id="fullHelpText">This option also displays product descriptions.</div>
                            <img src="https://builder.boxup.io/assets/image/right.png" style={{maxHeight:'250px', width:'auto',marginTop: '10px', marginBottom: '30px'}}/>
                            </>
                        }
                        id="right"
                        name="right"
                        checked={this.state.theme === 'right'}
                        onChange={() => this.setState({theme: 'right'})}
                    />
                    </Stack>
            </Card.Section>
            {this.state.theme === 'full' &&
            <Card.Section>
                <FormLayout.Group>
                    <Checkbox
                        label="Show images of selected items in the footer?"
                        checked={this.state.showThumbnails}
                        onChange={(checked) => this.setState({showThumbnails: checked})}
                        helpText={
                            <span>
                            This only applies to the 'full' layout.
                            </span>
                        }
                    />
                </FormLayout.Group>
            </Card.Section>
            }
        </Card>
        <div style={{height: this.state.useVariantInfo ? '400px' : '0px'}}>
            <Modal
                open={this.state.useVariantInfo}
                onClose={() => this.setState({
                    useVariantInfo: false
                })}
                title="Order Product/SKU Settings"
                secondaryActions={[
                    {
                    content: 'Okay',
                    onAction: () => this.setState({
                            useVariantInfo: false
                        }),
                    },
                ]}
            >
                <Modal.Section>
                    <Heading>Use Your Products on new Orders</Heading>
                    <p>By default, each box shows up on the cart, checkout and order as 1 item/product, with the box's contents listed beneath.</p><br/>
                    <p>
                        You can check this box to use the actual variants on the order so, instead of showing 1 product, it will apply all the individual variants to the order.
                    </p><br/>
                    <p>This is useful if you have downstream systems relying on the order details telling it what variants were actually sold.</p><br/>
                    <p>After the order has been paid, we will automatically make these adjustments. To the customer, it will still show as 1 product on the cart and checkout pages.</p><br/>
                    <p>Either method will adjust the stock for each variant chosen by the customer.</p>
                    <p>If you're using a base price, please note that this will show as an amount owed to the customer once the products are added.</p>
                    <br/>
                    <Heading>Use fixed-price or discounted?</Heading>
                    <p>Check this if you want to use a fixed price, or if you want to use discounts in your builder.</p><br/>
                    <p>When you want to use a fixed price, or discounts, the total price the customer pays will not match the sum of all the selected products. In the checkout process, Shopify's system won't like how the amount paid doesn't match the sum owed, and will then chase the customer for the outstanding amount.</p><br/>
                    <p>So, we developed a feature that keeps the custom product bundle on the order, and unwraps all the variants (after checkout) but sets the individual products to 0.00, so there's no outstanding amounts.</p>
                </Modal.Section>
            </Modal>
        </div>
        <div style={{height: this.state.editTranslations ? '500px' : '0px'}}>
            <Modal
                large
                open={this.state.editTranslations}
                onClose={() => this.setState({
                    editTranslations: false
                })}
                title="Builder Translations"
                secondaryActions={[
                    {
                        content: 'Close',
                        onAction: () => {
                            this.setState({
                                editTranslations: false
                            });
                        }
                    }
                ]}
            >
                <Modal.Section>
                    <LoadTranslations parent={this}/>
                </Modal.Section>
            </Modal>
        </div>
        </>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    setStep,
    saveSettings,
    exitBuilder
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BuilderSettings));
