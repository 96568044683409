
const defaultState = {
    builderId: '',
    builderName: '',
    currentTab: 0,
    steps: [],
    active: true,
    theme: 'right',
    lightbox: true,
    productId: '',
    primary: '#3a3a3a',
    secondary: '#e8e8e8',
    discounts: [],
    useVariants: false,
    taxAble: true,
    isFixed: false,
    fixedPrice: '',
    addText: '',
    selectedColor: '#acd373',
    seoTitle: '',
    seoDescription: '',
    seoKeywords: '',
    showPrices: true,
    formatDescription: false,
    borderColor: '#d9e1e6',
    lightboxBg: 'rgba(0,0,0,.8)',
    showCarousel: true,
    showProgress: false,
    progressTextColor: '#3a3a3a',
    progressBarColor: '#d9e1e6',
    translations: [],
    totalBoxBackground: '#fff', 
    totalColor: '#ccc', 
    priceColor: '#000',
    boxContentsText: '#555',
    customJS: '',
    customHTML: '',
    modalColor: '#fff',
    showThumbnails: false,
    startPrice: 0.00,
    showBuilding: false,
    buildingGif: 'https://assets9.lottiefiles.com/packages/lf30_editor_hdzgigho.json',
    buildingBackground: '#fff',
    buildingTitle: '#000',
    buildingText: '#000',
    hideSoldOut: false,
    redirectTo: '/cart',
    requiresShipping: true,
    fulfilmentOption: 'manual',
    customDomain: '',
    rememberSelections: true,
    singleProducts: false,
    keepBuilderOnOrder: false,
    paginated: false
}

export default function builder (state = defaultState, action) {
    switch (action.type) {
        case "CLEAN_BUILDER":
            return {
                ...state,
                steps: [],
                builderId: '',
                builderName: '',
                currentTab: 0,
                productId: '',
                discounts: [],
                useVariants: false,
                taxAble: true,
                isFixed: false,
                fixedPrice: '',
                addText: '',
                seoTitle: '',
                seoDescription: '',
                seoKeywords: '',
                showPrices: true,
                formatDescription: false,
                borderColor: '#d9e1e6',
                lightboxBg: 'rgba(0,0,0,.8)',
                showCarousel: true,
                showProgress: false,
                progressTextColor: '#3a3a3a',
                progressBarColor: '#d9e1e6',
                translations: [],
                totalBoxBackground: '#fff', 
                totalColor: '#ccc', 
                priceColor: '#000',
                boxContentsText: '#555',
                customJS: '',
                customHTML: '',
                modalColor: '#fff',
                showThumbnails: false,
                startPrice: 0.00,
                showBuilding: false,
                buildingGif: 'https://assets9.lottiefiles.com/packages/lf30_editor_hdzgigho.json',
                buildingBackground: '#fff',
                buildingTitle: '#000',
                buildingText: '#000',
                hideSoldOut: false,
                redirectTo: '/cart',
                requiresShipping: true,
                fulfilmentOption: 'manual',
                customDomain: '',
                rememberSelections: true,
                singleProducts: false,
                keepBuilderOnOrder: false,
                paginated: false
            }
        case "EDIT_BUILDER":
            return {
                ...state, 
                builderId: action.payload.builderId,
                builderName: action.payload.builderName,
                active: action.payload.active,
                theme: action.payload.theme,
                steps: action.payload.steps,
                lightbox: action.payload.lightbox,
                productId: action.payload.productId,
                primary: action.payload.primary,
                secondary: action.payload.secondary,
                discounts: action.payload.discounts,
                useVariants: action.payload.useVariants,
                taxAble: action.payload.taxAble,
                isFixed: action.payload.isFixed,
                fixedPrice: action.payload.fixedPrice,
                addText: action.payload.addText,
                selectedColor: action.payload.selectedColor,
                seoTitle: action.payload.seoTitle,
                seoDescription: action.payload.seoDescription,
                seoKeywords: action.payload.seoKeywords,
                showPrices: action.payload.showPrices,
                formatDescription: action.payload.formatDescription,
                borderColor: action.payload.borderColor,
                lightboxBg: action.payload.lightboxBg,
                showCarousel: action.payload.showCarousel,
                showProgress: action.payload.showProgress,
                progressTextColor: action.payload.progressTextColor,
                progressBarColor: action.payload.progressBarColor,
                translations: action.payload.translations,
                totalBoxBackground: action.payload.totalBoxBackground,
                totalColor: action.payload.totalColor,
                priceColor: action.payload.priceColor,
                boxContentsText: action.payload.boxContentsText,
                customJS: action.payload.customJS,
                customHTML: action.payload.customHTML,
                modalColor: action.payload.modalColor,
                showThumbnails: action.payload.showThumbnails,
                startPrice: action.payload.startPrice,
                showBuilding: action.payload.showBuilding,
                buildingGif: action.payload.buildingGif,
                buildingBackground: action.payload.buildingBackground,
                buildingTitle: action.payload.buildingTitle,
                buildingText: action.payload.buildingText,
                hideSoldOut: action.payload.hideSoldOut,
                redirectTo: action.payload.redirectTo,
                requiresShipping: action.payload.requiresShipping,
                fulfilmentOption: action.payload.fulfilmentOption,
                customDomain: action.payload.customDomain,
                rememberSelections: action.payload.rememberSelections,
                singleProducts: action.payload.singleProducts,
                keepBuilderOnOrder: action.payload.keepBuilderOnOrder,
                paginated: action.payload.paginated
            }
        case "NEW_BUILDER":
            return {
                ...state,
                steps: [],
                builderId: action.payload.builderId,
                builderName: '',
                currentTab: 0,
                productId: '',
                discounts: [],
                useVariants: false,
                taxAble: true,
                isFixed: false,
                fixedPrice: '',
                addText: '',
                seoTitle: '',
                seoDescription: '',
                seoKeywords: '',
                showPrices: true,
                formatDescription: false,
                borderColor: '#d9e1e6',
                lightboxBg: 'rgba(0,0,0,.8)',
                showCarousel: true,
                showProgress: false,
                progressTextColor: '#3a3a3a',
                progressBarColor: '#d9e1e6',
                translations: [],
                totalBoxBackground: '#fff', 
                totalColor: '#ccc', 
                priceColor: '#000',
                boxContentsText: '#555',
                customJS: '',
                customHTML: '',
                modalColor: '#fff',
                showThumbnails: false,
                startPrice: 0.00,
                showBuilding: false,
                buildingGif: 'https://assets9.lottiefiles.com/packages/lf30_editor_hdzgigho.json',
                buildingBackground: '#fff',
                buildingTitle: '#000',
                buildingText: '#000',
                hideSoldOut: false,
                redirectTo: '/cart',
                requiresShipping: true,
                fulfilmentOption: 'manual',
                customDomain: '',
                rememberSelections: true,
                singleProducts: false,
                keepBuilderOnOrder: false,
                paginated: false
            }
        case "SET_BUILDER":
            return {
                ...state,
                builderName: action.payload.builderName,
                steps: action.payload.steps,
                active: action.payload.active,
                theme: action.payload.theme,
                lightbox: action.payload.lightbox,
                productId: action.payload.productId,
                primary: action.payload.primary,
                secondary: action.payload.secondary,
                discounts: action.payload.discounts,
                useVariants: action.payload.useVariants,
                taxAble: action.payload.taxAble,
                isFixed: action.payload.isFixed,
                fixedPrice: action.payload.fixedPrice,
                addText: action.payload.addText,
                selectedColor: action.payload.selectedColor,
                seoTitle: action.payload.seoTitle,
                seoDescription: action.payload.seoDescription,
                seoKeywords: action.payload.seoKeywords,
                showPrices: action.payload.showPrices,
                formatDescription: action.payload.formatDescription,
                borderColor: action.payload.borderColor,
                lightboxBg: action.payload.lightboxBg,
                showCarousel: action.payload.showCarousel,
                showProgress: action.payload.showProgress,
                progressTextColor: action.payload.progressTextColor,
                progressBarColor: action.payload.progressBarColor,
                translations: action.payload.translations,
                totalBoxBackground: action.payload.totalBoxBackground,
                totalColor: action.payload.totalColor,
                priceColor: action.payload.priceColor,
                boxContentsText: action.payload.boxContentsText,
                customJS: action.payload.customJS,
                customHTML: action.payload.customHTML,
                modalColor: action.payload.modalColor,
                showThumbnails: action.payload.showThumbnails,
                startPrice: action.payload.startPrice,
                showBuilding: action.payload.showBuilding,
                buildingGif: action.payload.buildingGif,
                buildingBackground: action.payload.buildingBackground,
                buildingTitle: action.payload.buildingTitle,
                buildingText: action.payload.buildingText,
                hideSoldOut: action.payload.hideSoldOut,
                redirectTo: action.payload.redirectTo,
                requiresShipping: action.payload.requiresShipping,
                fulfilmentOption: action.payload.fulfilmentOption,
                customDomain: action.payload.customDomain,
                rememberSelections: action.payload.rememberSelections,
                singleProducts: action.payload.singleProducts,
                keepBuilderOnOrder: action.payload.keepBuilderOnOrder,
                paginated: action.payload.paginated
            }
        case "NEXT_STEP":
            return {
                ...state,
                currentTab: action.payload.currentTab
            }
        case "SAVE_DISCOUNTS":
            return {
                ...state,
                discounts: action.payload
            }
        case "SET_PRODUCT":
            return {
                ...state,
                productId: action.payload
            }
        case "SAVE_SETTINGS":
            return {
                ...state,
                builderName: action.payload.builderName,
                active: action.payload.active,
                theme: action.payload.theme,
                lightbox: action.payload.lightbox,
                productId: action.payload.productId,
                primary: action.payload.primary,
                secondary: action.payload.secondary,
                useVariants: action.payload.useVariants,
                taxAble: action.payload.taxAble,
                isFixed: action.payload.isFixed,
                fixedPrice: action.payload.fixedPrice,
                addText: action.payload.addText,
                selectedColor: action.payload.selectedColor,
                seoTitle: action.payload.seoTitle,
                seoDescription: action.payload.seoDescription,
                seoKeywords: action.payload.seoKeywords,
                showPrices: action.payload.showPrices,
                formatDescription: action.payload.formatDescription,
                borderColor: action.payload.borderColor,
                lightboxBg: action.payload.lightboxBg,
                showCarousel: action.payload.showCarousel,
                showProgress: action.payload.showProgress,
                progressTextColor: action.payload.progressTextColor,
                progressBarColor: action.payload.progressBarColor,
                translations: action.payload.translations,
                totalBoxBackground: action.payload.totalBoxBackground,
                totalColor: action.payload.totalColor,
                priceColor: action.payload.priceColor,
                boxContentsText: action.payload.boxContentsText,
                customJS: action.payload.customJS,
                customHTML: action.payload.customHTML,
                modalColor: action.payload.modalColor,
                showThumbnails: action.payload.showThumbnails,
                startPrice: action.payload.startPrice,
                showBuilding: action.payload.showBuilding,
                buildingGif: action.payload.buildingGif,
                buildingBackground: action.payload.buildingBackground,
                buildingTitle: action.payload.buildingTitle,
                buildingText: action.payload.buildingText,
                hideSoldOut: action.payload.hideSoldOut,
                redirectTo: action.payload.redirectTo,
                requiresShipping: action.payload.requiresShipping,
                fulfilmentOption: action.payload.fulfilmentOption,
                customDomain: action.payload.customDomain,
                rememberSelections: action.payload.rememberSelections,
                singleProducts: action.payload.singleProducts,
                keepBuilderOnOrder: action.payload.keepBuilderOnOrder,
                paginated: action.payload.paginated
            }
        case "EXIT_BUILDER":
            return {
                ...state,
                builderId: '',
                steps: [],
                builderName: '',
                currentTab: 0,
                active: true,
                lightbox: true,
                productId: '',
                discounts: [],
                useVariants: false,
                taxAble: true,
                isFixed: false,
                fixedPrice: '',
                addText: '',
                seoTitle: '',
                seoDescription: '',
                seoKeywords: '',
                showPrices: true,
                formatDescription: false,
                borderColor: '#d9e1e6',
                lightboxBg: 'rgba(0,0,0,.8)',
                showCarousel: true,
                showProgress: false,
                progressTextColor: '#3a3a3a',
                progressBarColor: '#d9e1e6',
                translations: [],
                totalBoxBackground: '#fff', 
                totalColor: '#ccc', 
                priceColor: '#000',
                boxContentsText: '#555',
                customJS: '',
                customHTML: '',
                modalColor: '#fff',
                showThumbnails: false,
                startPrice: 0.00,
                showBuilding: false,
                buildingGif: 'https://assets9.lottiefiles.com/packages/lf30_editor_hdzgigho.json',
                buildingBackground: '#fff',
                buildingTitle: '#000',
                buildingText: '#000',
                hideSoldOut: false,
                redirectTo: '/cart',
                requiresShipping: true,
                fulfilmentOption: 'manual',
                customDomain: '',
                rememberSelections: true,
                singleProducts: false,
                keepBuilderOnOrder: false,
                paginated: false
            }
        case "ADD_STEP":
            return {
                ...state,
                steps: [...state.steps, action.payload]
            }
        case "SET_STEPS":
            return {
                ...state,
                steps: action.payload
            }
        default:
            return state;
    }
}