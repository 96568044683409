import React from 'react';
import { connect } from 'react-redux';
import ReactQueryParams from 'react-query-params';
import { fb } from './../../actions/settings';
import { LoadingPage } from './../loading';
import ErrorPage from './../auth/error';
import { confirmPlan, login } from './../../actions/';

////////////////////
// Shopify directs the installer here after they accept the charge.
// - It sends the query params to the express API to confirm it came from Shopify and the charge was accepted.
// - And then sets up the store in the DB ready to accept the charge.
// Install/OAuth Flow described here: https://shopify.dev/apps/auth/oauth
////////////////////

class Paid extends ReactQueryParams {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: ''
        }
    }

    componentDidMount = async () => {
        try {
            let res = await confirmPlan(this.queryParams);
            if (res.error) {
                this.setState({
                    loading: false,
                    error: res.message
                });
            } else {
                let user = await fb.auth()
                    .signInWithEmailAndPassword(res.user.email, res.unique);
                if (user) {
                    this.props.login(res.user);
                    window.location.href = `https://admin.shopify.com/store/${res.user.shop.replace(".myshopify.com", "")}/apps/${process.env.REACT_APP_NAME}`;
                }
            }
        } catch(e) {
            console.log(e);
            this.setState({
                loading: false,
                error: e.message
            });
        }
    }

    render() {
        let { loading } = this.state;
        if (loading) {
            return <LoadingPage  shop=""/>;
        } else {
            return <ErrorPage/>;
        }
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Paid);