import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loading } from './../home/loading';
import { login, getOrder, checkAuth, unwrapOrder } from './../../actions/';
import { Page, Card, DataTable } from '@shopify/polaris';


function OrderContents(props) {

    const [loading, setLoading] = useState(true);
    const [boxes, setBoxes] = useState('');
    const [actions, setActions] = useState(false);
    const [error, setError] = useState(false);
    const [orderDetails, setOrder] = useState({});
    const [attributes, setAtts] = useState([]);

    useEffect(() => {
        let search = props.location.search.substring(1); 
        let params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        async function doItAll() {
            try {
                let res = await checkAuth(params);
                console.log("params", params)
                props.login(res.user);
                
                async function getOrderDetails(id) {
                    let data = await getOrder(props.shop.info.id, id);
                    console.log("data", data)
                    setBoxes(data.boxes);
                    setLoading(false);
                    setOrder(data.order);
                    setAtts(data.order.note_attributes);
                }
                getOrderDetails(params.id);
            } catch(e) {
                console.log(e);           
            }
        }
        doItAll();
    }, [props.location.search]);

    const unwrap = async() => {
        setLoading(true);
        let data = await unwrapOrder(props.shop.info.id, orderDetails.id);
        console.log("data", data);
        if (data.unwrapped) {
            window.open(`https://${props.shop.info.shop}/admin/orders/${orderDetails.id}`, "_blank");
        } else {
            setError(true);
            setLoading(false);
        }
    }
    
    if (loading) {
        return <Loading/>
    } else {
        let cards = boxes.map((box, i) => {
            if (box.products && box.products.length > 0) {
                let rows = [];
                box.products[0].map((product) => {
                    console.log(product)
                    console.log(product.variant)
                    if (product && product.variant) rows.push([`${product.title}`, product.variant.title, product.quantity, product.variant.price, '']);
                });
                return <Card key={i}>
                    <Card.Header
                        title={box.name}
                    >
                    </Card.Header>
                    <Card.Section>
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                            ]}
                            rows={rows}
                            headings={['Product', 'Variant', 'Qty', 'Price', '']}
                        />
                    </Card.Section>
                </Card>
            }
        });
        let info = attributes.map((property, x) => {
            return [`${property.name}`, property.value];
        });
        return <Page
            title={`Boxes on order: #${orderDetails.order_number}`}
            subtitle="You can view and unwrap the boxes here. Unwrapping removes the builder and adds in the individual variants."
            separator
            primaryAction={{content: 'Unwrap', onAction: () => unwrap()}}
        >
            {!error && 
            <div>
                {cards}
                <Card>
                    <Card.Header
                        title="Additional Information"
                    >
                    </Card.Header>
                    <Card.Section>
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text'
                            ]}
                            rows={info}
                            headings={['Name', 'Value']}
                        />
                    </Card.Section>
                </Card>
            </div>
            }
            {error &&
            <Card>
                <Card.Header
                    title="Error trying to unwrap"
                >
                </Card.Header>
                <Card.Section>
                    <p>It looks like Shopify didn't allow the order to be unwrapped. Please contact us and send over the order ID: {orderDetails.id}</p>
                </Card.Section>
            </Card>
            }
        </Page>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OrderContents));