import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { db } from "./../../actions/settings";
import { checkPaginatedSection } from "./../../actions/";
import {
  login,
  createBuilder,
  editBuilder,
  closeBuilder,
  exitBuilder,
  setPath,
  createProduct,
  deleteProduct,
  exportFile,
  cleanReducer,
} from "./../../actions/";
import {
  Page,
  Card,
  DataTable,
  EmptyState,
  Button,
  ActionList,
  Popover,
  MediaCard,
  Layout,
  Badge,
  Banner,
  Modal as SimpleModal,
  Icon,
} from "@shopify/polaris";
import { Loading } from "./loading";
import { ActiveBadge } from "./../../components/ActiveBadge";
import createApp from "@shopify/app-bridge";
import { Modal, Button as ModalButton } from "@shopify/app-bridge/actions";
import {
  DeleteMinor,
  DuplicateMinor,
  ArrowDownMinor,
  ProductsMajorMonotone,
  QuestionMarkMajorMonotone,
} from "@shopify/polaris-icons";
import { ReviewPopUp } from "./reviewPopUp";
import DismissibleBanner from "../../components/DismissibleBanner";

let random = require("randomstring");

class HomeComponent extends React.Component {
  state = {
    loading: true,
    editing: false,
    actions: false,
    showCompare: false,
    builds: [],
  };

  componentDidMount = async () => {
    if (this.props.shop.info.version == process.env.REACT_APP_VERSION) {
      this.loadBuilders();
      this.props.setPath("My Builders");
    } else {
      this.props.history.push("/admin/version");
    }
    if (window.$zoho.salesiq.visitor)
      window.$zoho.salesiq.visitor.email(this.props.shop.info.shop || "");
  };

  loadBuilders = async () => {
    let builds = [];
    try {
      let docs = await db
        .collection("Builders")
        .where("shopId", "==", this.props.shop.info.id)
        .where("deleted", "==", false)
        .get();
      if (docs.size > 0) {
        docs.forEach((doc) => {
          builds.push(doc.data());
        });
        this.setState({
          builds: builds,
          loading: false,
        });
      } else {
        this.setState({
          builds: [],
          loading: false,
        });
        let element = window.document.getElementsByClassName(
          "Polaris-EmptyState__ImageContainer"
        )[0];
        window.lottie.loadAnimation({
          container: element,
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: "/assets/image/loading-box.json", // the path to the animation json
        });
      }
    } catch (e) {
      console.log(e);
      this.props.history.push("/error");
    }
  };

  viewBuilder = (build) => {
    const builderURL = `/apps/builder?b=${build.builderId}`;
    window.open(`https://${this.props.shop.info.shop}${builderURL}`, "_blank");
  };

  collectionData = (steps, builderId) => {
    let returnData = [];
    return new Promise((resolve) => {
      if (steps.length === 0) resolve(returnData);
      for (let x = 0, p = Promise.resolve(); x < steps.length; x++) {
        p = p.then(
          (_) =>
            new Promise(async (pResolve) => {
              let data = steps[x];
              let cDoc = await db
                .collection(
                  `Builders/${builderId}/Steps/Step${Number(x + 1)}/Filters`
                )
                .doc("Collections")
                .get();
              if (cDoc.exists) {
                let cData = cDoc.data().collections;
                data.selectedCollections = cData;
                returnData.push(data);
                pResolve();
              } else {
                data.selectedCollections = [];
                returnData.push(data);
                pResolve();
              }
              if (x === steps.length - 1) resolve(returnData);
            })
        );
      }
    });
  };

  editBuilder = async (build) => {
    let step = 0;
    this.setState({ editing: true }, async () => {
      let steps = [];
      let docs = await db.collection(`Builders/${build.builderId}/Steps`).get();
      if (docs.size > 0) {
        docs.forEach(async (doc) => {
          steps.push(doc.data());
          if (build.speed) {
            let products = [];
            let pDocs = await db
              .collection(
                `Builders/${build.builderId}/Steps/${doc.id}/Products`
              )
              .get();
            if (pDocs.size > 0) {
              let productChunks = [];
              pDocs.forEach((pDoc) =>
                productChunks.push({
                  products: pDoc.data().products,
                  order: Number(pDoc.id.replace("Chunk", "")),
                })
              );
              productChunks.sort((a, b) => a.order - b.order);
              productChunks.forEach((chunk) => {
                chunk.products.forEach((product) => products.push(product));
              });
            }
            steps[step].products = products;
          }
          step++;
        });
      }
      steps = await this.collectionData(steps, build.builderId);
      build.steps = steps;
      build.lightbox = build.lightbox === undefined ? true : build.lightbox;
      build.productId = build.productId === undefined ? "" : build.productId;
      build.useVariants =
        build.useVariants === undefined ? false : build.useVariants;
      build.isFixed = build.isFixed === undefined ? false : build.isFixed;
      build.customJS = build.customJS === undefined ? "" : build.customJS;
      build.customHTML = build.customHTML === undefined ? "" : build.customHTML;
      build.customDomain =
        build.customDomain === undefined ? "" : build.customDomain;
      build.rememberSelections =
        build.rememberSelections === undefined ? "" : build.rememberSelections;
      build.singleProducts =
        build.singleProducts === undefined ? false : build.singleProducts;
      build.keepBuilderOnOrder =
        build.keepBuilderOnOrder === undefined
          ? false
          : build.keepBuilderOnOrder;
      build.paginated = build.paginated === undefined ? false : build.paginated;

      await this.props.editBuilder(build);
      if (build.speed) {
        this.props.history.push("/admin/speed/" + build.builderId);
      } else {
        await checkPaginatedSection(this.props.shop.info.id);
        this.props.history.push({
          pathname: "/admin/builder/" + build.builderId,
          state: { paginated: build.paginated },
        });
      }
    });
  };

  confirmDelete = (build) => {
    const app = createApp({
      apiKey: process.env.REACT_APP_API_KEY,
      shopOrigin: this.props.shop.info.shop,
    });
    const okButton = ModalButton.create(app, {
      label: "Delete",
      style: ModalButton.Style.Danger,
    });
    const cancelButton = ModalButton.create(app, { label: "Cancel" });
    cancelButton.subscribe(ModalButton.Action.CLICK, () => {
      modal.dispatch(Modal.Action.CLOSE);
    });
    okButton.subscribe(ModalButton.Action.CLICK, () => {
      this.deleteBuild(build);
      modal.dispatch(Modal.Action.CLOSE);
    });
    const modalOptions = {
      title: "Delete builder",
      message:
        "Are you sure you wish to delete the " +
        build.builderName +
        " builder?",
      footer: {
        buttons: {
          primary: okButton,
          secondary: [cancelButton],
        },
      },
    };
    const modal = Modal.create(app, modalOptions);
    modal.dispatch(Modal.Action.OPEN);
  };

  deleteBuild = async (build) => {
    this.setState({ loading: true }, async () => {
      await db
        .collection("Builders")
        .doc(build.builderId)
        .update({ deleted: true });
      if (build.productId) {
        await deleteProduct(this.props.shop.info.id, build.productId);
      }
      this.loadBuilders();
    });
  };

  saveChunks = async (newId, docs, id) => {
    let chunks = [];
    docs.forEach((doc) => {
      chunks.push(doc.data());
    });
    return new Promise((resolve) => {
      for (let x = 0, p = Promise.resolve(); x < chunks.length; x++) {
        p = p.then(
          (_) =>
            new Promise(async (pResolve) => {
              console.log("Saving", x, "chunk");
              await db
                .collection(`Builders/${newId}/Steps/${id}/Products`)
                .doc(`Chunk${x + 1}`)
                .set(chunks[x]);
              if (x === chunks.length - 1) resolve();
              pResolve();
            })
        );
      }
    });
  };

  copyBuilder = async (builderId) => {
    this.setState({ loading: true }, async () => {
      let newId = random.generate(24);
      let bDoc = await db.collection("Builders").doc(builderId).get();
      if (bDoc.exists) {
        let bData = { ...bDoc.data() };
        bData.builderId = newId;
        bData.builderName = bData.builderName + " - copy";
        let product = await createProduct({
          title: bData.builderName,
          id: this.props.shop.info.id,
          builderId: newId,
        });
        bData.productId = product.id;
        await db.collection("Builders").doc(newId).set(bData);
        let sDocs = await db.collection(`Builders/${builderId}/Steps`).get();
        if (sDocs.size > 0) {
          sDocs.forEach(async (sDoc) => {
            await db
              .collection(`Builders/${newId}/Steps`)
              .doc(sDoc.id)
              .set(sDoc.data());
            let fDoc = await db
              .collection(`Builders/${builderId}/Steps/${sDoc.id}/Filters`)
              .doc("Collections")
              .get();
            if (fDoc.exists) {
              await db
                .collection(`Builders/${newId}/Steps/${sDoc.id}/Filters`)
                .doc("Collections")
                .set(fDoc.data());
            }
            let pDoc = await db
              .collection(`Builders/${builderId}/Steps/${sDoc.id}/Products`)
              .get();
            if (pDoc.size > 0) {
              await this.saveChunks(newId, pDoc, sDoc.id);
            }
          });
        }
        this.loadBuilders();
      }
    });
  };

  exportOutstanding = async (i, builder) => {
    this.setState({ editing: true }, async () => {
      let stateVar = i ? `actions_${i}` : "actions";
      this.setState({ [stateVar]: false });
      let query =
        i === 0 || i > 0
          ? `?shopId=${this.props.shop.info.id}&builderId=${builder.builderId}&builderName=${builder.builderName}`
          : `?shopId=${this.props.shop.info.id}`;
      let blob = await exportFile(query, "outstanding");
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "Recent Orders - Box Builder.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
      this.setState({ editing: false });
    });
  };

  createBuilders = () => {
    return (
      <div className="builder-types" style={{ marginBottom: "30px" }}>
        <Layout>
          <Layout.Section fullWidth>
            <Banner
              title="Enable BundleBuilder Theme Helper"
              status="critical"
              action={{
                content: "Enable BundleBuilder Theme Helper",
                external: true,
                url: `https://admin.shopify.com/store/${this.props.shop.info.shop.replace(".myshopify.com", "")}/themes/current/editor?context=apps&template=product&activateAppId=0616c381-b470-4395-b013-48a4995ac72a/bundle_builder`
              }}
            >
              <p>
                Please ensure that the 'BundleBuilder Theme Helper' is enabled for a seamless transition to version 2.0.<br />
              </p>
              <br />
              <p>
                Click the button below to open the theme editor and click <b>Save</b> in the top right. 
              </p>
            </Banner>
            <br />
            <Card
              title="Which Builder Type should you use?"
              primaryFooterAction={{
                content: "Compare Builder Types",
                onAction: () => this.setState({ showCompare: true }),
              }}
            >
              <Card.Section>
                We now have some options for you when it comes to setting up
                your builders. Each Builder Type has its benefits.
              </Card.Section>
            </Card>
            <div style={{ marginBottom: "15px" }}></div>
          </Layout.Section>
          <Layout.Section oneThird>
            <MediaCard
              size="small"
              primaryAction={{
                content: "Create Builder",
                onAction: async () => {
                  this.setState({ loading: true });
                  await this.props.cleanReducer();
                  await checkPaginatedSection(this.props.shop.info.id);
                  this.setState({ loading: false });
                  this.props.history.push({
                    pathname: "/admin/builder/",
                    state: { paginated: true },
                  });
                },
              }}
              title="Simple & Fast"
            >
              <img src="/assets/image/rocket.svg" width="100%" height="100%" />
            </MediaCard>
          </Layout.Section>
          <Layout.Section oneThird>
            <Badge status="info">Popular</Badge>
            <MediaCard
              size="small"
              primaryAction={{
                content: "Create Builder",
                onAction: async () => {
                  await this.props.cleanReducer();
                  console.log(this.props.builder);
                  this.props.history.push("/admin/builder/");
                },
              }}
              title="Custom & Small"
              description="Most popular option! Add products individually or by collection."
            >
              <img src="/assets/image/small.svg" width="100%" height="100%" />
            </MediaCard>
          </Layout.Section>
          <Layout.Section oneThird>
            <MediaCard
              size="small"
              primaryAction={{
                content: "Create Builder",
                onAction: async () => {
                  await this.props.cleanReducer();
                  this.props.history.push("/admin/speed/");
                },
              }}
              title="Custom & Big"
            >
              <img src="/assets/image/old.svg" width="100%" height="100%" />
            </MediaCard>
          </Layout.Section>
        </Layout>
      </div>
    );
  };

  compareRows = [
    [
      "Loading Speed",
      <Badge status="success">Fast</Badge>,
      <Badge>Slower</Badge>,
      <Badge status="info">Medium</Badge>,
    ],
    [
      <span>
        Can add products using a collection
        <div className="tooltip">
          <Icon source={QuestionMarkMajorMonotone} color="base" />
          <span className="tooltiptext">
            When building a step which contains products, you can tell the
            builder to load all products in a collection.
          </span>
        </div>
      </span>,
      <Badge status="success">Yes</Badge>,
      <Badge status="success">Yes</Badge>,
      <Badge>No</Badge>,
    ],
    [
      <span>
        Can add individual products
        <div className="tooltip">
          <Icon source={QuestionMarkMajorMonotone} color="base" />
          <span className="tooltiptext">
            When building a step which contains products, you can tell the
            builder to load specific products that you select.
          </span>
        </div>
      </span>,
      <Badge>No</Badge>,
      <Badge status="success">Yes</Badge>,
      <Badge status="success">Yes</Badge>,
    ],
    [
      "Recommended Product Limits",
      <Badge status="success">Unlimited</Badge>,
      <Badge>50</Badge>,
      <Badge status="success">Unlimited</Badge>,
    ],
    [
      <span>
        Can show hidden products
        <div className="tooltip">
          <Icon source={QuestionMarkMajorMonotone} color="base" />
          <span className="tooltiptext">
            Once products have been added to the builder, you can then hide them
            from the Online Store and they'll remain on the builder.
          </span>
        </div>
      </span>,
      <Badge>No</Badge>,
      <Badge>No</Badge>,
      <Badge status="success">Yes</Badge>,
    ],
    [
      <span>
        Can edit the liquid code
        <div className="tooltip">
          <Icon source={QuestionMarkMajorMonotone} color="base" />
          <span className="tooltiptext">
            The liquid can be found in your theme's 'Sections' and is therefore
            editable.
          </span>
        </div>
      </span>,

      <Badge status="success">Yes</Badge>,
      <Badge>No</Badge>,
      <Badge>No</Badge>,
    ],
  ];

  render() {
    let { loading, builds, editing } = this.state;
    let createBuilders = this.createBuilders();
    if (loading) {
      return <Loading />;
    } else {
      if (builds.length === 0) {
        return (
          <Page
            title="BundleBuilder: Custom Bundles"
            subtitle="Create custom, step-by-step bundle builders."
            separator
          >
            <EmptyState heading="Create your first builder" image={""}>
              <p>Which option suits you?</p>
            </EmptyState>
            {createBuilders}
          </Page>
        );
      } else {
        let rows = builds.map((build, i) => {
          let activator = (
            <Button
              onClick={() => this.setState({ [`actions_${i}`]: true })}
              disclosure
            >
              More Actions
            </Button>
          );
          let version = build.speed ? (
            <Badge status="warning">Custom & Big</Badge>
          ) : build.paginated ? (
            <Badge status="info">Simple & Fast</Badge>
          ) : (
            <Badge>Custom & Small</Badge>
          );
          // this.setState({[`actions_${i}`]: false})
          return [
            <a href="#" onClick={() => this.viewBuilder(build)}>
              {build.builderName}
            </a>,
            build.stepCount,
            version,
            <ActiveBadge active={build.active} />,
            <Button
              onClick={() => this.editBuilder(build)}
              size="slim"
              loading={this.state.editing}
              primary
            >
              Edit
            </Button>,
            <>
              <Popover
                active={this.state[`actions_${i}`]}
                activator={activator}
                onClose={() => this.setState({ [`actions_${i}`]: false })}
              >
                <ActionList
                  size="slim"
                  sections={[
                    {
                      title: "More Actions",
                      items: [
                        {
                          content: "Copy Builder",
                          icon: DuplicateMinor,
                          onAction: () => this.copyBuilder(build.builderId),
                        },
                        {
                          content: "View Product",
                          icon: ProductsMajorMonotone,
                          external: true,
                          url: `https://${this.props.shop.info.shop}/admin/products/${build.productId}`,
                        },
                        {
                          content: "Export Recent Orders",
                          icon: ArrowDownMinor,
                          onAction: () => this.exportOutstanding(i, build),
                          loading: editing,
                        },
                        {
                          destructive: true,
                          content: "Delete Builder",
                          onAction: () => this.confirmDelete(build),
                          icon: DeleteMinor,
                          active: true,
                        },
                      ],
                    },
                  ]}
                />
              </Popover>
            </>,
          ];
        });
        return (
          <Page
            title="BundleBuilder: Custom Bundles"
            subtitle="Create custom, step-by-step bundle builders."
            separator
          >
            {createBuilders}
            <Card>
              <Card.Header title="My Builders">
                <ReviewPopUp
                  numberOfBuilders={this.state.builds.length}
                  shop={this.props.shop.info.shop}
                ></ReviewPopUp>
                <Popover
                  active={this.state.actions}
                  activator={
                    <Button
                      disclosure
                      plain
                      onClick={() => this.setState({ actions: true })}
                    >
                      More Actions
                    </Button>
                  }
                  onClose={() => this.setState({ actions: false })}
                >
                  <ActionList
                    items={[
                      {
                        content: "Export All Recent Orders",
                        icon: ArrowDownMinor,
                        onAction: () => this.exportOutstanding(),
                        loading: editing,
                      },
                    ]}
                  />
                </Popover>
              </Card.Header>
              <Card.Section>
                <DataTable
                  columnContentTypes={[
                    "text",
                    "text",
                    "text",
                    "text",
                    "text",
                    "text",
                    "",
                  ]}
                  headings={[
                    "Builder",
                    "No. of Steps",
                    "Builder Type",
                    "",
                    "",
                    "",
                    "",
                    "",
                  ]}
                  rows={rows}
                />
              </Card.Section>
            </Card>
            <div>
              <SimpleModal
                large
                open={this.state.showCompare}
                onClose={() =>
                  this.setState({
                    showCompare: false,
                  })
                }
                title="Compare Builder Types"
                secondaryActions={[
                  {
                    content: "Okay",
                    onAction: () =>
                      this.setState({
                        showCompare: false,
                      }),
                  },
                ]}
              >
                <SimpleModal.Section>
                  <DataTable
                    columnContentTypes={["text", "text", "text", "text"]}
                    headings={[
                      "Feature",
                      "Simple & Fast",
                      "Custom & Small",
                      "Custom & Big",
                    ]}
                    rows={this.compareRows}
                    totals={["", "", "", ""]}
                    footerContent="All builders share many other features."
                  />
                </SimpleModal.Section>
              </SimpleModal>
            </div>
          </Page>
        );
      }
    }
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  login,
  createBuilder,
  editBuilder,
  closeBuilder,
  exitBuilder,
  setPath,
  cleanReducer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomeComponent));
