import React, { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
    Form, FormLayout, TextField, Button, TextStyle, Card,
    Avatar, Thumbnail, ResourceList, ResourceItem, TextContainer,
    InlineError, Checkbox, ButtonGroup, Banner, Select
} from '@shopify/polaris';

import createApp from '@shopify/app-bridge';
import { ResourcePicker, Toast } from '@shopify/app-bridge/actions';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

////////////////////
// Bulky Builder
// Store uses this screen when setting up/editing a Pre-Selected Product step
// All products added below are then selected on the builder (customer-end) and they aren't deselectable
////////////////////

const PreStep = React.forwardRef((props, ref) => {

    const [error, setError] = useState('');
    const [editing] = useState(props.editing !== null ? props.builder.steps[props.editing] : {});
    const [title, setTitle] = useState(editing.title || '');
    const [columns, setColumns] = useState(editing.columns || '4');
    const [description, setDescription] = useState(editing.description || '');
    const [displayOrder, setDisplayOrder] = useState(editing.displayOrder || '');
    const [selectedItems, setSelectedItems] = useState(editing.products || []);
    const [showPrice, setShowPrice] = useState((editing.showPrice === undefined) ? false : editing.showPrice);
    const [buttonText, setButtonText] = useState(editing.buttonText);
    const [showSummary, setShowSummary] = useState((editing.showSummary) === undefined  ? false : editing.showSummary);
    const [hideFromProgress, setHideFromProgress] = useState(editing.hideFromProgress === undefined  ? false : editing.hideFromProgress);
    let loadCollection = [];
    if (typeof editing.collection === 'object' && editing.collection.handle) {
        loadCollection[0] = editing.collection;
    } else {
        loadCollection[0] = {};
    }
    const [selectedCollection, setCollection] = useState(loadCollection[0]);

    const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        shopOrigin: props.shop.info.shop,
    });

    const picker = ResourcePicker.create(app, {
        resourceType: ResourcePicker.ResourceType.Product,
        options: {
            selectMultiple: true,
            showHidden: false,
            actionVerb: "select",
            showVariants: true
        }
    });

    const addPicker = ResourcePicker.create(app, {
        resourceType: ResourcePicker.ResourceType.Product,
        options: {
            selectMultiple: true,
            showHidden: false,
            actionVerb: "select",
            showVariants: true
        }
    });

    picker.subscribe(ResourcePicker.Action.SELECT, ({selection}) => {
        setSelectedItems(selection);
    });

    addPicker.subscribe(ResourcePicker.Action.SELECT, ({selection}) => {
        let items = selectedItems.concat(selection);
        setSelectedItems(items);
    });

    const collectionPicker = ResourcePicker.create(app, {
        resourceType: ResourcePicker.ResourceType.Collection,
        options: {
            selectMultiple: false,
            showHidden: false,
            actionVerb: "select"
        }
    });

    collectionPicker.subscribe(ResourcePicker.Action.SELECT, ({selection}) => {
        let col = {};
        if (selection.length > 0) {
            let sel = selection[0];
            col = {
                "handle": sel.handle,
                "id": sel.id,
                "title": sel.title || '',
                "sortOrder": sel.sortOrder || '',
                "image": (sel.image) ? sel.image.originalSrc : ''
            }
        }
        let newCol = [];
        newCol[0] = col;
        setCollection(col);
    });

    const toastOptions = {
        message: 'Step saved',
        duration: 3000
    };
    const toast = Toast.create(app, toastOptions);

    const addStep = () => {
        setError("");
        if (!title) return setError("Please enter a step title.");
        if (!displayOrder) return setError("Please enter a display order.");
        if (!buttonText) return setError("Please enter text to show in the button.");
        let products = [];
        let collection = {};
        if (!props.builder.paginated) {
            if (selectedItems.length === 0) return setError("Please add products or variants to this step.");
            selectedItems.map(product => {
                if (product.variants) {
                    product.variants.map((v) => {
                        let pId = (product.id).split("/")[(product.id).split("/").length-1];
                        let vid = (v.id).split("/")[(v.id).split("/").length-1];
                        products.push({
                            "title": product.title + " - " + v.title,
                            "handle": product.handle,
                            "images": product.images.length > 0 ? [{originalSrc: product.images[0].originalSrc}] : '',
                            "id": pId,
                            "gid": product.id,
                            "type": product.productType || '',
                            "qty": 1,
                            "variant": vid
                        });
                    });
                } else {
                    products.push({
                        "title": product.title,
                        "handle": product.handle,
                        "images": product.images.length > 0 ? [{originalSrc: product.images[0].originalSrc}] : '',
                        "id": product.id,
                        "gid": product.gid,
                        "type": product.type || '',
                        "qty": product.qty,
                        "variant": product.variant
                    });
                }
            });
        } else {
            let colSelected = typeof(selectedCollection) === 'object' && selectedCollection.handle ? true : false;
            if (!colSelected) return setError("Please add a collection to this step.");
        }

        props.addStep(props.editing, {
            title,
            description,
            columns,
            displayOrder,
            products,
            collection: selectedCollection,
            type: 'pre',
            buttonText,
            showPrice,
            showSummary,
            hideFromProgress
        });
        toast.dispatch(Toast.Action.SHOW);
    }

    const removeSelection = (i) => {
        let newItems = selectedItems.filter((item, x) => x !== i);
        setSelectedItems(newItems);
    }

    const setQty = (i, val) => {
        let newItems = [...selectedItems];
        let item = {
            ...newItems[i],
            qty: String(val)
        }
        newItems[i] = item;
        setSelectedItems(newItems);
    }

    useEffect(() => {
        if (editing) {
            picker.dispatch(ResourcePicker.Action.SELECT, selectedItems);
        }
    }, []);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          selectedItems,
          result.source.index,
          result.destination.index
        );
        setSelectedItems(items);
    }

    return (
        <Card 
            title="Add Products to be pre-selected" 
            sectioned
            secondaryFooterActions={[{
                content: 'Back',
                onAction: () => props.hideForm(false),
                loading: false
            }]}
            primaryFooterAction={{
                content: 'Save Step',
                onAction: () => addStep(),
                loading: false
            }}
        >
            <Card.Section>
                <TextContainer>
                The products you choose here will show up as pre-selected and cannot be removed by the customer.
                </TextContainer>
            </Card.Section>
            <Card.Section>
                <Form>
                    <FormLayout>
                        {error !== "" &&
                        <InlineError message={error} />
                        }
                        <TextField
                            label="Step Title"
                            value={title}
                            onChange={(val) => setTitle(val)}
                            type="text"
                            placeholder="e.g. Select Your Hard Drive"
                        />
                        <TextField
                            label="Step Description"
                            value={description}
                            onChange={(val) => setDescription(val)}
                            type="text"
                            placeholder="e.g. You can only select one hard drive "
                            helpText={
                                <span>
                                Additional text to help the customer understand what's required from this step.
                                </span>
                            }
                        />
                        <FormLayout.Group>
                            <TextField
                                label="Display Order"
                                type="number"
                                placeholder="e.g. 1"
                                value={displayOrder}
                                onChange={(val) => setDisplayOrder(val)}
                                helpText={
                                    <span>
                                    Set the order the step will display within this builder.
                                    </span>
                                }
                            />
                            {props.builder.theme === 'full' &&
                            <Select
                                label="Products per row"
                                options={[
                                    {label: '3', value: '4'},
                                    {label: '4', value: '3'}
                                ]}
                                onChange={(val) => setColumns(val)}
                                value={columns}
                                helpText=""
                            />
                            }
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <TextField
                                label="Button Text"
                                type="text"
                                placeholder="e.g. Included"
                                value={buttonText}
                                onChange={(val) => setButtonText(val)}
                                helpText={
                                    <span>
                                    These buttons are disabled for pre-selected products.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show Prices"
                                checked={showPrice}
                                onChange={setShowPrice}
                                helpText={
                                    <span>
                                    Removes the price from the button but still charges for the product.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Show Box Contents/Summary"
                                checked={showSummary}
                                onChange={setShowSummary}
                                helpText={
                                    <span>
                                    This will show the currently selected products below the step title.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Hide from Step Progress"
                                checked={hideFromProgress}
                                onChange={setHideFromProgress}
                                helpText={
                                    <span>
                                    Hide this step from the list of steps in the progress bar.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <hr/>
                        {!props.builder.paginated &&
                        <>
                        <ButtonGroup>
                            <Button onClick={() => picker.dispatch(ResourcePicker.Action.OPEN)}>Choose selectable products for this step</Button>
                            <Button onClick={() => addPicker.dispatch(ResourcePicker.Action.OPEN)}>Add more products</Button>
                        </ButtonGroup>
                        <Banner
                            title="Link a collection to products you wish to show"
                            status="info"
                            >
                            <p>Products with no collections will not show. If you have products like this, simply create a collection and attach all products before completing this step.</p>
                        </Banner>
                        </>
                        }
                        {props.builder.paginated &&
                        <>
                            <Button onClick={() => {
                                collectionPicker.dispatch(ResourcePicker.Action.OPEN);
                            }}>
                            Pick Collection
                            </Button>
                            {selectedCollection.handle &&
                            <div style={{paddingTop:'15px'}}>
                                <ResourceList
                                    resourceName={{singular: 'Collection', plural: 'Collection'}}
                                    items={selectedCollection ? [selectedCollection] : []}
                                    renderItem={(item, itemId, i) => {
                                        const { id, title, image } = item;
                                        return (
                                            <ResourceItem
                                                id={id}
                                                media={<Thumbnail alt={title} source={image || 'https://builder-front.boxup.io/img/placeholder.png'}/>}
                                                shortcutActions={[
                                                    {
                                                    content: 'Remove',
                                                    accessibilityLabel: 'Remove',
                                                    onAction: () => setCollection({}),
                                                    }
                                                ]}
                                                persistActions
                                                >
                                                <h3>
                                                    <TextStyle variation="strong">{title} selected.</TextStyle>
                                                </h3>
                                                <div>Products will load in the order defined in the collection.</div>

                                            </ResourceItem>
                                        );
                                    }}
                                />
                            </div>
                            }
                        </>
                        }
                        
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}>
                                    <ResourceList
                                        resourceName={{singular: 'Product', plural: 'Products'}}
                                        items={selectedItems}
                                        renderItem={(item, itemId, i) => {
                                            const shortcutActions = [{content: 'Remove', onAction: () => removeSelection(i)}];
                                            const { id, images, title, qty } = item;
                                            const media = (images.length > 0) ? <Thumbnail
                                                source={images[0].originalSrc}
                                                alt={title}
                                            />
                                            : <Avatar size="medium" initials="NA" />;
                                            return (
                                                <Draggable key={id} draggableId={id} index={i}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        >
                                                        <ResourceItem
                                                            
                                                            id={id}
                                                            media={media}
                                                            shortcutActions={shortcutActions}
                                                            persistActions
                                                            >
                                                            <h3>
                                                                <TextStyle variation="strong">{title}</TextStyle>
                                                                <TextField
                                                                    label="Qty"
                                                                    type="number"
                                                                    placeholder="e.g. 1"
                                                                    onChange={(val) => setQty(i, val)}
                                                                    value={String(qty)}
                                                                    helpText={
                                                                        <span>
                                                                        Set the quantity for this variant.
                                                                        </span>
                                                                    }
                                                                />
                                                            </h3>
                                                        </ResourceItem>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        }}
                                    />
                                    {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <hr/>
                    </FormLayout>
                </Form>
            </Card.Section>
        </Card>
    );
});

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(withRouter(PreStep));