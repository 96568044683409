import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { AppProvider } from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';

import Layout from './containers/layout';
import Auth from './screens/auth/';
import Install from './screens/auth/install';
import Paid from './screens/auth/paid';
import ErrorPage from './screens/auth/error';

import { login, logout } from './actions';

// layout routes
import Home from './screens/home';
import Builder from './screens/builder/';
import SpeedBuilder from './screens/speed/';
import Support from './screens/support';
import Version from './screens/auth/version';
import UpdateVersion from './screens/auth/update';

import OrderContents from './screens/orders/index';

import ExitIframe from './screens/auth/exitFrame';

class App extends React.Component {

    requireAuth = async () => {
        return this.props.shop.loggedIn;
    }
    
    render() {
        try {        
            return (
                <Router>
                    <Switch>
                        <Route path="/admin/" render={() => {
                            return this.requireAuth() && this.props.shop.info ? (
                                <AppProvider>
                                    <Provider config={{
                                apiKey: process.env.REACT_APP_API_KEY, 
                                shopOrigin: this.props.shop.info.shop,
                                forceRedirect: process.env.REACT_APP_FORCE_REDIRECT
                            }}>
                                        <Layout>
                                            <Route exact path="/admin/" component={Home}/>
                                            <Route exact path="/admin/builder" component={Builder}/>
                                            <Route exact path="/admin/builder/:id" component={Builder}/>
                                            <Route exact path="/admin/speed" component={SpeedBuilder}/>
                                            <Route exact path="/admin/speed/:id" component={SpeedBuilder}/>
                                            <Route exact path="/admin/support" component={Support}/>
                                            <Route exact path="/admin/version" component={Version}/>
                                            <Route exact path="/admin/orders/contents" component={OrderContents}/>
                                        </Layout>
                                    </Provider>
                                </AppProvider>
                            ) : (
                                <Redirect to="/logout"/>
                            )
                        }}/>
                        <Route exact path="/auth" component={Auth} />
                        <Route exact path="/install" component={Install} />
                        <Route exact path="/paid" component={Paid} />
                        <Route exact path="/update" component={UpdateVersion}/>
                        <Route exact path="/exitFrame" component={ExitIframe} />
                        <Route exact path="/error" component={ErrorPage} />
                        <Route component={ErrorPage} />
                    </Switch>
                </Router>
            )
        } catch(e) {
            console.log("Error", e.message);
            return null;
        }
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);