import { Modal, TextContainer } from "@shopify/polaris";
import React, { useState, useEffect, useCallback } from "react";
import firebase from "firebase";
import { db } from "../../actions/settings";

export const ReviewPopUp = (props) => {
  const [active, setActive] = useState(false);
  const handleChange = useCallback(
    (param) => {
      console.log(param, active);
      db.collection("tracking").doc(props.shop).set({
        review: param,
        date: firebase.firestore.Timestamp.now(),
      });
      if (param === "yes") {
        const okWindow = window.open(
          "https://apps.shopify.com/boxup-product-builder?reveal_new_review=true&utm_source=in-app&utm_medium=reviewmodalbb&utm_campaign=leavereview",
          "_blank",
          "noopener,noreferrer"
        );
        if (okWindow) okWindow.opener = null;
      } else if (param === "no") {
        const issuesWindow = window.open(
          "https://boxup.io/contact/?utm_source=in-app&utm_medium=reviewmodalbb&utm_campaign=havingissues",
          "_blank",
          "noopener,noreferrer"
        );
        if (issuesWindow) issuesWindow.opener = null;
      }
      setActive(!active);
    },
    [active]
  );

  useEffect(() => {
    if (!props.shop) return;
    db.collection("tracking")
      .doc(props.shop)
      .get()
      .then((doc) => {
        setActive(!doc.exists);
      });
  }, []);

  return props.numberOfBuilders === 1 ? (
    <div>
      <Modal
        open={active}
        onClose={() => handleChange("close")}
        title="Please share your experience"
        primaryAction={{
          content: "Leave a review",
          onAction: () => handleChange("yes"),
        }}
        secondaryActions={[
          {
            content: "No I'm having issues",
            onAction: () => handleChange("no"),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Great job! You created your first bundle. Please take a moment to
              leave us a review 🙌
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  ) : (
    <div></div>
  );
};
