import React, { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
    Form, FormLayout, TextField, Button, TextStyle, Card,
    Avatar, Thumbnail, ResourceList, ResourceItem, TextContainer,
    InlineError, Checkbox, ButtonGroup, Banner, Select
} from '@shopify/polaris';

import createApp from '@shopify/app-bridge';
import { ResourcePicker, Toast } from '@shopify/app-bridge/actions';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PreStep = React.forwardRef((props, ref) => {

    const [error, setError] = useState('');
    const [editing] = useState(props.editing !== null ? props.builder.steps[props.editing] : {});
    const [title, setTitle] = useState(editing.title || '');
    const [columns, setColumns] = useState(editing.columns || '4');
    const [description, setDescription] = useState(editing.description || '');
    const [displayOrder, setDisplayOrder] = useState(editing.displayOrder || '');
    const [selectedItems, setSelectedItems] = useState(editing.products || []);
    const [showPrice, setShowPrice] = useState((editing.showPrice === undefined) ? false : editing.showPrice);
    const [buttonText, setButtonText] = useState(editing.buttonText);
    const [showSummary, setShowSummary] = useState((editing.showSummary) === undefined  ? false : editing.showSummary);

    const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        shopOrigin: props.shop.info.shop,
    });

    const picker = ResourcePicker.create(app, {
        resourceType: ResourcePicker.ResourceType.Product,
        options: {
            selectMultiple: true,
            showHidden: false,
            actionVerb: "select",
            showVariants: true
        }
    });

    const addPicker = ResourcePicker.create(app, {
        resourceType: ResourcePicker.ResourceType.Product,
        options: {
            selectMultiple: true,
            showHidden: false,
            actionVerb: "select",
            showVariants: true
        }
    });

    picker.subscribe(ResourcePicker.Action.SELECT, ({selection}) => {
        console.log(selection);
        let products = formatProducts(selection);
        setSelectedItems(products);
    });

    addPicker.subscribe(ResourcePicker.Action.SELECT, ({selection}) => {
        let products = formatProducts(selection);
        let items = selectedItems.concat(products);
        setSelectedItems(items);
    });

    const toastOptions = {
        message: 'Step saved',
        duration: 3000
    };
    const toast = Toast.create(app, toastOptions);

    const formatProducts = (items) => {
        let products = [];
        items.map(product => {
            let vs = product.variants.sort(function(a, b) {
                if (a.price < b.price) return -1;
                if (a.price > b.price) return 1;
                return 0;
            });
            let pId = (product.pid) ? product.pid : (product.id).split("/")[(product.id).split("/").length-1];
            let images = product.images.map(image => {
                return {
                    id: image.id,
                    originalSrc: image.originalSrc || ''
                }
            });
            product.variants.map(variant => {
                let vId = (variant.id).split("/")[(variant.id).split("/").length-1];
                console.log(variant.image, (variant.image) ? (variant.image.originalSrc) ? variant.image.originalSrc : variant.image : '')
                products.push({
                    pid: pId,
                    body_html: (product.descriptionHtml !== undefined) ? product.descriptionHtml : product.body_html,
                    handle: product.handle,
                    images: images,
                    options: product.options,
                    type: product.productType || '',
                    tags: product.tags || [],
                    title: product.title,
                    variants: [{
                        id: variant.id,
                        vid: vId,
                        title: variant.title,
                        inventory_management: (variant.inventoryManagement !== undefined) ? variant.inventoryManagement : variant.inventory_management,
                        inventory_policy: (variant.inventoryPolicy !== undefined) ? variant.inventoryPolicy : variant.inventory_policy,
                        inventory_quantity: (variant.inventoryQuantity !== undefined) ? variant.inventoryQuantity : variant.inventory_quantity,
                        price: variant.price,
                        weight: variant.weight || '',
                        weight_unit: (variant.weightUnit !== undefined) ? variant.weightUnit : variant.weight_unit,
                        availableForSale: variant.availableForSale,
                        compare_at_price: (variant.compareAtPrice !== undefined) ? variant.compareAtPrice : variant.compare_at_price,
                        image: (variant.image) ? (variant.image.originalSrc) ? variant.image.originalSrc : variant.image : ''
                    }],
                    price: vs[0].price,
                    qty: product.qty
                });
            });
        });
        return products;
    }

    const addStep = () => {
        setError("");
        if (!title) return setError("Please enter a step title.");
        if (!displayOrder) return setError("Please enter a display order.");
        if (!buttonText) return setError("Please enter text to show in the button.");
        if (selectedItems.length === 0) return setError("Please add products or variants to this step.");
        let products = formatProducts(selectedItems);
        
        console.log("products", products)
        props.addStep(props.editing, {
            title,
            description,
            columns,
            displayOrder,
            products,
            type: 'pre',
            buttonText,
            showPrice,
            showSummary
        });
        toast.dispatch(Toast.Action.SHOW);
    }

    const removeSelection = (i) => {
        let newItems = selectedItems.filter((item, x) => x !== i);
        setSelectedItems(newItems);
    }

    const setQty = (i, val) => {
        let newItems = [...selectedItems];
        let item = {
            ...newItems[i],
            qty: String(val)
        }
        newItems[i] = item;
        setSelectedItems(newItems);
    }

    useEffect(() => {
        if (editing) {
            picker.dispatch(ResourcePicker.Action.SELECT, selectedItems);
        }
    }, []);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          selectedItems,
          result.source.index,
          result.destination.index
        );
        setSelectedItems(items);
    }

    return (
        <Card 
            title="Add Products to be pre-selected" 
            sectioned
            secondaryFooterActions={[{
                content: 'Back',
                onAction: () => props.hideForm(false),
                loading: false
            }]}
            primaryFooterAction={{
                content: 'Save Step',
                onAction: () => addStep(),
                loading: false
            }}
        >
            <Card.Section>
                <TextContainer>
                The products you choose here will show up as pre-selected and cannot be removed by the customer.
                </TextContainer>
            </Card.Section>
            <Card.Section>
                <Form>
                    <FormLayout>
                        {error !== "" &&
                        <InlineError message={error} />
                        }
                        <TextField
                            label="Step Title"
                            value={title}
                            onChange={(val) => setTitle(val)}
                            type="text"
                            placeholder="e.g. Select Your Hard Drive"
                        />
                        <TextField
                            label="Step Description"
                            value={description}
                            onChange={(val) => setDescription(val)}
                            type="text"
                            placeholder="e.g. You can only select one hard drive "
                            helpText={
                                <span>
                                Additional text to help the customer understand what's required from this step.
                                </span>
                            }
                        />
                        <FormLayout.Group>
                            <TextField
                                label="Display Order"
                                type="number"
                                placeholder="e.g. 1"
                                value={displayOrder}
                                onChange={(val) => setDisplayOrder(val)}
                                helpText={
                                    <span>
                                    Set the order the step will display within this builder.
                                    </span>
                                }
                            />
                            {props.builder.theme === 'full' &&
                            <Select
                                label="Products per row"
                                options={[
                                    {label: '3', value: '4'},
                                    {label: '4', value: '3'}
                                ]}
                                onChange={(val) => setColumns(val)}
                                value={columns}
                                helpText=""
                            />
                            }
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <TextField
                                label="Button Text"
                                type="text"
                                placeholder="e.g. Included"
                                value={buttonText}
                                onChange={(val) => setButtonText(val)}
                                helpText={
                                    <span>
                                    These buttons are disabled for pre-selected products.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show Prices"
                                checked={showPrice}
                                onChange={setShowPrice}
                                helpText={
                                    <span>
                                    Removes the price from the button but still charges for the product.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show Box Contents/Summary"
                                checked={showSummary}
                                onChange={setShowSummary}
                                helpText={
                                    <span>
                                    This will show the currently selected products below the step title.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <hr/>
                        <ButtonGroup>
                            {selectedItems.length === 0 &&
                            <Button onClick={() => picker.dispatch(ResourcePicker.Action.OPEN)}>Choose selectable products for this step</Button>
                            }
                            {selectedItems.length > 0 &&
                            <Button onClick={() => addPicker.dispatch(ResourcePicker.Action.OPEN)}>Add more products</Button>
                            }
                        </ButtonGroup>
                        <Banner
                            title="Link a collection to products you wish to show"
                            status="info"
                            >
                            <p>Products with no collections will not show. If you have products like this, simply create a collection and attach all products before completing this step.</p>
                        </Banner>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}>
                                    <ResourceList
                                        resourceName={{singular: 'Product', plural: 'Products'}}
                                        items={selectedItems}
                                        renderItem={(item, itemId, i) => {
                                            const shortcutActions = [{content: 'Remove', onAction: () => removeSelection(i)}];
                                            const { id, images, title, qty, variants } = item;
                                            const media = (images.length > 0) ? <Thumbnail
                                                source={images[0].originalSrc}
                                                alt={title}
                                            />
                                            : <Avatar size="medium" initials="NA" />;
                                            return (
                                                <Draggable key={id} draggableId={id} index={i}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        >
                                                        <ResourceItem
                                                            
                                                            id={id}
                                                            media={media}
                                                            shortcutActions={shortcutActions}
                                                            persistActions
                                                            >
                                                            <h3>
                                                                <TextStyle variation="strong">{title}  {variants[0] ? "- " + variants[0].title : ''}</TextStyle>
                                                                <TextField
                                                                    label="Qty"
                                                                    type="number"
                                                                    placeholder="e.g. 1"
                                                                    onChange={(val) => setQty(i, val)}
                                                                    value={String(qty)}
                                                                    helpText={
                                                                        <span>
                                                                        Set the quantity for this variant.
                                                                        </span>
                                                                    }
                                                                />
                                                            </h3>
                                                        </ResourceItem>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        }}
                                    />
                                    {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <hr/>
                    </FormLayout>
                </Form>
            </Card.Section>
        </Card>
    );
});

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(withRouter(PreStep));