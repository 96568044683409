import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
    Form, FormLayout, TextField, Card, Checkbox, InlineError, Banner
} from '@shopify/polaris';
import { createApp } from '@shopify/app-bridge';
import { setupModalAutoSizing } from '@shopify/app-bridge-utils';
import { Toast } from '@shopify/app-bridge/actions';

////////////////////
// Bulky Builder
// Store uses this screen when setting up/editing a Subscription Step
// This step is empty until the 'Builder' Cloud function adds in the liquid code 
// which loops through available selling plans for this builder's product ID
// Selling plans are available through Shopify's subscription API https://shopify.dev/apps/subscriptions/getting-started
////////////////////

const SubStep = (props) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [modal, openModal] = useState(false);
    const [editing] = useState(props.editing !== null ? props.builder.steps[props.editing] : {});
    const [title, setTitle] = useState(editing.title || '');
    const [description, setDescription] = useState(editing.description || '');
    const [shortTitle, setShortTitle] = useState(editing.shortTitle || '');
    const [displayOrder, setDisplayOrder] = useState(editing.displayOrder || '');
    const [required, setRequired] = useState((editing.required) === undefined  ? false : editing.required);
    const [showSummary, setShowSummary] = useState((editing.showSummary) === undefined  ? false : editing.showSummary);
    const [hideFromProgress, setHideFromProgress] = useState(editing.hideFromProgress === undefined  ? false : editing.hideFromProgress);

    const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        shopOrigin: props.shop.info.shop,
    });

    setupModalAutoSizing(app);

    const toastOptions = {
        message: 'Step saved',
        duration: 3000
    };
    const toast = Toast.create(app, toastOptions);

    const showError = (msg) => {
        setError(msg);
        const errToastOptions = {
            message: msg,
            duration: 3000,
            isError: true
        };
        const errToast = Toast.create(app, errToastOptions);
        errToast.dispatch(Toast.Action.SHOW);
    }

    const addStep = () => {
        setError("");
        if (!title) return showError("Please enter a step title.");
        if (!displayOrder) return showError("Please enter a display order.");
        
        props.addStep(props.editing, {
            title,
            description,
            displayOrder,
            required,
            type: 'subscription',
            shortTitle,
            showSummary,
            quantity: false,
            hideFromProgress
        });
        toast.dispatch(Toast.Action.SHOW);
    }

    return (
        <>
        <Card 
            title="Adding a Subscription Step" 
            sectioned
            secondaryFooterActions={[{
                content: 'Cancel Step',
                onAction: () => props.hideForm(false),
                loading: false
            }]}
            primaryFooterAction={{
                content: 'Save Step',
                onAction: () => addStep(),
                loading: false
            }}
        >
            <Card.Section>
                <Banner
                    title="Setting Up Subscription Plans"
                    status="info"
                    >
                    <p>
                        Please note that you will need to set up subscription plans using your preferred subscription app.{` `}
                        Once they have been setup, you can link the plan to the product generated for this builder.{` `}
                        All linked subscription plans/options will show within this step.
                    </p>
                </Banner>
                <Form>
                    <FormLayout>
                        {error !== "" &&
                        <InlineError message={error} />
                        }
                        <TextField
                            label="Step Title"
                            value={title}
                            onChange={(val) => setTitle(val)}
                            type="text"
                            placeholder="e.g. Some information about you"
                        />
                        <TextField
                            label="Step Description"
                            value={description}
                            onChange={(val) => setDescription(val)}
                            type="text"
                            placeholder="e.g. You can personalize this order by providing your information below. "
                            helpText={
                                <span>
                                Additional text to help the customer understand what's required from this step.
                                </span>
                            }
                        />
                        <TextField
                            label="Step Short Title"
                            value={shortTitle}
                            onChange={(val) => setShortTitle(val)}
                            type="text"
                            placeholder="e.g. Gift Box "
                            helpText={
                                <span>
                                If you're using the step progress bar, you can add a shorter title for this step to show there.
                                </span>
                            }
                        />
                        <FormLayout.Group>
                            <TextField
                                label="Display Order"
                                type="number"
                                placeholder="e.g. 1"
                                value={displayOrder}
                                onChange={(val) => setDisplayOrder(val)}
                                helpText={
                                    <span>
                                    Set the order the step will display within this builder.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="A Required Step"
                                checked={required}
                                onChange={setRequired}
                                helpText={
                                    <span>
                                    Requires at least 1 item selection from the customer
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Show Box Contents/Summary"
                                checked={showSummary}
                                onChange={setShowSummary}
                                helpText={
                                    <span>
                                    This will show the currently selected products below the step title.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <Checkbox
                                label="Hide from Step Progress"
                                checked={hideFromProgress}
                                onChange={setHideFromProgress}
                                helpText={
                                    <span>
                                    Hide this step from the list of steps in the progress bar.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </Form>
            </Card.Section>
        </Card>
        </>
    );
}

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(withRouter(SubStep));