import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyAwWhuTB6kt2A8yEwO9ZlfaSoeOXffgdeo",
    authDomain: "boxup-builder.firebaseapp.com",
    databaseURL: "https://boxup-builder.firebaseio.com",
    projectId: "boxup-builder",
    storageBucket: "boxup-builder.appspot.com",
    messagingSenderId: "14007275180",
    appId: "1:14007275180:web:dc413e284d29fdd4c91909",
    measurementId: "G-6FFTPZ4YDH"
};

export const fb = !app.apps.length ? app.initializeApp(config) : app.app();
export const db = app.firestore();
export const storage = app.storage();

export const translations = [
    {
        key: 'total',
        default: 'Total',
        label: '"Total" - Shown in the footer'
    },
    {
        key: 'next',
        default: 'Next',
        label: '"Next" - Inside the next button'
    },
    {
        key: 'back',
        default: 'Back',
        label: '"Back" - Inside the back button'
    },
    {
        key: 'complete',
        default: 'Complete',
        label: '"Complete" - Inside the complete button'
    },
    {
        key: 'minError',
        default: 'Please select at least {number} product(s)',
        label: 'Text inside the error when the minimum selections is not met. Use the {number} placeholder '
    },
    {
        key: 'maxError',
        default: 'You can only select {number} product(s)',
        label: 'Text inside the error when the maximum selections is met'
    },
    {
        key: 'view',
        default: 'View Box',
        label: '"View Box" - Text inside the button to load the contents on a mobile'
    },
    {
        key: 'hide',
        default: 'Hide Box',
        label: '"Hide Box" - Text inside the button to hide the contents on a mobile'
    },
    {
        key: 'sortBy',
        default: 'Sort by',
        label: '"Sort by" - Inside the sort by drop down menu'
    },
    {
        key: 'sortTitleAsc',
        default: 'Product Title (asc)',
        label: 'The first sorting option'
    },
    {
        key: 'sortTitleDesc',
        default: 'Product Title (desc)',
        label: 'The second sorting option'
    },
    {
        key: 'sortPriceAsc',
        default: 'Price (low to high)',
        label: 'The third sorting option'
    },
    {
        key: 'sortPriceDesc',
        default: 'Price (high to low)',
        label: 'The fourth sorting option'
    },
    {
        key: 'filterCollection',
        default: 'Filter by collection',
        label: '"Filter by collection" - Inside the collection filter drop down menu'
    },
    {
        key: 'filterTags',
        default: 'Filter by tags',
        label: '"Filter by tags" - Inside the tags filter drop down menu'
    },
    {
        key: 'search',
        default: 'Search...',
        label: '"Search" - The placeholder for the search box'
    },
    {
        key: 'step',
        default: 'Step',
        label: '"Step" - Shown in the progress bar'
    },
    {
        key: 'select',
        default: 'Select option',
        label: 'Shown inside variant selection drop down menus'
    },
    {
        key: 'choose',
        default: 'Choose a',
        label: 'Text shown before the opton type in variant selection drop down menus'
    },
    {
        key: 'readMore',
        default: 'read more',
        label: 'Text to show the full product descriptions'
    },
    {
        key: 'soldOut',
        default: 'Sold Out',
        label: 'Text show when the variant is unavailable'
    },
    {
        key: 'discount',
        default: 'Discount',
        label: '"Discount" - Shown in the order details'
    },
    {
        key: 'addPercent',
        default: 'Add {number} more item(s) to receive {percentage} off.',
        label: 'Text encouraging the customer to add more items. Keep {number} and {percentage} if you want those to show.'
    },
    {
        key: 'addFixed',
        default: 'Spend {number} more to get {discount} off.',
        label: 'Text encouraging the customer to add more items. Keep {number} and {discount} if you want those to show.'
    },
    {
        key: 'applied',
        default: 'Discount Applied',
        label: 'Text shown when a discount criteria is met'
    },
    {
        key: 'itemAdded',
        default: 'Item Added',
        label: 'Popup message when a variant is selected'
    },
    {
        key: 'required',
        default: 'Required',
        label: 'Error text when a required field is not filled'
    },
    {
        key: 'boxDetails',
        default: 'Order details',
        label: '"Order details" - Appears above the contents of the box.'
    },
    {
        key: 'pleaseSelect',
        default: 'Please select...',
        label: 'The initial option for drop down menus on From Steps'
    },
    {
        key: 'remove',
        default: 'Remove:',
        label: '"Remove" - Text appearing in the button once the product is selected'
    },
    {
        key: 'selectOption',
        default: 'You must select an option for this product',
        label: 'You must select an option for this product'
    },
    {
        key: 'buildingTitle',
        default: 'We are building your box.',
        label: 'The title on the popup when completing the steps.'
    },
    {
        key: 'buildingText',
        default: 'This may take a few seconds...',
        label: 'The smaller text on the popup when completing the steps.'
    },
    {
        key: 'requiredPlan',
        default: 'Please select a subscription plan.',
        label: 'The message displayed if the customer is required to select a subscription plan.'
    },
    {
        key: 'startPrice',
        default: 'Base Price',
        label: 'Text that shows next to the start price in the box summary.'
    },
    {
        key: 'reset',
        default: 'Reset',
        label: 'Text inside the reset button.'
    }
];