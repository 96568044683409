import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { db } from './../../actions/settings';
import { createBuilder, setStep, setBuilderDetails, cleanReducer } from './../../actions/';
import { Page, Tabs } from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';

import BuilderSettings from './settings';
import BuilderSteps from './steps';
import BuilderSummary from './summary';

import Discounts from './../builder/discounts';

class SpeedBuilder extends React.Component {

    state = {
        loading: false,
        steps: [],
        builderId: this.props.match.params.id,
        tabSelected: 0
    }

    collectionData = (steps, builderId) => {
        let returnData = [];
        return new Promise((resolve) => {
          if (steps.length === 0) resolve(returnData);
          for (let x = 0, p = Promise.resolve(); x < steps.length; x++) {
            p = p.then(_ => new Promise(async(pResolve) => {

                let data = steps[x];
                let cDoc = await db.collection(`Builders/${builderId}/Steps/Step${Number(x+1)}/Filters`).doc("Collections").get();
                if (cDoc.exists) {
                    let cData = cDoc.data().collections;
                    data.selectedCollections = cData;
                    returnData.push(data);
                    pResolve();
                } else {
                    data.selectedCollections = [];
                    returnData.push(data);
                    pResolve();
                }
                if (x === steps.length-1) resolve(returnData);

            }));
        }
      });
    }

    componentDidMount = async () => {
        try {
            let id = this.state.builderId;
            if (!id) {
                let randomstring = require("randomstring");
                id = randomstring.generate(24);
                await this.props.cleanReducer();
                await this.props.createBuilder(id);
                this.setState({builderId: id});
            } else {
                let doc = await db
                    .collection("Builders")
                    .doc(id)
                    .get();
                if (doc.exists) {
                    let build = doc.data();
                    let steps = [];
                    let step = 0;
                    let docs = await db
                        .collection(`Builders/${id}/Steps`)
                        .get();
                    if (docs.size > 0) {
                        docs.forEach(async sdoc => {
                            steps.push(sdoc.data());
                            if (build.speed) {
                                let products = [];
                                let pDocs = await db.collection(`Builders/${build.builderId}/Steps/${sdoc.id}/Products`).get();
                                if (pDocs.size > 0) {
                                    let productChunks = [];
                                    pDocs.forEach(pDoc => productChunks.push({
                                        products: pDoc.data().products,
                                        order: Number(pDoc.id.replace("Chunk", ""))
                                    }));
                                    console.log("productChunks", productChunks);
                                    productChunks.sort((a,b) => a.order - b.order);
                                    productChunks.forEach(chunk => {
                                        chunk.products.forEach(product => products.push(product));
                                    });
                                }
                                steps[step].products = products;
                            }
                            step++;
                        });
                    }
                    steps = await this.collectionData(steps, id);
                    build.steps = steps;
                    await this.props.setBuilderDetails(build);
                    this.setState({
                        loading: false
                    });
                } else {
                    this.props.history.push("/admin/speed");
                }
            }
        } catch(e) {
            console.log(e);
            this.props.history.push("/error");
        }
    }

    handleTabChange = (newTab) => {
        if (newTab < this.state.tabSelected) {
            this.props.setStep(newTab);
            this.setState({tabSelected: newTab});
        }
    }
    
    render() {
        let { loading, tabSelected } = this.state;
        if (loading) {
            return <Loading/>
        } else {
            const tabs = [
                {
                  id: 'settings',
                  content: '1. Builder Settings',
                  accessibilityLabel: 'Builder Settings',
                  panelID: 'settings',
                  component: <BuilderSettings 
                        parent={this} 
                        settings={this.state.settings}
                        builderId={this.state.builderId}
                    />
                },
                {
                  id: 'steps',
                  content: '2. Steps',
                  panelID: 'steps',
                  component: <BuilderSteps parent={this} />
                },
                {
                    id: 'discounts',
                    content: '3. Discounts',
                    panelID: 'discounts',
                    component: <Discounts parent={this} />
                },
                {
                  id: 'summary',
                  content: '3. Summary',
                  panelID: 'summary',
                  component: <BuilderSummary parent={this} />
                }
            ];
            return <Page
                title="Design Your Builder"
                subtitle="Complete each tab below to create a product builder. You'll be given instructions at the end."
            >
                <Tabs tabs={tabs} onSelect={this.handleTabChange} selected={this.state.tabSelected} fitted>
                    <div style={{paddingTop: 25+'px'}}>
                        {tabs[tabSelected].component}
                    </div>
                </Tabs>
            </Page>
        }
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    createBuilder,
    setStep,
    cleanReducer,
    setBuilderDetails
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SpeedBuilder));