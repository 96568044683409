import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { closeBuilder, exitBuilder } from './../../actions/';
import { Heading, Banner, Layout, Button } from '@shopify/polaris';
import { createApp } from '@shopify/app-bridge';
import { Toast } from '@shopify/app-bridge/actions';

function BuilderSummary(props) {

    const [product, setProduct] = useState('');

    const builderURL = `/apps/builder?b=${props.builder.builderId}`;

    const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        shopOrigin: props.shop.info.shop,
    });

    const toastOptions = {
        message: 'URL Copied',
        duration: 2500
    };
    const toast = Toast.create(app, toastOptions);

    const loadBuilder = () => {
        window.open(`https://${props.shop.info.shop}${builderURL}`,  '_blank');
    }

    function copyURL() {
        var elem = window.document.createElement("textarea");
        window.document.body.appendChild(elem);
        elem.value = builderURL;
        elem.select();
        window.document.execCommand("copy");
        window.document.body.removeChild(elem);
        toast.dispatch(Toast.Action.SHOW);
    }

    useEffect(() => {
        setProduct(props.builder.productId);
    }, [props.builder]);
    
    return <>
    <Layout>
        <Layout.Section oneHalf>
            <Heading element="h1">View Using a Product</Heading><br/>
            <Banner
                title="A product was created for this builder"
                status="info"
            >
                <p>We have created a product with the same name as this builder. You can link customers to this product to load the builder.</p><br/>
                <p><b>Please note</b> that editing or deleting this product will stop the builder from working.</p><br/>
                <p>You can only edit this product to add a product image and description.</p><br/>
                <Button
                    external={true}
                    url={`https://${props.shop.info.shop}/admin/products/${product}`}
                >View Product</Button>
            </Banner>
        </Layout.Section>
        <Layout.Section oneHalf>
            <Heading element="h1">View Using a Link</Heading><br/>
            {props.builder.active &&
            <>
            <Banner
                title="You can now view your builder on your store."
                status="success"
                action={{content: 'View Builder', onAction: () => loadBuilder()}}
                secondaryAction={{content: 'Copy URL', onAction: () => copyURL()}}
            >
                <p>In order to get your customers loading the builder, you will need to direct them to this same link.</p><br/>
                <p>You can copy this link by using the button below.</p><br/>
                <p>e.g. in Online Store {'>'} Navigation, you can use this link for a menu item.</p>
            </Banner>
            </>
            }
            {!props.builder.active &&
            <Banner
                title="Your builder has been saved."
                status="info"
            >
                <p>You have chosen to not go live with this builder yet, so it's not visible on your store.</p>
                <p>When you are ready, edit this builder and check the 'Live' box to make it visible to your customers.</p>
            </Banner>
            }
        </Layout.Section>
    </Layout>
    </>
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    closeBuilder,
    exitBuilder
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BuilderSummary));