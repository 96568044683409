import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import { AppProvider, EmptyState, Banner } from "@shopify/polaris";

import { LoadingPage } from './../../screens/loading';

function ExitIframeComponent() {
    const { search } = useLocation();
    const [loading, setLoading] = React.useState(true);
    const [showWarning, setShowWarning] = React.useState(false);
    const [showThanks, setShowThanks] = React.useState(false);
    const [heading, setHeading] = React.useState("Pending Installation");
    const [action, setAction] = React.useState({});

    React.useEffect(() => {
        if (!!search) {
            const params = new URLSearchParams(search);
            const redirectUri = params.get("redirectUri");
            const url = new URL(decodeURIComponent(redirectUri));
            if (
                [window.location.hostname, "admin.shopify.com"].includes(url.hostname) ||
                url.hostname.endsWith(".myshopify.com")
            ) {
                setLoading(false);
                setAction({ content: 'Continue', url: url, external: true, disabled: showWarning, onAction: handleClick });
            } else {
                setShowWarning(true);
                setLoading(false);
            }
        }
    }, [search, setShowWarning, setLoading, setAction]);

    const handleClick = () => {
        setTimeout(() => {
            setShowThanks(true);
            setAction({ content: 'Continue', external: false, onAction: () => window.location.href = "/admin/" });
            setHeading("Thanks for installing the application")
        }, "5000");
    }

    return (
        loading ? (
            <LoadingPage shop="" />
        ) : (

            <>
                <div style={{
                    backgroundColor: '#f4f6f8',
                    marginLeft: 10 + '%',
                    width: 80 + '%'
                }}>
                    <div style={{
                        paddingTop: 80 + 'px'
                    }}>
                        <AppProvider
                            apiKey={process.env.API_KEY}
                            forceRedirect={process.env.FORCE_REDIRECT}
                        >
                            <EmptyState
                                heading={heading}
                                action={action}
                                secondaryAction={{ content: 'BoxUp Support', url: 'https://boxup.io/contact' }}
                                image="/assets/image/error.svg"
                                imageContained={true}
                            >
                                {showWarning ? (
                                    <div style={{ marginTop: "100px" }}>
                                        <Banner status="warning">
                                            Apps can only use /exitiframe to reach Shopify or the app itself.
                                        </Banner>
                                    </div>
                                ) : (
                                    showThanks ? (
                                        <Banner status="info">
                                            Click on continue to go to the main page of the application.
                                        </Banner>
                                    ) : (
                                        <Banner status="warning">
                                            You have a pending approval during installation process, click on continue to finish installing the application.
                                        </Banner>
                                    )
                                )}
                            </EmptyState>
                        </AppProvider>
                    </div>
                </div>
            </>
        )
    )
}

const ExitIframe = withRouter(ExitIframeComponent);
export default ExitIframe;