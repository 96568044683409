import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Page,
  CalloutCard,
  List,
  Layout,
  Card,
  Banner,
  Link,
} from "@shopify/polaris";
import { Loading } from "@shopify/app-bridge-react";
import { v4 as uuidv4 } from "uuid";
////////////////////
// If I need to request additional permissions from stores that already have the app installed
// then I direct them to this page instead of the 'Home' screen to agree the new permissions
////////////////////

class Version extends React.Component {
  state = {
    loading: false,
    show: true,
  };

  componentDidMount = async () => {
    if (this.props.shop.info.version === process.env.REACT_APP_VERSION) {
      this.setState({ loading: false, show: false });
    }
  };

  updateVersion = async () => {
    let rUrl = "https://builder.boxup.io/update";
    let url =
      `https://${this.props.shop.info.shop}/admin/oauth/authorize?client_id=` +
      `${process.env.REACT_APP_API_KEY}&scope=${
        process.env.REACT_APP_SCOPE
      }&redirect_uri=${encodeURI(rUrl)}&state=${uuidv4()}`;
    window.top.location.href = url;
  };

  render() {
    let { loading, show } = this.state;
    if (loading) {
      return <Loading />;
    } else if (show) {
      return (
        <Page
          title="Get ready for the upgrade!"
          subtitle="We are close to launch version 2.0 of the Box Builder app, we want to make sure you're ready."
        >
          <Layout>
            <Layout.Section>
              <Card title="What do you need to do:" sectioned>
                <List type="number">
                  <List.Item>
                    Enable theme extensions.
                    <List>
                      <List.Item>
                        <b>Action Needed</b>: Install the new theme extension (
                        <Link
                          url={`https://admin.shopify.com/store/${this.props.shop.info.shop.replace(
                            ".myshopify.com",
                            ""
                          )}/themes/current/editor?context=apps&template=product&activateAppId=0616c381-b470-4395-b013-48a4995ac72a/bundle_builder`}
                          external={true}
                        >
                          click here to enable theme extension
                        </Link>
                        )
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    Accept new permissions (these will help us use more updated
                    Shopify APIs, like the order and product discounts API).
                    <List>
                      <List.Item>
                        <b>Action needed</b>: Review and accept new permissions
                        by clicking <b>Update Box Builder</b> button below (this only takes a few seconds).
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
                <br />
                <br />
                <p style={{ marginBottom: "20px" }}>
                  As a reminder, here are some of the exciting updates you can look forward to:
                </p>
                <List>
                  <List.Item>
                    <b>Streamlined Functionality:</b> <br />
                    Say goodbye to the concept of UNWRAPPING! BundleBuilder is
                    now built using Shopify Bundles function, making the process
                    smoother and more intuitive for both you and your customers.
                  </List.Item>
                  <List.Item>
                    <b>Unified Builder Type:</b> <br />
                    We're simplifying things by consolidating all bundle types
                    into one. Don't worry – this new bundle type encompasses all
                    the features of the previous three types - but better!
                  </List.Item>
                  <List.Item>
                    <b>Improved Discount System:</b> <br />
                    We'll now use automatic discounts and Shopify functions.
                    Each bundle will have an automatic discount created, which
                    will be applied by Shopify to the bundle variant and its
                    components.
                  </List.Item>
                </List>
                <br />
                <Link
                  external={true}
                  url="https://box-builder.zendesk.com/hc/en-us/sections/24038768046491-BundleBuilder-upgrade-FAQ"
                >
                  Learn more about version 2.0
                </Link>
              </Card>
            </Layout.Section>
            <Layout.Section>
              <CalloutCard
                title=""
                illustration="/assets/image/new.png"
                primaryAction={{
                  content: "Update Box Builder",
                  primary: true,
                  onAction: () => this.updateVersion(),
                }}
              >
                <p>
                  We will direct you over to Shopify to grant the new
                  permission, you'll then come right back to our app.
                  <br />
                  It will just take a few seconds.
                </p>
              </CalloutCard>
            </Layout.Section>
          </Layout>
        </Page>
      );
    } else {
      return <Redirect to="/admin/" />;
    }
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(withRouter(Version));
