import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
    Form, FormLayout, TextField, List, Banner, Card, Checkbox,
    InlineError, Spinner, Button, Thumbnail, ResourceItem, ResourceList
} from '@shopify/polaris';
import { Toast } from '@shopify/app-bridge/actions';
import createApp from '@shopify/app-bridge';
import { getAssets, uploadAsset } from './../../../actions';

////////////////////
// Bulky Builder
// Store uses this screen when setting up/editing a Content step
// A content step is essentially just a rich text editor that allows for image/video uploads
// Any media content is uploaded to the store's asset files, not firebase storage
////////////////////

const ContentStep = React.forwardRef((props, ref) => {

    const [error, setError] = useState('');
    const [editing] = useState(props.editing !== null ? props.builder.steps[props.editing] : {});
    const [title, setTitle] = useState(editing.title || '');
    const [description, setDescription] = useState(editing.description || '');
    const [displayOrder, setDisplayOrder] = useState(editing.displayOrder || '');
    const [showSummary, setShowSummary] = useState(editing.showSummary === undefined  ? false : editing.showSummary);
    const [content, setContent] = useState(editing.content || '');
    const [showBanner, setBanner] = useState(true);
    const [showAssets, setShowAssets] = useState(false);
    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hideFromProgress, setHideFromProgress] = useState(editing.hideFromProgress === undefined  ? false : editing.hideFromProgress);

    const app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        shopOrigin: props.shop.info.shop,
    });

    const toastOptions = {
        message: 'Step saved',
        duration: 3000
    };

    const toast = Toast.create(app, toastOptions);

    const copyToastOptions = {
        message: 'Step saved',
        duration: 3000
    };

    const copyToast = Toast.create(app, copyToastOptions);

    const addStep = () => {
        setError("");
        if (!title) return setError("Please enter a step title.");
        if (!displayOrder) return setError("Please enter a display order.");
        let content = (window.$('#content').length > 0) ? window.$('#content').summernote('code') : '';

        props.addStep(props.editing, {
            title,
            description,
            displayOrder,
            type: 'content',
            showSummary,
            content: content,
            products: [],
            hideFromProgress
        });
        toast.dispatch(Toast.Action.SHOW);
    }

    useState(() => {
        setTimeout(() => {
            window.$('.summernote').summernote({ 
                height: 350,
                disableDragAndDrop: true,
                fontSizes: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '30', '36', '48' , '64'],
                fontNamesIgnoreCheck: ['Poppins'],
                toolbar: [
                  ['style', ['style','bold', 'italic', 'underline', 'clear', 'fontsize', 'fontsizeunit']],
                  ['font', ['strikethrough']],
                  ['color', ['color']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['height', ['height']],
                  ['codeview', ['codeview']],
                  ['insert', ['link', 'picture', 'hr']],
                  ['misc', ['undo', 'redo']]
                ],
                callbacks: {
                    onImageUpload: async function(files) {
                        let res = await uploadAssets(files);
                        if (res.error) {
                            alert("Error uploading to Shopify. Please try again,");
                            setShowAssets(true);
                            setLoading(false);
                        } else {
                            if (res.asset.public_url) window.$('#content').summernote('editor.insertImage', res.asset.public_url);
                            loadAssets();
                        }
                    }
                }
            });
            window.$("input[name=files]").prop("multiple", false);
        }, 500);
    }, [editing.content]);

    const uploadAssets = async(files) => {
        setLoading(true);
        setShowAssets(false);
        let upload = await uploadAsset(props.shop.info.id, files);
        return upload;        
    }

    const loadAssets = async() => {
        setLoading(true);
        let data = await getAssets(props.shop.info.id);
        if (data.assets) {
            setAssets(data.assets);
        } else {
            console.log(data.error);
            alert("Something went wrong, please try again.");
        }
        setShowAssets(true);
        setLoading(false);
    }

    function copyURL(val) {
        var elem = window.document.createElement("textarea");
        window.document.body.appendChild(elem);
        elem.value = val;
        elem.select();
        window.document.execCommand("copy");
        window.document.body.removeChild(elem);
        copyToast.dispatch(Toast.Action.SHOW);
    }

    return (
        <Card 
            title="Edit the contents of this step" 
            sectioned
            secondaryFooterActions={[{
                content: 'Back',
                onAction: () => props.hideForm(false),
                loading: false
            }]}
            primaryFooterAction={{
                content: 'Save Step',
                onAction: () => addStep(),
                loading: false
            }}
        >
            <Card.Section>
                {showBanner && 
                <Banner
                    title="A few things to note before setting up a Content Step."
                    status="info"
                    onDismiss={() => setBanner(false)}
                >
                    <List type="bullet">
                        <List.Item>Any images that are added to the content, that are chosen from your device, will be uploaded to your theme's assets.</List.Item>
                        <List.Item>The fonts will be based on the fonts provided by your theme, so there are no font options here.</List.Item>
                        <List.Item>Unless you specify font size here, it will take the default size of your theme.</List.Item>
                        <List.Item>You may need to be familiar with CSS to achieve a more advanced design. You can use the 'Code View' in the editor to add CSS and custom classes.</List.Item>
                    </List>
                </Banner>
                }
            </Card.Section>
            <Card.Section>
                <Form>
                    <FormLayout>
                        {error !== "" &&
                        <InlineError message={error} />
                        }
                        <TextField
                            label="Step Title"
                            value={title}
                            onChange={(val) => setTitle(val)}
                            type="text"
                            placeholder="e.g. Select Your Hard Drive"
                        />
                        <TextField
                            label="Step Description"
                            value={description}
                            onChange={(val) => setDescription(val)}
                            type="text"
                            placeholder="e.g. You can only select one hard drive "
                            helpText={
                                <span>
                                Additional text to help the customer understand what's required from this step.
                                </span>
                            }
                        />
                        <FormLayout.Group>
                            <TextField
                                label="Display Order"
                                type="number"
                                placeholder="e.g. 1"
                                value={displayOrder}
                                onChange={(val) => setDisplayOrder(val)}
                                helpText={
                                    <span>
                                    Set the order the step will display within this builder.
                                    </span>
                                }
                            />
                            <Checkbox
                                label="Hide from Step Progress"
                                checked={hideFromProgress}
                                onChange={setHideFromProgress}
                                helpText={
                                    <span>
                                    Hide this step from the list of steps in the progress bar.
                                    </span>
                                }
                            />
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <div className="summernote" id="content"><div dangerouslySetInnerHTML={{ __html: content }} /></div>
                        </FormLayout.Group>
                    </FormLayout>
                    <FormLayout>
                        <hr/>
                        <Button 
                            onClick={() => loadAssets()}
                            disabled={showAssets}
                            
                        >Show Your Theme's Image Assets with URL's</Button>
                        {loading && <Spinner accessibilityLabel="Loading assets" size="small" />}
                        {showAssets &&
                        <ResourceList
                            resourceName={{singular: 'asset', plural: 'assets'}}
                            items={assets}
                            renderItem={(item, i) => {
                            const {public_url} = item;
                            const media = <Thumbnail size="medium" source={public_url} />;
                            const shortcutActions = [{content: 'Copy URL', onAction: () => copyURL(public_url)}];
                            return (
                                <ResourceItem
                                id={i}
                                media={media}
                                shortcutActions={shortcutActions}
                                persistActions
                                >
                                <h3>
                                    <Button plain>{public_url}</Button>
                                </h3>
                                </ResourceItem>
                            );
                            }}
                        />
                        }
                        {showAssets && assets.length === 0 &&
                        <p>No assets can be found on your theme. You can upload them using the 'Picture' icon in the editor.</p>
                        }
                    </FormLayout>
                </Form>
            </Card.Section>
        </Card>
    );
});

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps
)(withRouter(ContentStep));