import React from 'react';
import { translations as defaultTranslations } from './../../actions/settings';
import { 
    FormLayout, TextField, Form
} from '@shopify/polaris';

export const LoadTranslations = ({parent}) => {
    let tItems = [];
    let translations = parent.state.translations;

    if (translations.length === 0) {
      translations = defaultTranslations;
    } else {
      defaultTranslations.map(d => {
        if (translations.filter(t => t.key === d.key).length === 0) {
          translations.push(d);
        }
      });
    }

    translations.map((t,i) => {
        tItems.push(
        <FormLayout.Group key={i}>
            <TextField
                value={t.value || t.default}
                onChange={(val) => {
                    translations[i].value = val;
                    parent.setState({
                        translations
                    });
                }}
                placeholder={t.default}
                label={t.label}
                type="text"
            />
        </FormLayout.Group>
        );
    });
    return <Form seperator>
        <p>Use the text boxes below to replace the default wording used on this builder.</p><br/>
        {tItems}
    </Form>
}